import React, { useMemo, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as QueryString from 'query-string';
import * as Sentry from '@sentry/react';

// Config
import constants from '../config/constants';
import packageJson from '../../package.json';

// Actions
import { orderCartItemsWithCreditCard } from '../actions/cart';

// Helpers
import { ApiError } from '../helpers/translateApiError';

// Hooks
import useAction from '../hooks/useAction';
import useOrderNumber from '../hooks/useOrderNumber';

// Components
import Loading from './shared/Loading';

const OrderPaid = ({ orderId, search }) => {
  // Convert the query string into an object we can use to populate the product filter.
  const params = useMemo(() => QueryString.parse(search), [search]);
  const { payment_amount: paymentAmount, payment_date: paymentDate, payment_number: paymentNumber, bank_reference: bankReference } = params;

  const [loading, setLoading] = useState(false);
  const orderNo = useOrderNumber();
  const onCartOrderConfirm = useAction(() => orderCartItemsWithCreditCard(paymentDate, null, orderNo, paymentAmount, bankReference, paymentNumber));

  const sentryErrorMessage = `Payment Paid - Order: ${orderId}`;
  const mode = 'payment_gateway';
  const expired = false;
  const response = '';
  const debtorId = localStorage.getItem('debtor_id');
  const cartItemsObject = JSON.parse(localStorage.getItem('cartItems'));

  const sendSentryError = useCallback(() => {
    // Manipulate the result into something more suitable for our Sentry error.
    const reducedCartItems =
      (cartItemsObject &&
        Object.entries(cartItemsObject).reduce(
          (a, [, product]) => ({
            ...a,
            [product.oid]: product.quantity,
          }),
          {},
        )) ||
      '';

    if (constants.sentryEnabled) {
      Sentry.captureException(new ApiError(sentryErrorMessage, mode, expired, response), {
        tags: {
          version: packageJson.version,
          debtorId,
          cartItems: JSON.stringify(reducedCartItems),
          params: JSON.stringify(params),
        },
      });
    }
  }, [cartItemsObject, debtorId, expired, params, sentryErrorMessage]);

  // Finalise the order and save into GlobalBake
  useEffect(() => {
    if (!loading) {
      setLoading(true);
      onCartOrderConfirm();
      // sendSentryError();
    }
  }, [loading, onCartOrderConfirm, setLoading, sendSentryError]);

  return <Loading loading={loading} mode="full" />;
};

OrderPaid.propTypes = {
  orderId: PropTypes.string,
  search: PropTypes.string,
};

OrderPaid.defaultProps = {
  orderId: '',
  search: '',
};

export default OrderPaid;
