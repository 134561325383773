import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import findIndex from 'lodash/findIndex';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';

// Date
import dayjs from 'dayjs';

// Material-UI
import { MuiThemeProvider, createTheme, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import HelpIcon from '@material-ui/icons/Help';
import MUIDataTable from 'mui-datatables';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

// Actions
import { showModal } from '../../actions/modals';

// Components
import OrdersListLineItems from './OrdersListLineItems';

// Helpers
import { dollars } from '../../helpers/number';

// Hooks
import useDeliveryDateNext from '../../hooks/useDeliveryDateNext';

// Reducers
import { getMinimumOrderValue } from '../../reducers';

// Config
import constants from '../../config/constants';

// Styles
const useStyles = makeStyles((theme) => ({
  drop: theme.drop,
  dropHalf: theme.dropHalf,
  itemRow: {
    fontSize: '0.9rem',
    fontWeight: 300,
  },
  total: {
    paddingTop: theme.spacing(1.25),
    paddingBottom: theme.spacing(1.25),
    textAlign: 'right',
    border: 'none',
  },
  totalRow: {
    border: 'none',
    paddingTop: theme.spacing(1.25),
    paddingBottom: theme.spacing(1.25),
  },
  grandTotal: {
    borderColor: theme.palette.secondary.main,
    borderTop: '1px solid',
    borderLeft: 0,
    backgroundColor: '#B0DF2B0D',
    fontWeight: 400,
  },
  sum: {
    fontWeight: 500,
  },
  helpIcon: {
    fontSize: 14,
    position: 'absolute',
  },
  button: {
    float: 'right',
    marginLeft: theme.spacing(3),
    borderRadius: '50px',
    fontSize: '0.875rem',
    letterSpacing: '0.6px',
    padding: theme.spacing(0, 2),
    height: '40px',
  },
  containedPrimary: {
    backgroundColor: '#00A1E4',
    color: '#ffffff',
  },
  outlinedPrimary: {
    color: '#00A1E4',
    border: '1px solid rgba(0, 161, 228, 0.5)',
    '&:hover': {
      border: '1px solid #00A1E4',
      backgroundColor: 'rgba(0, 161, 228, 0.04)',
    },
  },
  tooltip: {
    fontSize: 14,
    color: '#fff',
    padding: theme.spacing(1),
  },
}));

const useMuiStyles = createTheme({
  overrides: {
    MUIDataTable: {
      responsiveBase: {
        overflow: 'hidden',
      },
    },
    MUIDataTableHeadCell: {
      fixedHeader: {
        '&:nth-child(4)': {
          display: 'flex',
          justifyContent: 'flex-end',
        },
      },
    },
    MUIDataTableBodyCell: {
      root: {
        '&:nth-child(3)': {
          width: '35%',
        },
        '&:nth-child(4)': {
          textAlign: 'right',
          width: '20%',
        },
      },
    },
  },
});

const OrdersList = ({ loading, orders, disabled }) => {
  const classes = useStyles();
  const theme = useMuiStyles;
  const dispatch = useDispatch();

  const [expandedRow, setExpandedRow] = useState([]);
  const [selectedOrder, setOrder] = useState({});

  const creditDeliverySurchargeOid = constants.deliverySurchargeOid;
  const totalNett = selectedOrder?.totalNett || 0;
  const deliveryCost = useSelector(getMinimumOrderValue);
  let totalDelivery = deliveryCost && totalNett < deliveryCost ? deliveryCost - totalNett : 0;

  const onShowDeleteOrderModal = useCallback((order) => dispatch(showModal('delete', order)), [dispatch]);

  // Delivery Dates
  const { deliveryDateNext } = useDeliveryDateNext();

  const onOrderLineItemDelete = useCallback((event) => {
    if (event) event.preventDefault();
    // Update the order and reset loading.
    setExpandedRow([]);
  }, []);

  const onOrderDelete = useCallback(
    (event) => {
      if (event) event.preventDefault();
      // Show the confirm modal.
      onShowDeleteOrderModal(selectedOrder);

      // Update the order and reset loading.
      setExpandedRow([]);
    },
    [selectedOrder, onShowDeleteOrderModal],
  );

  // Job can be cancelled as long as it's due for dispatch after the next delivery cutoff
  const canEditOrder = false;
  const canCancelOrder = !disabled && dayjs(selectedOrder.despatchDate) > deliveryDateNext;

  const columns = [
    {
      name: 'Delivery Date',
      options: {
        filter: true,
      },
    },
    {
      name: 'Order Number',
      options: {
        filter: true,
      },
    },
    {
      name: 'Total',
      options: {
        filter: false,
      },
    },
  ];

  const options = {
    filter: true,
    filterType: 'dropdown',
    responsive: 'standard',
    elevation: 0,
    selectableRows: 'none',
    print: false,
    download: false,
    downloadOptions: {
      filename: 'VSE-Orders.csv',
    },
    textLabels: {
      body: {
        noMatch: loading ? 'Loading orders' : 'Sorry, no orders found',
        toolTip: '',
      },
    },

    // Expandable
    expandableRows: true,
    expandableRowsHeader: false,
    expandableRowsOnClick: true,
    rowsExpanded: expandedRow,
    isRowExpandable: () =>
      // Return false as we want to close all expanded rows and open the clicked on row manually. This is done in onRowExpansionChange
      false,
    onRowExpansionChange: (currentRowsExpanded, allRowsExpanded) => {
      // Get the row that was clicked.
      const { index } = currentRowsExpanded[0];

      // If the current row is not extended...
      if (findIndex(allRowsExpanded, { index }) < 0) {
        // Set it as our expanded row. This will close all others too.
        setExpandedRow([index]);

        // If the current row is extended, close it.
      } else {
        setExpandedRow([]);
      }

      // Get the order for the selected row.
      const order = orders[index][3];

      // Store the order for use in our hooks.
      setOrder(order);
    },

    renderExpandableRow: () => {
      const { rBKdocketLine } = selectedOrder;
      const tableRows = [];

      if (isEmpty(selectedOrder)) return null;

      rBKdocketLine &&
        rBKdocketLine.forEach((product, index) => {
          if (product.rBKproduct.oid === creditDeliverySurchargeOid) {
            // eslint-disable-next-line fp/no-mutation
            totalDelivery += product.unitPrice;
          } else {
            // eslint-disable-next-line fp/no-mutating-methods
            tableRows.push(
              <OrdersListLineItems
                key={`OrdersList-${selectedOrder.oid}-OrdersListLineItems-${product.oid}`}
                order={selectedOrder}
                product={product}
                index={index}
                disabled={canEditOrder === false || disabled}
                onOrderChange={setOrder}
                onOrderLineItemDelete={onOrderLineItemDelete}
              />,
            );
          }
        });

      // eslint-disable-next-line fp/no-mutating-methods
      tableRows.push(
        <TableRow className={classes.totalsRow} key={`OrdersList-${selectedOrder.oid}-LineItem-Delivery-Fee`}>
          <TableCell className={classes.totalRow} />
          <TableCell className={classes.totalRow} />
          <TableCell className={classes.totalRow} />
          <TableCell className={classes.total}>
            <Grid container>
              <Grid item xs={6}>
                <Typography className={classes.itemRow}>
                  Delivery Fee:
                  <Tooltip
                    title={
                      <Typography className={classes.tooltip}>
                        {`Delivery is free for all orders over ${dollars(deliveryCost)}. For orders smaller than this, there will be a delivery fee
                        that brings the order total up to ${dollars(deliveryCost)}`}
                      </Typography>
                    }
                    placement="top-end"
                  >
                    <HelpIcon className={classes.helpIcon} />
                  </Tooltip>
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography className={classes.itemRow}>{dollars(totalDelivery)}</Typography>
              </Grid>
            </Grid>
          </TableCell>
        </TableRow>,
      );

      // eslint-disable-next-line fp/no-mutating-methods
      tableRows.push(
        <TableRow className={classes.totalsRow} key={`OrdersList-${selectedOrder.oid}-LineItem-Nett`}>
          <TableCell className={classes.totalRow} />
          <TableCell className={classes.totalRow} />
          <TableCell className={classes.totalRow} />
          <TableCell className={classes.total}>
            <Grid container>
              <Grid item xs={6}>
                <Typography className={classes.itemRow}>Net:</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography className={classes.itemRow}>{dollars(totalNett)}</Typography>
              </Grid>
            </Grid>
          </TableCell>
        </TableRow>,
      );

      // eslint-disable-next-line fp/no-mutating-methods
      tableRows.push(
        <TableRow className={classes.totalsRow} key={`OrdersList-${selectedOrder.oid}-LineItem-Gst`}>
          <TableCell className={classes.totalRow} />
          <TableCell className={classes.totalRow} />
          <TableCell className={classes.totalRow} />
          <TableCell className={classes.total}>
            <Grid container>
              <Grid item xs={6}>
                <Typography className={classes.itemRow}>GST:</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography className={classes.itemRow}>{dollars(selectedOrder.gst)}</Typography>
              </Grid>
            </Grid>
          </TableCell>
        </TableRow>,
      );

      // eslint-disable-next-line fp/no-mutating-methods
      tableRows.push(
        <TableRow className={classes.grandTotal} key={`OrdersList-${selectedOrder.oid}-LineItem-Total`}>
          <TableCell className={classes.totalRow} />
          <TableCell className={classes.totalRow} />
          <TableCell className={classes.totalRow} />
          <TableCell className={classes.total}>
            <Grid container>
              <Grid item xs={6}>
                <Typography className={classes.sum}>Total:</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography className={classes.sum}>{dollars(selectedOrder.gross)}</Typography>
              </Grid>
            </Grid>
          </TableCell>
        </TableRow>,
      );

      canCancelOrder &&
        // eslint-disable-next-line fp/no-mutating-methods
        tableRows.push(
          <TableRow key={`OrdersList-${selectedOrder.oid}-Cancel`}>
            <TableCell colSpan={4} className={classes.totalRow}>
              <Grid container>
                <Grid item xs={12} className={classes.dropHalf}>
                  <Button variant="contained" color="primary" className={`${classes.button} ${classes.containedPrimary}`} onClick={onOrderDelete}>
                    Cancel Order
                  </Button>
                </Grid>
              </Grid>
            </TableCell>
          </TableRow>,
        );

      return tableRows;
    },
  };

  return (
    <MuiThemeProvider theme={theme}>
      <MUIDataTable data={orders} columns={columns} options={options} />
    </MuiThemeProvider>
  );
};

OrdersList.propTypes = {
  loading: PropTypes.bool,
  orders: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object]))),
  disabled: PropTypes.bool,
};

OrdersList.defaultProps = {
  loading: false,
  orders: [],
  disabled: false,
};

export default OrdersList;
