import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

// Actions
import { removeOrder } from '../actions/orders';

const useOrderDelete = (order) => {
  const dispatch = useDispatch();

  const onRemoveOrder = useCallback(() => {
    dispatch(removeOrder(order));
  }, [dispatch, order]);

  return onRemoveOrder;
};

export default useOrderDelete;
