import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useSelector, useDispatch } from 'react-redux';
import { getError, getErrorObject, getSuccess } from '../reducers';

// Actions
import { setError, setSuccess } from '../actions/notifications';

const useNotifications = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const errorMessage = useSelector(getError);
  const errorObject = useSelector(getErrorObject);
  const successMessage = useSelector(getSuccess);

  useEffect(() => {
    if (errorMessage) {
      console.error(errorObject ?? errorMessage);

      // Display the error message.
      enqueueSnackbar(errorMessage, {
        variant: 'error',
      });

      // The error has been displayed, remove error from state.
      dispatch(setError(''));
    }

    if (successMessage) {
      console.info(successMessage);

      // Display the error message.
      enqueueSnackbar(successMessage, {
        variant: 'success',
      });

      // The error has been displayed, remove error from state.
      dispatch(setSuccess(''));
    }
  }, [dispatch, errorMessage, errorObject, successMessage, enqueueSnackbar]);
};

export default useNotifications;
