import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

// Material-UI
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import EmojiFoodBeverageOutlinedIcon from '@material-ui/icons/EmojiFoodBeverageOutlined';

// Config
import routes from '../../config/routes';

// Actions
import { hideModal } from '../../actions/modals';

// Helpers
import { getProductWithinProducts } from '../../helpers/getProductWithinProducts';

// Hooks
import useCartItems from '../../hooks/useCartItems';

// Components
import CartItem from './CartItem';
import Link from './Link';

// Reducers
import { getProducts } from '../../reducers';

// Styles
const useStyles = makeStyles((theme) => ({
  listItem: {
    width: '100%',
  },
  cartItem: {
    backgroundColor: 'red',
  },
  listItemText: {
    margin: theme.spacing(2, 0),
  },
  listItemIcon: {
    top: '6px',
    position: 'relative',
    padding: theme.spacing(0, 2, 0, 0),
  },
}));

const CartList = ({ onError, showAddMore }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { cartItems, cartLength } = useCartItems();
  const onHideModal = useCallback(() => dispatch(hideModal()), [dispatch]);

  const products = useSelector(getProducts);

  const [hasInputError, setHasInputError] = useState({});

  useEffect(() => {
    onError(Object.values(hasInputError).includes(true));
  }, [hasInputError, onError]);

  const onInputUpdate = useCallback(
    (existingProductId, newProductId, quantity) => {
      const { [existingProductId]: remove, ...rest } = hasInputError;

      if (newProductId) {
        const product = getProductWithinProducts(newProductId, products);
        const hasError = product.maximumOrderQty !== 0 && quantity > product.maximumOrderQty;
        setHasInputError({ ...rest, [newProductId]: hasError });
      } else {
        setHasInputError({ ...rest });
      }
    },
    [hasInputError, products],
  );

  return (
    <List className={classes.list}>
      {cartLength === 0 && (
        <CartItem className={classes.cartItem}>
          <Link color="inherit" underline="none" to={routes.private.products} onClick={onHideModal} className={classes.listItem}>
            <Typography className={classes.listItemText}>
              <EmojiFoodBeverageOutlinedIcon className={classes.listItemIcon} />
              Your cart is empty. Click here to view available products.
            </Typography>
          </Link>
        </CartItem>
      )}
      {cartLength > 0 &&
        Object.values(cartItems).map((item) => (
          <CartItem className={classes.cartItem} key={`cartItems-${item.oid}`} item={item} onInputUpdate={onInputUpdate} />
        ))}
      {showAddMore && cartLength > 0 && (
        <CartItem className={classes.cartItem}>
          <Link color="inherit" underline="none" to={routes.private.products} onClick={onHideModal} className={classes.listItem}>
            <Typography className={classes.listItemText}>
              <EmojiFoodBeverageOutlinedIcon className={classes.listItemIcon} />
              Continue Shopping
            </Typography>
          </Link>
        </CartItem>
      )}
    </List>
  );
};

CartList.propTypes = {
  onError: PropTypes.func,
  showAddMore: PropTypes.bool,
};

CartList.defaultProps = {
  onError: () => {},
  showAddMore: false,
};

export default CartList;
