import React from 'react';
import PropTypes from 'prop-types';

// Material-UI
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';

// Styles
const useStyles = makeStyles((theme) => ({
  loadMoreButton: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    '&:hover': {
      backgroundColor: 'rgb(0, 112, 159)',
    },
  },
}));

const LinkLoadMore = ({ loading, hidden, onClick }) => {
  const classes = useStyles();

  if (loading)
    return (
      <Grid item xs={12} md={9} container direction="row" justifyContent="center" alignItems="center">
        <Button disabled={loading} disableFocusRipple disableRipple className={classes.loadMoreButton}>
          Loading
          <ExpandMoreOutlinedIcon />
        </Button>
      </Grid>
    );

  if (!hidden)
    return (
      <Grid item xs={12} md={9} container direction="row" justifyContent="center" alignItems="center">
        <Button disableFocusRipple disableRipple className={classes.loadMoreButton} onClick={onClick}>
          Load More
          <ExpandMoreOutlinedIcon />
        </Button>
      </Grid>
    );

  return null;
};

LinkLoadMore.propTypes = {
  loading: PropTypes.bool,
  hidden: PropTypes.bool,
  onClick: PropTypes.func,
};

LinkLoadMore.defaultProps = {
  loading: true,
  hidden: true,
  onClick: () => {},
};

export default LinkLoadMore;
