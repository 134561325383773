import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

// Material-UI
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Box from '@material-ui/core/Box';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';

// Styles
const useStyles = makeStyles((theme) => ({
  label: {
    textTransform: 'capitalize',
  },
  searchText: {
    color: theme.palette.primary.main,
    display: 'inline',
  },
}));

const ProductFilterItem = ({ title, options, selected, onFilter }) => {
  const classes = useStyles();

  const optionList = useMemo(
    () =>
      // eslint-disable-next-line fp/no-mutating-methods
      options
        .slice()
        .sort()
        .map((option) => ({
          label: option,
          value: option,
          onSelect: () => onFilter(option),
          onClear: () => onFilter(''),
        })),
    [options, onFilter],
  );

  return (
    <>
      {options && (
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel-size-content" id="panel-size-header">
            <Typography className={classes.label}>
              {title}:{' '}
              <Box component="span" className={classes.searchText}>
                {selected}
              </Box>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormControl component="fieldset">
              <FormGroup>
                {optionList.map(({ label, value, onClear, onSelect }) => (
                  <FormControlLabel
                    key={`filter-size-${label}`}
                    control={<Checkbox checked={selected === value} value={value} />}
                    label={label}
                    onMouseUp={selected === value ? onClear : onSelect}
                    className={classes.label}
                  />
                ))}
              </FormGroup>
            </FormControl>
          </AccordionDetails>
        </Accordion>
      )}
    </>
  );
};

ProductFilterItem.propTypes = {
  title: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string),
  selected: PropTypes.string,
  onFilter: PropTypes.func,
};

ProductFilterItem.defaultProps = {
  title: '',
  options: [],
  selected: '',
  onFilter: () => {},
};

export default ProductFilterItem;
