// App Version
import { clearCache } from 'clear-cache';
import packageJson from '../../package.json';

const compareAppVersion = (versionA, versionB) => {
  const versionsA = versionA.split('.');
  const versionsB = versionB.split('.');

  while (versionsA.length || versionsB.length) {
    // eslint-disable-next-line fp/no-mutating-methods
    const a = Number(versionsA.shift());
    // eslint-disable-next-line fp/no-mutating-methods
    const b = Number(versionsB.shift());

    // eslint-disable-next-line no-continue
    if (a === b) continue;
    // eslint-disable-next-line no-restricted-globals
    return a > b || isNaN(b);
  }
  return false;
};

const refreshCacheAndReload = () => {
  clearCache();
};

const cacheBuster = () => {
  // App Version
  const currentVersion = packageJson.version;

  fetch('/meta.json')
    .then((response) => response.json())
    .then((meta) => {
      const latestVersion = meta.version;
      const shouldForceRefresh = compareAppVersion(latestVersion, currentVersion);

      if (shouldForceRefresh) {
        console.log(`> We have a new version - ${latestVersion}. Should force refresh`);
        refreshCacheAndReload();
      } else {
        console.log(`> You already have the latest version - ${latestVersion}. No cache refresh needed.`);
      }
    })
    .catch((error) => {
      console.log(`> cacheBuster - No meta file found.`);
      return false;
    });

  return true;
};

export default cacheBuster;
