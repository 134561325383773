import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

// Actions
import { setCartItem } from '../actions/cart';
import { setSuccess } from '../actions/notifications';

// Hooks
import useProductOptions from './useProductOptions';
import useCartItems from './useCartItems';

// Helpers
import { parseFloat2dp } from '../helpers/number';

// Reducers
import { getMinimumOrderValue } from '../reducers';

// Config
import constants from '../config/constants';

const useCartAddItem = (lineitems) => {
  const dispatch = useDispatch();
  const [product, setProduct] = useState({});
  const { productOptionsMatrix } = useProductOptions(product);
  const { cartItems, cartLength } = useCartItems();
  const deliveryCost = useSelector(getMinimumOrderValue);

  const onAddCartItem = useCallback(() => {
    lineitems.products.forEach((lineItem) => {
      setProduct(lineItem);
    });
  }, [lineitems]);

  useEffect(() => {
    if (!isEmpty(product) && !isEmpty(productOptionsMatrix)) {
      // Add the productOptionsMatrix, as this is required by the ProductOptions component.
      dispatch(setCartItem({ ...product, productOptionsMatrix }));
      dispatch(setSuccess(`${product.webProductName} has been added to your cart.`));
    }
  }, [product, productOptionsMatrix, dispatch]);

  useEffect(() => {
    // Calculate the cart net total from all items and figure out the delivery fee.
    let cartNet = 0;
    let cartDelivery = {};

    if (cartLength > 0) {
      Object.values(cartItems).map((item) => {
        if (item.oid === constants.deliverySurchargeOid) {
          // eslint-disable-next-line fp/no-mutation
          cartDelivery = item;

          return cartNet;
        }

        // Calculate the lineitem total
        const itemPrice = parseFloat2dp(item.sellingPrice) || 0;
        const itemQuantity = parseInt(item.quantity, 10) || 1;
        const itemTotal = parseFloat2dp(itemPrice * itemQuantity) || 0;

        // eslint-disable-next-line fp/no-mutation
        cartNet += itemTotal;

        return cartNet;
      });
    }

    // Add the delivery fee as a cart line item so it is applied to the payment gateway.
    const deliveryCartItem = {
      category: '',
      exemptGst: false,
      itemCode: '',
      key: 0,
      maximumOrderQty: 1,
      oid: constants.deliverySurchargeOid,
      options: {},
      packaging: '',
      productOptionsMatrix: {},
      quantity: 1,
      sellingPrice: cartLength === 0 ? 0 : Math.max(0, parseFloat2dp(deliveryCost - cartNet)),
      size: '',
      sliced: '',
      subCategory: '',
      webProductName: 'Delivery Fee',
    };

    // Only update the cart if the delivery fee has changed.
    if (!isEqual(deliveryCartItem, cartDelivery))
      dispatch(
        setCartItem({
          ...deliveryCartItem,
        }),
      );
  }, [cartLength, cartItems, dispatch, deliveryCost]);

  return onAddCartItem;
};

export default useCartAddItem;
