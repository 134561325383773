import axios from 'axios';
// import './mock';

// Config
import constants from '../config/constants';

// Helpers
import { translateApiError } from '../helpers/translateApiError';

const productFields = {
  itemCode: '',
  webProductName: '',
  webProductDescription: '',
  // webEnabled: '',
  allergens: '',
  maximumOrderQty: '',
  // minimumOrderQty: '',
  // naFri: '',
  // naMon: '',
  // naSat: '',
  // naSun: '',
  // naThu: '',
  // naTue: '',
  // naWed: '',
  // nutritionalSpec: '',
  // nutritionalWeight: '',
  productDimensionsUnit: '',
  productHeight: '',
  productLength: '',
  productWidth: '',
  productDiameter: '',
  // rawMaterialList: '',
  // rawMaterialsListAltCsv: '',
  // rawMaterialsListAltExp: '',
  rawMaterialsListCsv: '',
  sellingPrice: '',
  exemptGst: '',
  servingSize: '',
  shelfLifeDays: '',
  storageConditions: '',
  temperature: '',
  unitsPerCtn: '',
  rBKcountryOfOrigin: {
    country: '',
  },
  rBKpriceBookLine: {
    price: '',
    rBKpriceBook: {
      code: '',
    },
  },
  xBKnutrition: {
    carbohydrate: '',
    carbohydrateBase: '',
    dietaryFibre: '',
    dietaryFibreBase: '',
    energy: '',
    energyBase: '',
    protein: '',
    proteinBase: '',
    saturatedFat: '',
    saturatedFatBase: '',
    sodium: '',
    sodiumBase: '',
    sugar: '',
    sugarBase: '',
    totalFat: '',
    totalFatBase: '',
  },
  '#WebCategory': '',
  '#WebSubCategory': '',
  '#WebFlavour': '',
  '#WebSize': '',
  '#WebWrapped': '',
  '#WebSliced': '',
};

export const fetchProducts = (token, debtorId) => {
  if (!token || !debtorId) return Promise.reject(new Error('Fetching Products Failed: Debtor token is missing'));

  const cachedProducts = JSON.parse(localStorage.getItem('products')); // Retrieve the products from localStorage.
  if (cachedProducts) return Promise.resolve(cachedProducts);

  const data = {
    command: 'GetClassObject',
    parameters: {
      token,
      oid: debtorId,
      properties: {
        name: '',
        rBKpriceBook: {
          code: '',
          description: '',
          rBKpriceBookLine: {
            customerCode: '',
            price: '',
            minimumSpecial: '',
            rBKproductFinished: {
              '#WebCategory': '',
              '#WebFlavour': '',
              '#WebSize': '',
              '#WebSliced': '',
              '#WebSubCategory': '',
              '#WebWrapped': '',
              itemCode: '',
              obsolete: '',
              sellingPrice: '',
              exemptGst: '',
              temperature: '',
              unitsPerCtn: '',
              webProductName: '',
              maximumOrderQty: '',
            },
          },
        },
      },
    },
  };

  // console.log('API REQUEST : fetchProducts = ', JSON.stringify(data));

  return axios
    .post(constants.apiUrl, data)
    .then((response) => translateApiError(response, data))
    .then((response) => response.data.data);
};

export const fetchProduct = (token, oid) => {
  const data = {
    command: 'GetClassObject',
    parameters: {
      token,
      oid,
      properties: productFields,
    },
  };

  // console.log('API REQUEST : fetchProduct = ', JSON.stringify(data));

  return axios
    .post(constants.apiUrl, data)
    .then((response) => translateApiError(response, data))
    .then((response) => response.data);
};

export const fetchProductImage = (token, productItemCode, width) => {
  const height = width * 0.75; // 4:3 Aspect Ratio
  const data = {
    token,
    productItemCode,
    width,
    height,
  };

  return axios.post(constants.cacheImageUrl, data).then((response) => response.data);
};

export const fetchProductsRelated = (oid) =>
  axios
    .get(`/product/${oid}/related`)
    .then((response) => translateApiError(response, { oid }))
    .then((response) => response.data.objects);
