import * as Sentry from '@sentry/react';
import { sendEmailError } from '../api/Email';

// Config
import constants from '../config/constants';
import packageJson from '../../package.json';

class ApiError extends Error {
  constructor(message = '', mode = 'notification', expired = false, debtorId = '', request = {}, response = {}, notificationMessage = '', ...params) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super(...params);

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ApiError);
    }

    // Custom debugging information
    this.name = '';
    this.message = message;
    this.mode = mode;
    this.expired = expired;
    this.debtorId = debtorId;
    this.request = request;
    this.response = response;
    this.notificationMessage = notificationMessage;
    this.date = new Date();
  }
}

const manipulateApiError = (type, string, state) => {
  const result = {};

  switch (type) {
    case 'restricted_products': {
      const regex = /(Not Valid : Debtor {1})(\w+)( has restricted products. Product {1})(\w+)( {2}\(Qty = {1})(\d+)(\) not on list for {1})(\w+)/g;
      const match = regex.exec(string) || [];

      // Find the product that matches the product code in the error message.
      if (match.length === 9 && state) {
        const productCode = match[4];
        const product = Object.keys(state).reduce((obj, key) => {
          if (state[key].product.itemCode === productCode) {
            return state[key].product;
          }
          return {};
        }, {});

        // Define our result.
        return { productCode, productName: product.webProductName, quantity: match[6], weekday: match[8] };
      }
      break;
    }

    default:
      break;
  }

  return result;
};

export const translateApiError = (response, request, state) => {
  let mode = 'notification';
  let expired = false;
  let sendEmail = false;
  let sendSentryError = false;

  // console.log('API RESPONSE : response = ', JSON.stringify(response));

  // HTTP Status Codes

  // 1xx: Informational	Communicates transfer protocol-level information.
  // 2xx: Success	Indicates that the client’s request was accepted successfully.
  // 3xx: Redirection	Indicates that the client must take some additional action in order to complete their request.
  // 4xx: Client Error	This category of error status codes points the finger at clients.
  // 5xx: Server Error	The server takes responsibility for these error status codes.

  let errorMessage =
    response.status >= 200 && response.status < 300 ? '' : 'We are currently having issues. Please try again later or ring us to process your order manually.';

  // SSi API Token Status

  // 100	Token found but Expired
  // 110	Token not found
  // 120	Token found but has different IP address to this request
  // 200	Token Logon does not support Display for that object
  // 300	Token Logon does not support Change or Delete for that object
  // 400	Token Logon does not support Add for that class
  if (response && response.data) {
    switch (response.data.tokenStatus) {
      case 100: // Expired Token
        /* eslint-disable fp/no-mutation */
        errorMessage = 'Expired login. Please login again to carry on from where you left off.';
        expired = true;
        /* eslint-enable fp/no-mutation */
        break;

      case 110: // Token not found
        /* eslint-disable fp/no-mutation */
        errorMessage = 'Expired login. Please login again to carry on from where you left off.';
        expired = true;
        /* eslint-enable fp/no-mutation */
        break;

      default:
        break;
    }
  }

  // SSi API Error Codes

  const responseErrorCode = response.data.errorCode || response.errorCode || 0;
  const responseErrorMessage = response.data.errorMessage || response.errorMessage || '';

  // console.log(`translateApiError :: responseErrorCode = ${responseErrorCode} :: responseErrorMessage = ${responseErrorMessage}`);

  // Error	Constant				Description
  // 1000	ErrorInvalidLicence		Invalid Licence/Registration
  // 1001	ErrorInvalidJSON		Invalid JSON sent
  // 1002	ErrorInvalidCompany		Invalid company - could not set
  // 1003	ErrorInvalidCommand	Command Not Found
  // 1004	ErrorNoParameter		No "parameter" keyword sent
  // 1005	ErrorInvalidKeyword		Invalid or missing keyword - see message
  // 1010	ErrorSecurityToken		Invalid or missing security token - see token status and error message
  // 1500	ErrorException		Exception occured processing request - see message
  // 1600	ErrorEdition			Object edition is out of date - see message
  // 2000	ErrorInvalidOid		Invalid Oid or no Oid sent
  // 2001	ErrorInvalidClass		Invalid class for command or access
  // 2002	ErrorInvalidProperty		Invalid Property for class - see message
  // 2100	ErrorDatabaseSecurity	Database Permissions prevent required access
  // 9999	ErrorUserMessage		Error message is for the user
  /* eslint-disable fp/no-mutation */
  switch (responseErrorCode) {
    case 1010: // Invalid Token
      errorMessage = 'Expired login. Please login again to carry on from where you left off.';
      expired = true;
      sendEmail = false;
      sendSentryError = false;
      break;

    case 2000: // Oid not found
      errorMessage = 'Sorry, we were unable to find what you were looking for.';
      sendEmail = false;
      sendSentryError = true;
      break;

    case 2100: // Login failed
      errorMessage = 'Invalid login. Please confirm your email address and password are correct.';
      expired = true;
      sendEmail = false;
      sendSentryError = false;
      break;

    default:
      if (responseErrorMessage && responseErrorMessage !== '' && !responseErrorMessage.toLowerCase().includes('success')) {
        errorMessage = responseErrorMessage;
        sendEmail = false;
        sendSentryError = responseErrorCode !== 0;

        // Not Valid : Debtor [DEBTOR NAME] is Blocked by Credit Status or Limit
        if (responseErrorMessage.toLowerCase().includes('credit status')) {
          errorMessage = `Unfortunately, your account is on hold.\n\nPlease get in touch and we can sort this issue out.`;
          mode = 'modal';
          sendSentryError = false;
        }

        // Not Valid : Debtor [DEBTOR NAME] has restricted products. Product ATF  (Qty = 1) not on list for Wednesday
        if (responseErrorMessage.toLowerCase().includes('restricted products')) {
          const match = manipulateApiError('restricted_products', responseErrorMessage, state);
          errorMessage = `We are unable to deliver "${match.productName}" on ${match.weekday}s.\n\nEither change the delivery date or remove this product from the cart before placing the ordering.`;
          mode = 'modal';
          sendSentryError = false;
        }

        // Not Valid : BELOW Min $ 20.00
        if (responseErrorMessage.toLowerCase().includes('below min')) {
          errorMessage = `There is a $20 minimum order set on your account.\n\nIf you would like this removed, please get in touch and we will be happy to help.`;
          mode = 'modal';
          sendSentryError = false;
        }
      }

      break;
  }
  /* eslint-enable fp/no-mutation */

  // If there is an translated or unproceesed error, send an email to VSE and dev's.
  if (sendEmail && constants.errorEmailsEnabled) {
    const debtorId = localStorage.getItem('debtor_id');
    const tokens = localStorage.getItem('tokens');
    const cartItems = localStorage.getItem('cartItems');

    sendEmailError({
      subject: 'Order Website - API Error',
      type: 'api-error-notification',
      preview: `Debtor ${debtorId} experienced an error on the order website.`,
      debtorId,
      tokens,
      url: window.location.href,
      cartItems,
      request,
      response,
      notificationMessage: errorMessage,
    });
  }

  // If there is an translated or unproceesed error, send the error to Sentry.
  if (constants.sentryEnabled && sendSentryError) {
    const debtorId = localStorage.getItem('debtor_id');
    const cartItemsObject = JSON.parse(localStorage.getItem('cartItems'));

    // Manipulate the result into something more suitable for our Sentry error.
    const reducedCartItems =
      (cartItemsObject &&
        Object.entries(cartItemsObject).reduce(
          (a, [, product]) => ({
            ...a,
            [product.oid]: product.quantity,
          }),
          {},
        )) ||
      '';

    const sentryErrorMessage = errorMessage || responseErrorMessage;

    console.log(
      `translateApiError SENTRY :: responseErrorCode = ${responseErrorCode} :: errorMessage = ${errorMessage} :: responseErrorMessage = ${responseErrorMessage}`,
    );

    Sentry.captureException(new ApiError(sentryErrorMessage, mode, expired, response), {
      tags: {
        version: packageJson.version,
        debtorId,
        cartItems: JSON.stringify(reducedCartItems),
      },
    });
  }

  // If there is an translated error, return a rejected response.
  if (errorMessage || expired) {
    return Promise.reject(new ApiError(errorMessage, mode, expired, response));
  }

  // No error, so return the original API response.
  return Promise.resolve(response);
};

export { translateApiError as default };
export { ApiError };
