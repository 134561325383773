import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

// Material-UI
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

// Config
import constants from '../config/constants';
import routes from '../config/routes';

// Actions
import { showModal, hideModal } from '../actions/modals';

// Hooks
import useAction from '../hooks/useAction';
import useCartItems from '../hooks/useCartItems';
import useModalShow from '../hooks/useModalShow';

// Components
import CartList from './shared/CartList';
import OrderTotals from './shared/OrderTotals';

// Styles
const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: theme.spacing(0, 2, 3, 2),
  },
  button: {
    marginLeft: theme.spacing(2),
  },
  header: {
    backgroundColor: theme.palette.action.selected,
    textAlign: 'center',
  },
  title: {
    padding: theme.spacing(2, 0, 0, 0),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  footer: {
    backgroundColor: theme.palette.action.selected,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    padding: theme.spacing(2),

    [theme.breakpoints.down('xs')]: {
      textAlign: 'right',
    },
  },
  totals: {
    [theme.breakpoints.up('sm')]: {
      order: 2,
    },
  },
  tooltip: {
    fontSize: 14,
    color: '#fff',
    padding: theme.spacing(1),
  },
}));

const Cart = () => {
  const classes = useStyles();
  const history = useHistory();

  const { cartTotal, cartNet, cartGst, cartDelivery } = useCartItems();
  const { openModal } = useModalShow();
  const onShowDeleteModal = useAction(() => showModal('cart_delete', true));
  const onHideModal = useAction(hideModal);
  const [redirectTo, setRedirect] = useState(false);
  const [hasError, setHasError] = useState(false);

  // Used to define the cart body to scroll
  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (openModal === 'cart') {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [openModal]);

  const onPlaceOrder = () => {
    onHideModal();
    setRedirect(routes.private.order);
  };

  if (redirectTo) {
    // Reset the redirect state.
    setRedirect(false);

    // Redirect to the order screen.
    history.push({
      pathname: routes.private.order,
    });
  }

  return (
    <Dialog open={openModal === 'cart'} onClose={onHideModal} scroll="paper" fullWidth maxWidth="md" className={classes.dialog}>
      <DialogTitle className={classes.header} disableTypography>
        <IconButton className={classes.closeButton} onClick={onHideModal}>
          <CloseIcon />
        </IconButton>
        <Typography variant="h4" className={classes.title}>
          Your Cart
        </Typography>
      </DialogTitle>
      <DialogContent dividers className={classes.dialogContent}>
        <Grid item xs={12} ref={descriptionElementRef}>
          <CartList onError={setHasError} />
        </Grid>
      </DialogContent>
      <DialogActions className={classes.footer}>
        <Grid container justifyContent="flex-end" alignItems="flex-end" spacing={constants.gridSpacing}>
          <Grid item xs={12} sm={6} className={classes.totals}>
            <OrderTotals delivery={cartDelivery} net={cartNet} gst={cartGst} total={cartTotal} />
          </Grid>
          <Grid item xs={12} sm={6} className={classes.actions}>
            <Button color="primary" onClick={onShowDeleteModal} className={classes.button}>
              Clear Cart
            </Button>
            <Button variant="contained" color="primary" onClick={onPlaceOrder} className={classes.button} disabled={hasError}>
              Place Order
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

Cart.propTypes = {};

Cart.defaultProps = {};

export default Cart;
