import axios from 'axios';
// import './mock';

// Config
import constants from '../config/constants';

// Helpers
import { translateApiError } from '../helpers/translateApiError';

export const fetchFavourites = (token, debtorId) => {
  if (!token || !debtorId) return Promise.reject(new Error('Fetching Favourites Failed: Debtor token is missing'));

  // const cachedFavourites = JSON.parse(localStorage.getItem('favourites')); // Retrieve the favourites from localStorage.
  // if (cachedFavourites) return Promise.resolve(cachedFavourites);

  const data = {
    command: 'GetClassObjects',
    parameters: {
      class: 'BKdebtor',
      token,
      oids: debtorId,
      properties: {
        rBKdebtorProduct: {
          // filter: {
          //   webFavourite: true,
          // },
          webFavourite: '',
          rBKproduct: {
            itemCode: '',
          },
        },
      },
    },
  };

  // console.log('API REQUEST : fetchFavourites = ', JSON.stringify(data));

  return axios
    .post(constants.apiUrl, data)
    .then((response) => translateApiError(response, data))
    .then((response) => response.data.data);
};

export const addFavourite = (token, debtorId, oid, status) => {
  if (!token || !debtorId) return Promise.reject(new Error('Fetching Favourites Failed: Debtor token is missing'));
  if (!oid) return Promise.reject(new Error('Updating Favourites Failed: Product ID is missing'));
  if (!status) return Promise.reject(new Error('Updating Favourites Failed: Status is missing'));

  const data = {
    command: 'UpdateObject',
    parameters: {
      class: 'BKdebtorProduct',
      token,
      rBKdebtor: debtorId,
      rBKproduct: oid,
      webFavourite: true,
    },
  };

  // console.log('API REQUEST : addFavourite = ', JSON.stringify(data));

  return axios
    .post(constants.apiUrl, data)
    .then((response) => translateApiError(response, data))
    .then((response) => response.data.data);
};

export const updateFavourite = (token, edition, oid, status = false) => {
  if (!token) return Promise.reject(new Error('Updating Favourites Failed: Debtor token is missing'));
  if (!oid) return Promise.reject(new Error('Updating Favourites Failed: Product ID is missing'));

  const data = {
    command: 'UpdateObject',
    parameters: {
      class: 'BKdebtorProduct',
      token,
      oid,
      edition,
      webFavourite: status,
    },
  };

  // console.log('API REQUEST : updateFavourite = ', JSON.stringify(data));

  return axios
    .post(constants.apiUrl, data)
    .then((response) => translateApiError(response, data))
    .then((response) => response.data.data);
};
