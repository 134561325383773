import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import last from 'lodash/last';

// Material-UI
import { makeStyles } from '@material-ui/core/styles';
import { ValidatorForm } from 'react-material-ui-form-validator';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import AddCircleIcon from '@material-ui/icons/AddCircle';

// Config
import constants from '../../config/constants';

// Hooks
import useCartAddItem from '../../hooks/useCartAddItem';
import useForm from '../../hooks/useForm';

// Helpers
import { dollars, parseFloat2dp } from '../../helpers/number';
import { poplateProductOptions } from '../../helpers/getProductOptions';

// Components
import ProductOptionsLineItems from './ProductOptionsLineItems';

// Styles
const useStyles = makeStyles((theme) => ({
  drop: theme.drop,
  dropHalf: theme.dropHalf,

  form: {
    boxSizing: 'border-box',
    padding: 12,
    width: '100%',
  },
  addButton: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    height: 40,
  },
  addIcon: {
    marginRight: theme.spacing(1),
  },
  productRow: {
    padding: theme.spacing(1),
  },
  footer: {
    backgroundColor: '#eee',
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    padding: theme.spacing(0, 1),
  },
  totalText: {
    marginLeft: theme.spacing(2),
    fontSize: '1.5rem',
  },
  gst: {
    float: 'right',
    fontSize: '0.8rem',
  },
}));

const ProductOptions = ({
  productId,
  itemCode,
  webProductName,
  category,
  subCategory,
  sellingPrice,
  quantity,
  maximumOrderQty,
  exemptGst,
  size,
  packaging,
  sliced,
  options,
  disabled,
  showAddRow,
  showDeleteRow,
  showProductName,
  showProductCost,
  showActions,
  onProductAdd,
  onProductChange,
  onProductDelete,
}) => {
  const classes = useStyles();

  const hasProduct = !isEmpty(options);
  const [hasError, setHasError] = useState(false);
  const [total, setTotal] = useState(0);
  const [validOptions, setValidOptions] = useState({ 0: { first: {}, poplateOptions: {} } });

  const defaultProduct = {
    key: 0,
    oid: productId,
    webProductName,
    category,
    subCategory,
    itemCode,
    sellingPrice,
    options,
    quantity,
    size,
    packaging,
    sliced,
    maximumOrderQty,
    exemptGst,
  };

  const [cartProducts, setCartProducts] = useState({
    products: [defaultProduct],
  });

  const addProductsToCart = useCartAddItem(cartProducts);
  const { inputs, handleAddRowClick, handleRemoveRowClick, handleSubmit, setInputValue } = useForm(cartProducts, () => {
    // On submit, add product to cart.
    addProductsToCart();

    // Run the function set on the parent component.
    onProductAdd();
  });

  // If the line items or options have changes...
  useEffect(() => {
    if (!isEmpty(options)) {
      const lineItems = inputs.products;
      let updateProducts = true;

      // For each line item...
      lineItems.forEach((lineItem, key) => {
        // Figure out the product options based on whats selected.
        const { poplateOptions, first } = poplateProductOptions(options, lineItem.size, lineItem.packaging, lineItem.sliced);

        // Set these in the state.
        setValidOptions({ ...validOptions, [key]: { poplateOptions, first } });

        // If the line item option is different to the ones that are available to select...
        if (lineItem.size !== first.size || lineItem.packaging !== first.packaging || lineItem.sliced !== first.sliced) {
          // Force the line item to have valid options selected.
          // This fixes an issue where the user changes the size, the packaging options change.
          // There is a chance were the  selected packaging is not a valid option that can be selected.
          setInputValue(`products[${key}]size`, first.size);
          setInputValue(`products[${key}]packaging`, first.packaging);
          setInputValue(`products[${key}]sliced`, first.sliced);

          // eslint-disable-next-line fp/no-mutation
          updateProducts = false;
        }

        // Check if the quantity is greater than the max available.
        setHasError(lineItem.maximumOrderQty !== 0 && lineItem.quantity > lineItem.maximumOrderQty);
      });

      // Flag the inputs as changed.
      if (updateProducts) {
        setCartProducts(inputs);
        onProductChange(productId, inputs);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, inputs, setInputValue]);

  // If the line items have changes...
  useEffect(() => {
    let subTotal = 0;
    const lineItems = inputs.products;

    // For each line item...
    lineItems.forEach((lineItem) => {
      // eslint-disable-next-line fp/no-mutation
      subTotal += parseFloat2dp(lineItem.sellingPrice) * parseFloat2dp(lineItem.quantity) || 0;
    });

    // Update the total in the state.
    setTotal(subTotal);
  }, [inputs]);

  const handleInputChangeOverride = useCallback(
    (event) => {
      event.persist();
      // Do not allow the quantity field to go below one.
      const minCheck = event.target.name.includes('quantity') && event.target.value !== '' && event.target.value < 1;

      // Push the input change into the form state.
      setInputValue(event.target.name, minCheck ? 1 : event.target.value);
    },
    [setInputValue],
  );

  const handleInputBlurOverride = useCallback(
    (event) => {
      event.persist();
      // Do not allow the quantity field to go below one.
      const minCheck = event.target.name.includes('quantity') && event.target.value < 1;

      // Push the input change into the form state.
      setInputValue(event.target.name, minCheck ? 1 : event.target.value);
    },
    [setInputValue],
  );

  const handleRemoveRowClickOverride = useCallback(
    (groupName, key) => (event) => {
      event.preventDefault();

      if (onProductDelete instanceof Function) {
        onProductDelete(key, inputs);

        // If the user has clicked the remove button, do the action only if there is more than one being displayed.
      } else if (Object.keys(inputs.products).length > 1) {
        handleRemoveRowClick(groupName, key)(event);
      }
    },
    [handleRemoveRowClick, onProductDelete, inputs],
  );

  const onInputChange = useCallback(
    (key, selectedProduct) => {
      // Updated the input in the form with the selected product information.
      setInputValue(`products[${key}]oid`, selectedProduct.oid);
      setInputValue(`products[${key}]webProductName`, selectedProduct.webProductName);
      setInputValue(`products[${key}]itemCode`, selectedProduct.itemCode);
      setInputValue(`products[${key}]sellingPrice`, parseFloat2dp(selectedProduct.sellingPrice));
      setInputValue(`products[${key}]options`, selectedProduct.options);
    },
    [setInputValue],
  );

  if (!hasProduct) return true;

  return (
    <ValidatorForm key="ProductOptions" className={classes.form} onSubmit={handleSubmit}>
      {inputs.products.map((item) => (
        <Grid key={`ProductOptions-ProductOptionsLineItems-${item.key}`} container className={classes.productRow} spacing={constants.gridSpacing}>
          <ProductOptionsLineItems
            index={item.key}
            options={validOptions[item.key] && validOptions[item.key].poplateOptions}
            lineItem={item}
            setInputValue={setInputValue}
            disabled={disabled}
            handleInputChange={handleInputChangeOverride}
            handleInputBlur={handleInputBlurOverride}
            onInputChange={onInputChange}
            handleAddRowClick={handleAddRowClick}
            handleRemoveRowClick={handleRemoveRowClickOverride}
            showAddRow={showAddRow}
            showDeleteRow={showDeleteRow}
            showProductName={showProductName}
            showProductCost={showProductCost}
          />
        </Grid>
      ))}

      {showAddRow && (
        <Grid container justifyContent="center" className={classes.addButton}>
          <Button
            color="primary"
            size="small"
            disableElevation
            onClick={handleAddRowClick('products', { ...last(inputs.products), key: last(inputs.products).key + 1 })}
          >
            <AddCircleIcon className={classes.addIcon} />
            Add another item
          </Button>
        </Grid>
      )}

      {showActions && (
        <Grid container spacing={constants.gridSpacing} justifyContent="space-between" className={`${classes.footer} ${classes.dropHalf}`}>
          <Grid item xs={5}>
            <Button type="submit" variant="contained" disableElevation color="primary" disabled={hasError}>
              Add to Cart
            </Button>
          </Grid>
          <Grid container item xs={6} pacing={constants.gridSpacing} justifyContent="flex-end" alignItems="center">
            <Typography variant="h6" align="right">
              Total
            </Typography>
            <Typography variant="h4" align="right" className={classes.totalText}>
              {dollars(total)}
            </Typography>
          </Grid>
        </Grid>
      )}
    </ValidatorForm>
  );
};

ProductOptions.propTypes = {
  productId: PropTypes.string,
  itemCode: PropTypes.string,
  webProductName: PropTypes.string,
  category: PropTypes.string,
  subCategory: PropTypes.string,
  sellingPrice: PropTypes.number,
  quantity: PropTypes.number,
  maximumOrderQty: PropTypes.number,
  exemptGst: PropTypes.bool,
  size: PropTypes.string,
  packaging: PropTypes.string,
  sliced: PropTypes.string,
  options: PropTypes.shape({}),
  disabled: PropTypes.bool,
  showAddRow: PropTypes.bool,
  showDeleteRow: PropTypes.bool,
  showProductName: PropTypes.bool,
  showProductCost: PropTypes.bool,
  showActions: PropTypes.bool,
  onProductAdd: PropTypes.func,
  onProductChange: PropTypes.func,
  onProductDelete: PropTypes.func,
};

ProductOptions.defaultProps = {
  productId: '',
  itemCode: '',
  webProductName: '',
  category: '',
  subCategory: '',
  sellingPrice: 0,
  maximumOrderQty: 255,
  exemptGst: false,
  quantity: 1,
  size: '',
  packaging: '',
  sliced: '',
  options: {},
  disabled: false,
  showAddRow: false,
  showDeleteRow: false,
  showProductName: false,
  showProductCost: false,
  showActions: false,
  onProductAdd: () => {},
  onProductChange: () => {},
  onProductDelete: null,
};

export default ProductOptions;
