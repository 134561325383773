import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

// Material-UI
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';

// Config
import constants from '../config/constants';
import routes from '../config/routes';

// Components
import Loading from './shared/Loading';

// Styles
const useStyles = makeStyles((theme) => ({
  container: {
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 'calc(100vw - 16px)',
    height: 'calc(100vh - 16px)',
  },
  typography: {
    margin: theme.spacing(4, 0),
    lineHeight: 1.4,
  },
  textColor: {
    color: theme.palette.text.primary,
  },
}));

const OrderProcessing = ({ location }) => {
  const classes = useStyles();

  const [errorMessage, setErrorMessage] = useState(null);
  const [hasErrored, setHasErrored] = useState(false);

  const { search } = location;
  const urlParams = new URLSearchParams(search);
  const sessionId = urlParams.get('session_id');
  const orderId = urlParams.get('orderNo');

  const history = useHistory();

  useEffect(() => {
    const confirmOrder = (items) => {
      const { debtor_id: debtorId, api_token: apiToken, order_number: orderNo } = items;

      const returnLinkUrl = `${routes.private.order_confirm}/${orderNo}?debtor_id=${debtorId}&tokens=${apiToken}/`;

      history.push(returnLinkUrl);
    };

    const fetchStripeSession = async () => {
      try {
        const response = await axios.post(constants.confirmPaymentUrl, {
          sessionId,
          orderId,
        });
        return response;
      } catch (error) {
        console.error(`postCheckoutSession ERROR :: error = `, JSON.stringify(error));
        return error;
      }
    };

    fetchStripeSession().then((response) => {
      if (response.status !== 200) {
        setHasErrored(true);
        setErrorMessage(response.message);
        return;
      }

      confirmOrder(response.data?.record?.Items[0]);
    });
  }, [orderId, sessionId, history]);

  return (
    <Container className={classes.container}>
      {hasErrored ? (
        <div>
          <Typography component="h1" variant="h3" className={`${classes.typography} ${classes.textColor}`}>
            Something went wrong
          </Typography>
          <Typography component="p" variant="h4" className={`${classes.typography} ${classes.textColor}`}>
            We were unable to verify your payment
          </Typography>
          {errorMessage && (
            <Typography component="p" variant="h5" className={classes.typography}>
              {errorMessage}
            </Typography>
          )}
          <Typography component="p" variant="h5" className={`${classes.typography} ${classes.textColor}`}>
            Please <Link href={`${constants.appUrl}/contact?orderNo=${orderId}`}>contact us</Link> with your order number: {orderId}
          </Typography>
        </div>
      ) : (
        <Loading loading mode="full" background="solid" title="Your order is being processed..." />
      )}
    </Container>
  );
};

OrderProcessing.propTypes = {
  location: PropTypes.shape({ search: PropTypes.string }),
};

OrderProcessing.defaultProps = {
  location: {},
};

export default OrderProcessing;
