import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

// Material-UI
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

// Actions
import { hideModal } from '../../actions/modals';

// Hooks
import useAction from '../../hooks/useAction';
import useModalShow from '../../hooks/useModalShow';
import useOrderDelete from '../../hooks/useOrderDelete';

// Reducers
import { getOrder } from '../../reducers';

// Styles
const useStyles = makeStyles((theme) => ({
  dialog: {
    textAlign: 'center',
  },
  dialogContent: {
    padding: theme.spacing(2, 2, 6, 2),
    borderTop: 'none',
  },
  dialogActions: {
    padding: theme.spacing(2),
  },
  grow: {
    flexGrow: 1,
    position: 'relative',

    top: 0,
    left: 0,
  },
  drop: {
    marginTop: theme.spacing(3),
  },
  title: {
    padding: theme.spacing(6, 0, 3, 0),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const ModalOrderDelete = () => {
  const classes = useStyles();

  const { openModal } = useModalShow();
  const onHideModal = useAction(hideModal);
  const order = useSelector(getOrder);
  const deleteOrder = useOrderDelete(order);

  const onOrderDelete = useCallback(() => {
    deleteOrder();
    onHideModal();
  }, [deleteOrder, onHideModal]);

  return (
    <Dialog onClose={onHideModal} open={openModal === 'delete'} fullWidth maxWidth="md" className={classes.dialog}>
      <DialogTitle disableTypography>
        <IconButton aria-label="close" className={classes.closeButton} onClick={onHideModal}>
          <CloseIcon />
        </IconButton>
        <Typography variant="h5" className={classes.title}>
          Cancel Order
        </Typography>
      </DialogTitle>
      <DialogContent dividers className={classes.dialogContent}>
        <Grid container direction="column" justifyContent="center" alignItems="center">
          <Grid item xs={6}>
            <Typography>Are you sure you want to cancel this order?</Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Grid container justifyContent="flex-end" spacing={2} className={classes.dialogActions}>
          <Grid item xs={6} sm={3}>
            <Button variant="outlined" fullWidth color="primary" onClick={onOrderDelete}>
              Cancel Order
            </Button>
          </Grid>
          <Grid item className={classes.grow} />
          <Grid item xs={6} sm={3}>
            <Button variant="contained" fullWidth color="primary" onClick={onHideModal}>
              Keep Order
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default ModalOrderDelete;
