import isEmpty from 'lodash/isEmpty';

// Helpers
import { getPriceCode } from './getPriceCode';

export const getProductPrice = (product) => {
  const priceCode = getPriceCode();

  if (isEmpty(product)) return 0;

  const priceBook = product?.rBKpriceBookLine.filter((item, index) => item?.rBKpriceBook.code === priceCode);

  return priceBook[0]?.price;
};

export { getProductPrice as default };
