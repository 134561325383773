import React from 'react';
import PropTypes from 'prop-types';

// Material-UI
import { makeStyles } from '@material-ui/core/styles';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

// Config
import routes from '../../config/routes';

// Helpers
import { isActiveLink } from '../../helpers/isActiveLink';

// Components
import SiteLogo from './SiteLogo';
import Link from './Link';

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
    position: 'relative',
    top: 0,
    left: 0,
  },
  link: {
    paddingRight: theme.spacing(5),
    lineHeight: '48px',
    textTransform: 'uppercase',
    fontSize: '14px',
    fontWeight: '500',
    letterSpacing: '0.4px',
    color: 'inherit',

    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}));

const NavigationDesktop = ({ currentUrl, onOpenDrawer }) => {
  const classes = useStyles();
  return (
    <AppBar position="static" color="inherit" elevation={0} className={classes.headerDesktop}>
      <Toolbar disableGutters>
        <Link color="inherit" to={routes.private.favourites}>
          <SiteLogo variant="normal" height="78" />
        </Link>
        <div className={classes.grow} />
        <Link color={isActiveLink(currentUrl, routes.private.favourites, true) ? 'primary' : 'inherit'} to={`${routes.private.favourites}`}>
          <Typography className={classes.link}>Favourites</Typography>
        </Link>
        <Link
          color={isActiveLink(currentUrl, routes.private.products, true) || isActiveLink(currentUrl, routes.private.product, true) ? 'primary' : 'inherit'}
          to={routes.private.products}
        >
          <Typography className={classes.link}>Products</Typography>
        </Link>
        <Link color={isActiveLink(currentUrl, routes.private.orders, true) ? 'primary' : 'inherit'} to={`${routes.private.orders}/scheduled`}>
          <Typography className={classes.link}>Orders</Typography>
        </Link>
        <Link color={isActiveLink(currentUrl, routes.public.faq) ? 'primary' : 'inherit'} to={routes.public.faq}>
          <Typography className={classes.link}>FAQs</Typography>
        </Link>
        <Link color={isActiveLink(currentUrl, routes.public.contact) ? 'primary' : 'inherit'} to={routes.public.contact}>
          <Typography className={classes.link}>Contact Us</Typography>
        </Link>
        <IconButton color={isActiveLink(currentUrl, routes.private.profile) ? 'primary' : 'inherit'} onClick={onOpenDrawer}>
          <AccountCircleOutlinedIcon style={{ color: '454851' }} />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

NavigationDesktop.propTypes = {
  currentUrl: PropTypes.string,
  onOpenDrawer: PropTypes.func,
};

NavigationDesktop.defaultProps = {
  currentUrl: '/',
  onOpenDrawer: () => {},
};

export default NavigationDesktop;
