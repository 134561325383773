import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { getUser, getUserLoading } from '../reducers';
import { loadUser } from '../actions/users';

const useUserLoad = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const queryDebtorId = queryParams.get('debtor_id') || '';
  const queryTokens = queryParams.get('tokens') || '';
  const isPaymentGatewayUser = !isEmpty(queryDebtorId);

  // If we are coming back from the payment gateway, the token is fetched and reapplied into local storage.
  // We then redirect the user straight to the order confirm page.
  if (isPaymentGatewayUser) {
    localStorage.setItem('debtor_id', queryDebtorId);
    localStorage.setItem('tokens', queryTokens.replace(/^\/|\/$/g, '')); // Store the token into localStorage. The token will be used if the user refreshes the page.

    const url = window.location.href.replace(window.location.search, '');

    // eslint-disable-next-line fp/no-mutation
    window.location.href = url;
  }

  const debtorId = localStorage.getItem('debtor_id'); // Retrieve the debtor_id from localStorage. The debtor_id is used to fetch related records from the API.
  const dispatch = useDispatch();
  const userLoading = useSelector(getUserLoading);
  const user = useSelector(getUser);

  useEffect(() => {
    if (!isPaymentGatewayUser) {
      dispatch(loadUser(debtorId));
    }
  }, [dispatch, debtorId, isPaymentGatewayUser]);

  return { userLoading, user };
};

export default useUserLoad;
