import React from 'react';
import PropTypes from 'prop-types';

// Material-UI
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import MenuOutlinedIcon from '@material-ui/icons/MenuOutlined';
import Toolbar from '@material-ui/core/Toolbar';

// Config
import routes from '../../config/routes';

// Helpers
import { isActiveLink } from '../../helpers/isActiveLink';

// Components
import SiteLogo from './SiteLogo';
import Link from './Link';

const useStyles = makeStyles(() => ({
  grow: {
    flexGrow: 1,
    position: 'relative',
    top: 0,
    left: 0,
  },
}));

const NavigationMobile = ({ currentUrl, onOpenDrawer }) => {
  const classes = useStyles();
  return (
    <AppBar position="static" color="inherit" elevation={0} className={classes.headerMobile}>
      <Toolbar disableGutters>
        <IconButton color={isActiveLink(currentUrl, routes.private.profile) ? 'primary' : 'inherit'} onClick={onOpenDrawer}>
          <MenuOutlinedIcon />
        </IconButton>
        <div className={classes.grow} />
        <Link color="inherit" to={`${routes.private.favourites}`}>
          <SiteLogo variant="normal" height="60" />
        </Link>
      </Toolbar>
    </AppBar>
  );
};

NavigationMobile.propTypes = {
  currentUrl: PropTypes.string,
  onOpenDrawer: PropTypes.func,
};

NavigationMobile.defaultProps = {
  currentUrl: '/',
  onOpenDrawer: () => {},
};

export default NavigationMobile;
