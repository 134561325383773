import React from 'react';

// Material-UI
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// Styles
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
}));

const NotFound = () => {
  const classes = useStyles();

  return (
    <Grid item xs={12}>
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <ErrorIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Not Found
        </Typography>
        <Typography>The feature you&apos;re looking for could not be found.</Typography>
      </div>
    </Grid>
  );
};

export default NotFound;
