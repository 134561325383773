import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

// Material-UI
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';

// Config
import routes from '../config/routes';

// Hooks
import useUserAuthenticate from '../hooks/useUserAuthenticate';
import useContactSubmit from '../hooks/useContactSubmit';
import useRegisterSubmit from '../hooks/useRegisterSubmit';

// Components
import ForgotForm from './auth/ForgotForm';
// import GuestForm from '../components/auth/GuestForm';
import Link from './shared/Link';
import LogInForm from './auth/LogInForm';
import RegisterForm from './auth/RegisterForm';
import ContactForm from './shared/ContactForm';
import SiteLogo from './shared/SiteLogo';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0),
  },
  logo: {
    margin: theme.spacing(3, 0),
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    margin: theme.spacing(2, 0, 0, 0),
  },
  link: {
    margin: theme.spacing(2, 0, 0, 0),
    fontSize: '1rem',
    fontWeight: '400',
    color: theme.palette.primary.main,
  },
}));

// Defined the components to display on tab click
const components = {
  ContactForm,
  ForgotForm,
  // GuestForm,
  LogInForm,
  RegisterForm,
};

const Home = ({ tab }) => {
  const classes = useStyles();

  const [selectedTab, setSelectedTab] = useState(tab);
  const [credentals, setCredentals] = useState({ username: '', password: '' });

  // Log user in when username and password entered
  useUserAuthenticate(credentals);

  // On tab change, set the clicked tab value into state.
  const onTabChange = (event, newValue) => setSelectedTab(newValue);

  // On form submit...
  const onContactSubmit = useContactSubmit();

  const onForgotSubmit = (values) => {
    console.log(`onForgotSubmit :: values = `, JSON.stringify(values));
  };
  const onLogInSubmit = (values) => {
    setCredentals({ username: values.email, password: values.password });
  };

  const onRegisterSubmit = useRegisterSubmit();

  // Defined the form submit methods to use on tab click
  const submitFunctions = {
    onContactSubmit,
    onForgotSubmit,
    onLogInSubmit,
    onRegisterSubmit,
  };

  // Set the component and save method as defined by the selected tab
  const TabComponent = components[`${selectedTab}Form`];
  const onSubmit = submitFunctions[`on${selectedTab}Submit`];

  // Define when the footer links are displayed.
  const showGuestLink = false; // selectedTab === 'LogIn' || selectedTab === 'Register';

  return (
    <Container component="main" maxWidth="lg">
      <Paper className={classes.paper} elevation={0}>
        <Grid item xs={6} sm={3} className={classes.logo}>
          <SiteLogo />
        </Grid>
        <Grid item xs={12}>
          <Tabs value={selectedTab} onChange={onTabChange} variant="fullWidth">
            <Tab label="Log In" value="LogIn" component={RouterLink} to={routes.auth.login} />
            <Tab label="Sign Up" value="Register" component={RouterLink} to={routes.auth.register} />
            <Tab label="Contact" value="Contact" component={RouterLink} to={routes.public.contact} />
            {showGuestLink && <Tab label="Guest" value="Guest" component={RouterLink} to={routes.auth.guest} />}
          </Tabs>
        </Grid>
        <Container component="main" maxWidth="sm">
          <Grid item xs={12} className={classes.form}>
            <TabComponent onForgot={() => setSelectedTab('Forgot')} onSubmit={onSubmit} />
          </Grid>
          {showGuestLink && (
            <Grid item xs={12}>
              <Link to={routes.auth.guest} onClick={() => setSelectedTab('Guest')}>
                <Typography component="h1" className={classes.link} variant="h6">
                  Making a one off order? <b>Shop as a guest here</b>
                </Typography>
              </Link>
            </Grid>
          )}
        </Container>
      </Paper>
    </Container>
  );
};

Home.propTypes = {
  tab: PropTypes.string,
};

Home.defaultProps = {
  tab: 'LogIn',
};

export default Home;
