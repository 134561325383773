/* eslint-disable react/prop-types */ // TODO: HACK - Investigate why eslint throws a lot of errors on this page
import React from 'react';
import { Route, Switch } from 'react-router-dom';

// Material-UI
import Grid from '@material-ui/core/Grid';

// Config
import constants from './config/constants';
import routes from './config/routes';

// Hooks
import useCartRestoreItems from './hooks/useCartRestoreItems';
import useProductsLoad from './hooks/useProductsLoad';
import useUserKeepAlive from './hooks/useUserKeepAlive';

// Components
import Cart from './components/Cart';
import Contact from './components/Contact';
import Faq from './components/Faq';
import Favourites from './components/Favourites';
import Loading from './components/shared/Loading';
import ModalCartDelete from './components/modals/ModalCartDelete';
import ModalOrderDelete from './components/modals/ModalOrderDelete';
import ModalOrderDeleteLastLineItem from './components/modals/ModalOrderDeleteLastLineItem';
import ModalOrderError from './components/modals/ModalOrderError';
import NotFound from './components/errors/NotFound';
import Order from './components/Order';
import OrderProcessing from './components/OrderProcessing';
import OrderConfirmed from './components/OrderConfirmed';
import OrderPaid from './components/OrderPaid';
import Orders from './components/Orders';
import Product from './components/Product';
import Products from './components/Products';
import Profile from './components/Profile';
import ScrollToTop from './components/shared/ScrollToTop';
import Terms from './components/Terms';

// import ModalCartConfirm from '.components/modals/ModalCartConfirm';
// import ModalOrderDate from '.components/modals/ModalOrderDate';
// import ModalOrderError from '.components/modals/ModalOrderError';
// import ModalOrderSuccess from '.components/modals/ModalOrderSuccess';

export default function AppAuthenticated() {
  // Keep the users session token alive, otherwise it will expire after 10 minutes.
  useUserKeepAlive();

  // Restore the users cart from localStorage.
  useCartRestoreItems();

  // Load the products
  const { productsLoading } = useProductsLoad();

  if (productsLoading) return <Loading loading mode="full" background="solid" title={`Loading ${constants.appName}`} />;

  return (
    <>
      <Route
        render={({ location }) => (
          <Grid container direction="column" justifyContent="center" alignItems="center">
            <Switch location={location}>
              {/* Favourites */}
              <Route exact path={routes.private.favourites} component={Favourites} />

              {/* Products */}
              <Route
                exact
                path={routes.private.products}
                render={(props) => <Products currentUrl={props.location.pathname} search={props.location.search} />}
              />
              <Route
                path={`${routes.private.product}/:oid`}
                render={(props) => <Product currentUrl={props.location.pathname} productId={props.match.params.oid} />}
              />

              {/* Orders */}
              <Route exact path={routes.private.order} render={(props) => <Order currentUrl={props.location.pathname} />} />
              <Route
                exact
                path={`${routes.private.order_confirm}/:oid`}
                render={(props) => <OrderConfirmed currentUrl={props.location.pathname} orderId={props.match.params.oid} />}
              />
              <Route
                exact
                path={`${routes.private.order_paid}/:oid`}
                render={(props) => <OrderPaid currentUrl={props.location.pathname} orderId={props.match.params.oid} search={props.location.search} />}
              />
              <Route path={`${routes.private.orders}/:tab`} render={(props) => <Orders currentUrl={props.location.pathname} tab={props.match.params.tab} />} />
              <Route exact path={`${routes.private.order_processing}`} render={(props) => <OrderProcessing location={props.location} />} />

              {/* Profile */}
              <Route exact path={routes.private.profile} render={(props) => <Profile currentUrl={props.location.pathname} />} />

              {/* Public */}
              <Route exact path={routes.private.terms} render={(props) => <Terms currentUrl={props.location.pathname} />} />
              <Route exact path={routes.private.faq} render={(props) => <Faq currentUrl={props.location.pathname} />} />
              <Route exact path={routes.private.contact} render={(props) => <Contact currentUrl={props.location.pathname} />} />

              {/* 404 Not Found - catch-all component */}
              <Route component={NotFound} />
            </Switch>
            <ScrollToTop />
          </Grid>
        )}
      />
      <Cart />
      <ModalCartDelete />
      <ModalOrderDelete />
      <ModalOrderDeleteLastLineItem />
      <ModalOrderError />

      {/* <ModalCartConfirm />
      <ModalOrderDate />
      <ModalOrderSuccess /> */}
    </>
  );
}
