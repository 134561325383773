import axios from 'axios';
// import './mock';

// Config
import constants from '../config/constants';

// Helpers
import { translateApiError } from '../helpers/translateApiError';

const fields = {
  name: '',
  email: '',

  accountNo: '',
  gstNo: '', // ABN No
  creditStatus: '',
  outstanding: '',
  price: '',
  statementFrequency: '',
  minimumOrderValue: '',

  contactName: '',
  contactMobile: '',
  contactPhone: '',

  deliveryAddress: '',
  deliveryAddress1: '',
  deliveryAddress2: '',
  deliveryAddress3: '',
  deliveryAddress4: '',
  invoiceName: '',

  rBKdebtorContact: {
    name: '',
    email: '',
    mobile: '',
    phone: '',
  },
  rBKrunLine: {
    // comment: '',
    default: '',
    // dropNo: '',
    // notes: '',
    deliveryOkMon1: '',
    deliveryOkTue1: '',
    deliveryOkWed1: '',
    deliveryOkThu1: '',
    deliveryOkFri1: '',
    deliveryOkSat1: '',
    deliveryOkSun1: '',
    // rBKrun: {
    //   active: '',
    //   description: '',
    // },
  },
  rBKpriceBook: {
    code: '',
    description: '',
  },
};

export const activateUser = (username, password) => {
  if (!username || !password) return Promise.reject(new Error('Authenticating User Failed: Username and password is missing'));

  const data = {
    command: 'ValidateUser',
    parameters: {
      class: 'BKwebUser',
      userid: username,
      password,
      properties: {
        userid: '',
        rBKwebUserLink: {
          rBKdebtor: fields,
        },
      },
    },
  };

  // console.log(`API REQUEST : activateUser :: username = ${username}`);

  return axios
    .post(constants.apiUrl, data)
    .then((response) => translateApiError(response, data))
    .then((response) => response.data);
};

export const reactivateUser = (token, apiUrl) => {
  const data = {
    command: 'KeepAlive',
    parameters: {
      token,
    },
  };

  // console.log(`API REQUEST : reactivateUser :: token = ${token} :: = `, JSON.stringify(data));

  return axios
    .post(apiUrl || constants.apiUrl, data)
    .then((response) => translateApiError(response, data))
    .then((response) => response.data);
};

export const fetchUser = (token, debtorId) => {
  if (!token || !debtorId) return Promise.reject(new Error('Fetching User Failed: Debtor token is missing'));

  const data = {
    command: 'GetClassObject',
    parameters: {
      token,
      oid: debtorId,
      properties: fields,
    },
  };

  // console.log(`API REQUEST : fetchUser :: token = ${token} :: debtorId = ${debtorId} :: data = `, JSON.stringify(data));

  return axios
    .post(constants.apiUrl, data)
    .then((response) => translateApiError(response, data))
    .then((response) => response.data);
};
