// Helper
import { localTime } from './date';

export const getProductDeliveryCutoff = (subCategory, localTimezone = false) => {
  let utcHour;
  const categoryName = subCategory && subCategory.trim().toLowerCase();

  switch (categoryName) {
    case 'salads':
    case 'sauces':
      // eslint-disable-next-line fp/no-mutation
      utcHour = 11; // 11am Sydney (AEST)
      break;

    // case 'sunday':
    //   // eslint-disable-next-line fp/no-mutation
    //   utcHour = 15; // 3pm Sydney (AEST)
    //   break;

    case 'sandwiches/wraps':
      // eslint-disable-next-line fp/no-mutation
      utcHour = 12; // 12pm Sydney (AEST)
      break;

    case 'high end desserts':
    case 'macarons':
      // eslint-disable-next-line fp/no-mutation
      utcHour = 13; // 1pm Sydney (AEST)
      break;

    default:
      // eslint-disable-next-line fp/no-mutation
      utcHour = 15; // 3pm Sydney (AEST)
  }

  return localTimezone === false ? utcHour : localTime(utcHour);
};

export { getProductDeliveryCutoff as default };
