import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getUser, getUserLoading } from '../reducers';
import { authenticateUser } from '../actions/users';

const useUserAuthenticate = (credentals) => {
  const dispatch = useDispatch();
  const userLoading = useSelector(getUserLoading);
  const user = useSelector(getUser);

  useEffect(() => {
    dispatch(authenticateUser(credentals.username, credentals.password));
  }, [dispatch, credentals]);

  return { userLoading, user };
};

export default useUserAuthenticate;
