import React from 'react';
import PropTypes from 'prop-types';

// Material-UI
import { makeStyles } from '@material-ui/core/styles';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

// Config
import constants from '../../config/constants';

// Hooks
import useForm from '../../hooks/useForm';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    margin: theme.spacing(3, 0),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const ForgotForm = ({ onSubmit }) => {
  const classes = useStyles();

  const { inputs, handleInputChange, handleSubmit } = useForm({ email: '' }, onSubmit);

  return (
    <ValidatorForm className={classes.form} onSubmit={handleSubmit}>
      <Grid container spacing={constants.gridSpacing}>
        <Grid item xs={12}>
          <TextValidator
            id="email"
            name="email"
            value={inputs.email}
            label="Email Address"
            variant="outlined"
            fullWidth
            autoFocus
            autoComplete="email"
            validators={['required', 'isEmail']}
            errorMessages={['Please enter an email address', 'Please enter a valid email address']}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid container item xs={12} justifyContent="flex-end">
          <Button type="submit" variant="contained" color="primary" className={classes.submit} position="right">
            Reset Password
          </Button>
        </Grid>
      </Grid>
    </ValidatorForm>
  );
};

ForgotForm.propTypes = {
  onSubmit: PropTypes.func,
};

ForgotForm.defaultProps = {
  onSubmit: () => {},
};

export default ForgotForm;
