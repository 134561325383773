// Date
import dayjs from 'dayjs';

// Hooks
import useOrdersLoad from './useOrdersLoad';

// Helpers
import { dollars } from '../helpers/number';

const useOrdersDataTableLoad = (type, limit, offset) => {
  const { ordersLoading, orders, ordersLength } = useOrdersLoad(type, limit, offset);

  const manipulatedOrders = Object.entries(orders).reduce(
    (obj, [key, invoice]) => [...obj, [dayjs(invoice.date).format('dddd, MMMM D, YYYY'), invoice.orderNo, dollars(invoice.gross), invoice]],
    [],
  );

  return { orderDataTableLoading: ordersLoading, ordersDataTable: manipulatedOrders, orderDataTableLength: ordersLength };
};

export default useOrdersDataTableLoad;
