import React, { useState, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import * as QueryString from 'query-string';

// Material-UI
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// Config
import constants from '../config/constants';
import routes from '../config/routes';

// Hooks
import useProductsLoad from '../hooks/useProductsLoad';
import useFavouritesLoad from '../hooks/useFavouritesLoad';

// Components
import Banners from './shared/Banners';
import Layout from './shared/Layout';
import LinkLoadMore from './shared/LinkLoadMore';
import ProductDeliveryWarning from './shared/ProductDeliveryWarning';
import ProductFilter from './shared/ProductFilter';
import ProductsCategories from './ProductsCategories';
import ProductsList from './shared/ProductsList';

// Styles
const useStyles = makeStyles((theme) => ({
  drop: theme.drop,
  dropHalf: theme.dropHalf,
}));

const Products = ({ currentUrl, search }) => {
  const classes = useStyles();
  const history = useHistory();

  // Convert the query string into an object we can use to populate the product filter.
  const params = useMemo(() => QueryString.parse(search), [search]);

  // Search
  const searchString = params.q;
  const optionCategory = params.category;
  const optionSubcategory = params.subcategory;
  const optionSize = params.size;
  const optionPackaging = params.packaging;
  const optionSliced = params.sliced;
  const optionFlavour = params.flavour;

  // Products
  const limit = 12;
  const [nextLimit, setLimit] = useState(limit);
  const [nextOffset] = useState(0);
  const {
    productsLoading,
    products,
    productsCount,
    productsLimitNext,
    ingredientsLoading,
    ingredients,
    ingredientsCount,
    // ingredientsLimitNext,
    options,
    optionsFiltered,
  } = useProductsLoad(nextLimit, nextOffset, searchString, optionCategory, optionSubcategory, optionSize, optionPackaging, optionSliced, optionFlavour);

  const categories = (options && options.subcategory) || [];

  // Load the favourite information, which is used to flag a product as a favourite or not.
  useFavouritesLoad('all');

  const onLoadMore = useCallback(() => {
    setLimit(nextLimit + limit);
  }, [nextLimit, limit]);

  // Search on the search string of filtering options.
  const onSearch = useCallback(
    ({ searchTerm = null, category = null, subcategory = null, size = null, packaging = null, sliced = null, flavour = null }) => {
      const stringified = QueryString.stringify({
        q: (searchTerm ?? searchString) || undefined,
        category: (category ?? optionCategory) || undefined,
        subcategory: (subcategory ?? optionSubcategory) || undefined,
        size: (size ?? optionSize) || undefined,
        packaging: (packaging ?? optionPackaging) || undefined,
        sliced: (sliced ?? optionSliced) || undefined,
        flavour: (flavour ?? optionFlavour) || undefined,
      });

      // Redirect - Update the URL and query string to include the filter params, this allowing the search to populate the product filter on page refresh.
      if (search !== stringified) {
        history.push({
          pathname: routes.private.products,
          search: stringified ? `?${stringified}` : null,
        });
      }
    },
    [history, search, searchString, optionCategory, optionSubcategory, optionSize, optionPackaging, optionSliced, optionFlavour],
  );

  const onSelectSubcategory = useCallback((subcategory) => onSearch({ subcategory }), [onSearch]);

  return (
    <Layout currentUrl={currentUrl}>
      <Grid className={classes.dropHalf}>
        <Banners mode="header" />
      </Grid>
      <Grid container spacing={constants.gridSpacing} justifyContent="flex-end" className={classes.drop}>
        <Grid item xs={12}>
          <Typography variant="h3" align="center" className={classes.margin}>
            Our Products
          </Typography>
        </Grid>

        <ProductDeliveryWarning category={optionSubcategory} />

        <Grid item xs={12} md={3}>
          <ProductFilter
            search={searchString}
            category={optionCategory}
            subcategory={optionSubcategory}
            size={optionSize}
            packaging={optionPackaging}
            sliced={optionSliced}
            flavour={optionFlavour}
            options={optionsFiltered}
            onSearch={onSearch}
          />
        </Grid>
        <Grid item xs={12} md={9}>
          <Grid container spacing={constants.gridSpacing}>
            <ProductsList products={products} loading={productsLoading} showLinks />
            <ProductsList products={ingredients} loading={ingredientsLoading} showLinks />
            <ProductsList loading={productsLoading && ingredientsLoading} showEmptyList={productsCount + ingredientsCount < 1} />
          </Grid>
        </Grid>
        <LinkLoadMore loading={productsLoading} hidden={nextLimit >= productsLimitNext} onClick={onLoadMore} />
      </Grid>
      <Grid container spacing={constants.gridSpacing} className={classes.drop}>
        <Grid item xs={12}>
          <Typography variant="h3">Browse by Category</Typography>
        </Grid>
        <Grid item xs={12}>
          <ProductsCategories
            currentUrl={`${currentUrl}${search}`}
            categories={categories}
            loading={productsLoading}
            onSelectSubcategory={onSelectSubcategory}
          />
        </Grid>
      </Grid>
      <Grid container spacing={constants.gridSpacing} className={classes.drop}>
        <Banners />
      </Grid>
    </Layout>
  );
};

Products.propTypes = {
  currentUrl: PropTypes.string,
  search: PropTypes.string,
};

Products.defaultProps = {
  currentUrl: '/',
  search: '',
};

export default Products;
