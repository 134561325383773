import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';

import firebase from 'firebase/compat/app';
import 'firebase/compat/analytics';

// Material-UI
import { ThemeProvider } from '@material-ui/core/styles';

// Config
import constants from './config/constants';

// Hooks
import cacheBuster from './hooks/useCacheBuster';
import useUserLoad from './hooks/useUserLoad';

// Components
import AppBody from './AppBody';
import Loading from './components/shared/Loading';
import Notifications from './components/shared/Notifications';

// Styles
import theme from './theme/theme';

export default function App() {
  const { userLoading, user } = useUserLoad();

  useEffect(() => {
    // Confirm the user is using the latest build.
    cacheBuster();

    if (!firebase.apps.length) {
      firebase.initializeApp({
        apiKey: 'AIzaSyAxYSctgk3Nw-rvd4UMJoeNSoK1IKe6aAA',
        authDomain: 'vse---ordering-portal.firebaseapp.com',
        projectId: 'vse---ordering-portal',
        storageBucket: 'vse---ordering-portal.appspot.com',
        messagingSenderId: '529729542553',
        appId: '1:529729542553:web:879edf94fe9b9063489b08',
        measurementId: 'G-JRZVLQYQ5S',
      });
      firebase.analytics();
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Loading loading={userLoading} mode="full" background="solid" title={`Loading ${constants.appName}`} />
      <Notifications>
        <BrowserRouter>
          <AppBody loading={userLoading} user={user} />
        </BrowserRouter>
      </Notifications>
    </ThemeProvider>
  );
}
