import React from 'react';
import PropTypes from 'prop-types';

// Material-UI
import { makeStyles } from '@material-ui/core/styles';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import BuildOutlinedIcon from '@material-ui/icons/BuildOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import Divider from '@material-ui/core/Divider';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import EmojiFoodBeverageOutlinedIcon from '@material-ui/icons/EmojiFoodBeverageOutlined';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import FavoriteBorderOutlinedIcon from '@material-ui/icons/FavoriteBorderOutlined';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined';

// Config
import routes from '../../config/routes';

// Actions
import { setUserExpired } from '../../actions/users';

// Hooks
import useAction from '../../hooks/useAction';

// Helpers
import { isActiveLink } from '../../helpers/isActiveLink';

// Components
import Link from './Link';

// App Version
import packageJson from '../../../package.json';

const useStyles = makeStyles(() => ({
  navigation: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  grow: {
    flexGrow: 1,
    position: 'relative',
    top: 0,
    left: 0,
  },
}));

const Navigation = ({ currentUrl }) => {
  const classes = useStyles();

  const onLogout = useAction(() => setUserExpired());

  const handleLogout = (event) => {
    event.preventDefault();
    onLogout();
  };

  // App Version
  const appVersion = packageJson.version;

  return (
    <List className={classes.navigation}>
      <List>
        <Link color="inherit" underline="none" to={routes.private.favourites}>
          <ListItem button key="menuHome" selected={isActiveLink(currentUrl, routes.private.favourites)}>
            <ListItemIcon>
              <FavoriteBorderOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Favourites" />
          </ListItem>
        </Link>
        <Link color="inherit" underline="none" to={routes.private.products}>
          <ListItem button key="menuProducts" selected={isActiveLink(currentUrl, routes.private.product, true)}>
            <ListItemIcon>
              <EmojiFoodBeverageOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Products" />
          </ListItem>
        </Link>
        <Link color="inherit" underline="none" to={`${routes.private.orders}/scheduled`}>
          <ListItem button key="menuProfile" selected={isActiveLink(currentUrl, routes.private.orders, true)}>
            <ListItemIcon>
              <ReceiptOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Orders" />
          </ListItem>
        </Link>
        <Link color="inherit" underline="none" to={routes.public.faq}>
          <ListItem button key="menuFaq" selected={isActiveLink(currentUrl, routes.public.faq)}>
            <ListItemIcon>
              <HelpOutlineOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="FAQ's" />
          </ListItem>
        </Link>
        <Link color="inherit" underline="none" to={routes.public.terms}>
          <ListItem button key="menuTerms" selected={isActiveLink(currentUrl, routes.public.terms)}>
            <ListItemIcon>
              <DescriptionOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Terms &amp; Conditions" />
          </ListItem>
        </Link>
        <Link color="inherit" underline="none" to={routes.public.contact}>
          <ListItem button key="menuContact" selected={isActiveLink(currentUrl, routes.public.contact)}>
            <ListItemIcon>
              <EmailOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Contact Us" />
          </ListItem>
        </Link>
        <Link color="inherit" underline="none" to={routes.private.profile}>
          <ListItem button key="menuProfile" selected={isActiveLink(currentUrl, routes.private.profile)}>
            <ListItemIcon>
              <AccountCircleOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Profile" />
          </ListItem>
        </Link>
      </List>
      <div className={classes.grow} />
      <List>
        <ListItem button key="menuVersion" disabled>
          <ListItemIcon>
            <BuildOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary={`Bundle Version - v${appVersion}`} />
        </ListItem>
      </List>
      <Divider />
      <List>
        <Link color="inherit" underline="none" to={routes.auth.logout} onClick={handleLogout}>
          <ListItem button key="menuLogout">
            <ListItemIcon>
              <ExitToAppOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </Link>
      </List>
    </List>
  );
};

Navigation.propTypes = {
  currentUrl: PropTypes.string,
};

Navigation.defaultProps = {
  currentUrl: '/',
};

export default Navigation;
