import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { getFavourites } from '../reducers';
import { getProductRelatedIds } from '../helpers/getProductRelatedIds';

const useProductFavourite = (oid, productOptionsMatrix) => {
  const favourites = useSelector(getFavourites);

  if (!oid) return false;

  // Get all products ID's related to this product.
  const products = getProductRelatedIds(productOptionsMatrix);
  let result = {};

  // Check each related product and check if anyone of them is a favourite.
  for (const productId of products) {
    if (favourites && favourites[productId] && favourites[productId].webFavourite) {
      // eslint-disable-next-line fp/no-mutation
      result = { favourite: favourites[productId], productId };
      break;
    }
  }

  // If one of the related products is a favourite, return that, otherwise return the favourite settings for this product.
  return isEmpty(result) ? { favourite: favourites[oid], productId: oid } : result;
};

export default useProductFavourite;
