import dayjs from 'dayjs';
import { saveOrder } from '../api/Orders';
import { savePayment } from '../api/Payments';

// Date

// Config
import routes from '../config/routes';

// Actions
import { setErrorModal } from './notifications';
import { setUserExpired } from './users';
import { showModal } from './modals';

export const setCartLoading = () => ({
  type: 'SET_CART_LOADING',
});

export const setCartError = (item) => ({
  type: 'SET_CART_ERROR',
  item,
});

export const setCartItems = (items) => ({
  type: 'SET_CART_ITEMS',
  items,
});

export const setCartItem = (item) => ({
  type: 'SET_CART_ITEM',
  item,
});

export const updateCartItem = (item) => ({
  type: 'UPDATE_CART_ITEM',
  item,
});

export const removeCartItem = (item) => ({
  type: 'REMOVE_CART_ITEM',
  item,
});

export const setCartDate = (date) => ({
  type: 'SET_CART_DATE',
  date,
});

export const setCartOrderNumber = (cartOrderNumber) => ({
  type: 'SET_CART_ORDER_NUMBER',
  cartOrderNumber,
});

export const setCartDeliveryCutoff = (cutoff) => ({
  type: 'SET_CART_DELIVERY_CUTOFF',
  cutoff,
});

export const setCartPaymentRefernce = (paymentReference) => ({
  type: 'SET_CART_PAYMENT_REFERENCE',
  paymentReference,
});

export const removeAllCartItems = () => ({
  type: 'REMOVE_ALL_CART_ITEMS',
});

export const clearCart = () => async (dispatch) => {
  dispatch(removeAllCartItems());
};

export const orderCartItemsOnAccount = (date, items, orderNo, payment) => async (dispatch) => {
  dispatch(setCartLoading());

  const token = localStorage.getItem('tokens'); // Retrieve the token from localStorage. The token will be used if the user refreshes the page.
  const debtorId = localStorage.getItem('debtor_id'); // Retrieve the debtor_id from localStorage. The debtor_id is used to fetch related records from the API
  const orderNumber = orderNo || localStorage.getItem('cartOrderNumber'); // Retrieve the cartOrderNumber from localStorage.
  const orderDate = date || localStorage.getItem('cartDate'); // Retrieve the cartDate from localStorage.
  const orderItems = items || JSON.parse(localStorage.getItem('cartItems')); // Retrieve the cartItems from localStorage.

  if (!orderDate || !orderItems) return false;

  return saveOrder(token, debtorId, orderNumber, orderDate, orderItems)
    .then((response) => {
      const orderId = response.data.changedObjects[0].savedOid;
      dispatch(clearCart());
      // eslint-disable-next-line fp/no-mutation
      if (orderId) window.location.href = `${routes.private.order_confirm}/${orderId}`; // Redirect the user to the invoice screen
      return response;
    })
    .catch((error) => {
      if (error.message) dispatch(setErrorModal(String(error.message)));
      if (error.expired) dispatch(setUserExpired());

      dispatch(setCartError());
      dispatch(showModal('order_error', true));
      return error;
    });
};

export const orderCartItemsWithCreditCard = (paymentDate, items, orderNo, amount, paymentReference, paymentNumber) => async (dispatch) => {
  dispatch(setCartLoading());

  const token = localStorage.getItem('tokens'); // Retrieve the token from localStorage. The token will be used if the user refreshes the page.
  const debtorId = localStorage.getItem('debtor_id'); // Retrieve the debtor_id from localStorage. The debtor_id is used to fetch related records from the API
  const accountNo = localStorage.getItem('account_no'); // Retrieve the debtor_id from localStorage. The debtor_id is used to fetch related records from the API
  const orderNumber = orderNo || localStorage.getItem('cartOrderNumber'); // Retrieve the cartOrderNumber from localStorage.
  const orderItems = items || JSON.parse(localStorage.getItem('cartItems')); // Retrieve the cartItems from localStorage.
  const orderDate = localStorage.getItem('cartDate'); // Retrieve the cartDate from localStorage.
  const formattedOrderDateJS = orderDate.length >= 13 ? orderDate : parseInt(`${orderDate}000`, 10); // Retrieve the cartDate from localStorage.
  const formattedOrderDate = dayjs(formattedOrderDateJS).format('YYYY-MM-DD'); // Retrieve the cartDate from localStorage.
  const formattedPaymentDateJS = paymentDate.length >= 13 ? paymentDate : parseInt(`${paymentDate}000`, 10); // Retrieve the cartDate from localStorage.
  const formattedPaymentDate = dayjs(formattedPaymentDateJS).format('YYYY-MM-DD'); // Retrieve the cartDate from localStorage.

  if (!formattedOrderDate || !amount || !paymentReference || !orderItems) return false;

  return savePayment(token, debtorId, orderNumber, accountNo, amount, formattedOrderDate, orderItems, formattedPaymentDate, paymentReference, paymentNumber)
    .then((response) => {
      const orderId = response.docketId;
      dispatch(clearCart());
      // eslint-disable-next-line fp/no-mutation
      if (orderId) window.location.href = `${routes.private.order_confirm}/${orderId}`; // Redirect the user to the invoice screen

      return response;
    })
    .catch((error) => {
      if (error.message) dispatch(setErrorModal(String(error.message)));
      if (error.expired) dispatch(setUserExpired());
      return error;
    });
};
