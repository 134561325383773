import { combineReducers } from 'redux';
import isEmpty from 'lodash/isEmpty';

// Config
import constants from '../config/constants';

const userId = (state = null, action) => {
  switch (action.type) {
    case 'SET_SESSION_DATA': {
      const debtorId = 'oid' in action.user ? action.user.oid : '';
      localStorage.setItem('debtor_id', debtorId);
      return debtorId;
    }
    default:
      return state;
  }
};

const accountNo = (state = null, action) => {
  switch (action.type) {
    case 'SET_SESSION_DATA': {
      const accountNumber = 'accountNo' in action.user ? action.user.accountNo : '';
      localStorage.setItem('account_no', accountNumber);
      return accountNumber;
    }
    default:
      return state;
  }
};

const priceCode = (state = null, action) => {
  switch (action.type) {
    case 'SET_SESSION_DATA': {
      const priceBookCode = 'rBKpriceBook' in action.user ? action.user.rBKpriceBook.code : '';
      localStorage.setItem('price_code', priceBookCode);
      return priceBookCode;
    }
    default:
      return state;
  }
};

const userLoading = (state = true, action) => {
  switch (action.type) {
    case 'SET_USER_LOADED':
      return false;
    case 'SET_USER_LOADING':
      return true;
    case 'SET_SESSION_DATA':
    case 'SET_USER_SAVED':
      return false;
    default:
      return state;
  }
};

const userOutstanding = (state = null, action) => {
  switch (action.type) {
    case 'SET_SESSION_DATA':
      return 'rBKdebtor' in action.user ? action.user.rBKdebtor.outstanding : 0;
    default:
      return state;
  }
};

const userToken = (state = null, action) => {
  switch (action.type) {
    case 'SET_SESSION_DATA': {
      if (action.user.token === undefined) return state;
      const token = 'oid' in action.user ? action.user.token : '';
      localStorage.setItem('tokens', token); // Store the token into localStorage. The token will be used if the user refreshes the page.
      return token;
    }
    case 'SET_SESSION_EXPIRED':
    case 'CLEAR_SESSION_DATA':
      console.log('REMOVING SESSION INFORMATION');
      localStorage.removeItem('account_no'); // Remove the account_no from localStorage. The account_no is used to fetch related records from the API
      localStorage.removeItem('cartDate'); // Remove the cartDate from localStorage.
      localStorage.removeItem('cartItems'); // Remove the cartItems from localStorage.
      localStorage.removeItem('cartOrderNumber'); // Remove the cartOrderNumber from localStorage.
      localStorage.removeItem('debtor_id'); // Remove the debtor_id from localStorage. The debtor_id is used to fetch related records from the API
      localStorage.removeItem('favourites'); // Remove the favourites from localStorage.
      localStorage.removeItem('price_code'); // Remove the price_code from localStorage. The price_code is used to fetch related records from the API
      localStorage.removeItem('products'); // Remove the products from localStorage.
      localStorage.removeItem('tokens'); // Remove the token from localStorage. The token is used if the user refreshes the page.
      localStorage.removeItem('blacklist_products'); // Remove the blacklist products from localStorage.
      // eslint-disable-next-line fp/no-mutation
      // Delete browser cache and hard reload
      window.location.reload(true);
      return null;
    default:
      return state;
  }
};

const contact = (state = null, action) => {
  switch (action.type) {
    case 'SET_SESSION_DATA':
      return 'rBKdebtorContact' in action.user && action.user.rBKdebtorContact && !isEmpty(action.user.rBKdebtorContact) ? action.user.rBKdebtorContact[0] : {};
    default:
      return state;
  }
};

const data = (state = null, action) => {
  switch (action.type) {
    case 'SET_SESSION_DATA':
      return 'oid' in action.user ? action.user : {};
    default:
      return state;
  }
};

const expiresIn = (state = false, action) => {
  switch (action.type) {
    case 'SET_SESSION_DATA':
      if (action.user.tokenLifetimeMins === undefined) return null;
      return action.user.tokenLifetimeMins;
    case 'SET_SESSION_EXPIRES':
      return action.sessionExpiresIn;
    default:
      return state;
  }
};

export default combineReducers({
  userId,
  accountNo,
  priceCode,
  userLoading,
  userOutstanding,
  userToken,
  contact,
  data,
  expiresIn,
});

export const getContact = (state) => state.contact;
export const getMinimumOrderValue = (state) => state.data.minimumOrderValue || constants.deliveryCost;
export const getUser = (state) => state.data;
export const getUserLoading = (state) => state.userLoading;
export const getUserName = (state) => state.data.name;
export const getUserOutstanding = (state) => state.userOutstanding;
