import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

// Config
import constants from '../config/constants';

// Actions
import { setCartDeliveryCutoff } from '../actions/cart';

// Helpers
import { getProductDeliveryCutoff } from '../helpers/getProductDeliveryCutoff';
import { parseFloat2dp } from '../helpers/number';

// Hooks
import useAction from './useAction';

// Reducers
import { getCartItems, getCartLoading } from '../reducers';

const useCartItems = () => {
  const cartItems = useSelector(getCartItems);
  const cartLength = Object.keys(cartItems).length;
  const cartLoading = useSelector(getCartLoading);
  const defaultDeliveryCutoff = getProductDeliveryCutoff('default', false);
  const saveCartDeliveryCutoff = useAction(setCartDeliveryCutoff);
  const [earliestDeliveryCutoff, setEarliestDeliveryCutoff] = useState(defaultDeliveryCutoff);

  // Store the cart into localStorage. The cart will be used if the user refreshes the page.
  useEffect(() => {
    if (!isEmpty(cartItems)) localStorage.setItem('cartItems', JSON.stringify(cartItems));
  }, [cartItems]);

  // eslint-disable-next-line fp/no-mutating-methods
  const sortedCartItems = Object.entries(cartItems)
    .sort((a, b) =>
      a[1].webProductName.trim().localeCompare(b[1].webProductName.trim(), undefined, {
        numeric: true,
        sensitivity: 'base',
      }),
    )

    // Manipulate the result into something more suitable for our application.
    .reduce(
      (a, [, item]) => ({
        ...a,
        [item.oid]: item,
      }),
      {},
    );

  // Store the cart delivery cutoff if changed.
  useEffect(() => {
    saveCartDeliveryCutoff(earliestDeliveryCutoff);
  }, [saveCartDeliveryCutoff, earliestDeliveryCutoff]);

  // Calculate the cart net total from all items and figure out the earliest delivery time.
  let cartNet = 0;
  let cartNetGst = 0;
  let cartDelivery = 0;
  let hasDelivery = false;
  if (cartLength > 0) {
    Object.values(sortedCartItems).map((item) => {
      if (item.oid === constants.deliverySurchargeOid) {
        const itemPrice = parseFloat2dp(item.sellingPrice) || 0;
        // eslint-disable-next-line fp/no-mutation
        cartDelivery += itemPrice;
        // eslint-disable-next-line fp/no-mutation
        hasDelivery = true;

        // eslint-disable-next-line fp/no-mutation
        cartNet += itemPrice;
        // eslint-disable-next-line fp/no-mutation
        cartNetGst += itemPrice;

        return cartDelivery;
      }

      // Get the earliest delivery time of store it.
      const cutoff = getProductDeliveryCutoff(item.subCategory, false);
      if (!earliestDeliveryCutoff || cutoff < earliestDeliveryCutoff) setEarliestDeliveryCutoff(cutoff);

      // Calculate the lineitem total
      const itemPrice = parseFloat2dp(item.sellingPrice) || 0;
      const itemQuantity = parseInt(item.quantity, 10) || 1;
      const itemTotal = itemPrice * itemQuantity;
      // eslint-disable-next-line fp/no-mutation
      cartNet += itemTotal;
      // eslint-disable-next-line fp/no-mutation
      cartNetGst += item.exemptGst ? 0 : itemTotal;

      return cartNet;
    });
  } else {
    // Get the default delivery time and reset it as our earliest delivery cutoff.
    const cutoff = getProductDeliveryCutoff('default', false);
    if (cutoff !== earliestDeliveryCutoff) setEarliestDeliveryCutoff(cutoff);
  }

  // Get a summary of the cart in a very basic format.
  const cartItemsSummary = Object.entries(sortedCartItems).reduce(
    (a, [, product]) => ({
      ...a,
      [product.oid]: product.oid === constants.deliverySurchargeOid ? product.sellingPrice : product.quantity,
    }),
    {},
  );

  // Calculate the cart delivery, GST & total.
  const cartGst = (cartNetGst / 100) * constants.gstPercent;
  const cartTotal = cartNet + cartGst;

  return {
    cartLoading,
    cartItems: sortedCartItems,
    cartLength,
    cartTotal,
    cartNet,
    cartGst,
    cartDelivery,
    cartItemsSummary,
    hasDelivery,
  };
};

export default useCartItems;
