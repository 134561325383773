import React from 'react';
import { useSelector } from 'react-redux';

// Material-UI
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

// Actions
import { showModal, hideModal } from '../../actions/modals';

// Config
import constants from '../../config/constants';

// Hooks
import useAction from '../../hooks/useAction';
import useModalShow from '../../hooks/useModalShow';

// Reducers
import { getErrorModal } from '../../reducers';

// Styles
const useStyles = makeStyles((theme) => ({
  dialog: {},
  dialogContent: {
    textAlign: 'center',
    padding: theme.spacing(2, 2, 3, 2),
  },
  button: {
    marginLeft: theme.spacing(2),
  },
  header: {
    backgroundColor: theme.palette.action.selected,
    textAlign: 'center',
  },
  title: {
    padding: theme.spacing(2, 0, 0, 0),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  error: {
    whiteSpace: 'pre-wrap',
  },
  footer: {
    backgroundColor: theme.palette.action.selected,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    padding: theme.spacing(2),

    [theme.breakpoints.down('xs')]: {
      textAlign: 'right',
    },
  },
  totals: {
    [theme.breakpoints.up('sm')]: {
      order: 2,
    },
  },
  tooltip: {
    fontSize: 14,
    color: '#fff',
    padding: theme.spacing(1),
  },
}));

const ModalOrderError = () => {
  const classes = useStyles();

  const { openModal } = useModalShow();
  const onShowCartModal = useAction(() => showModal('cart', true));
  const onHideModal = useAction(hideModal);
  const errorModalMessage = useSelector(getErrorModal);
  const errorMessage = errorModalMessage || `We're sorry but your order cannot be placed right now.`;

  return (
    <Dialog open={openModal === 'order_error'} onClose={onHideModal} scroll="paper" fullWidth maxWidth="sm" className={classes.dialog}>
      <DialogTitle className={classes.header} disableTypography>
        <IconButton className={classes.closeButton} onClick={onHideModal}>
          <CloseIcon />
        </IconButton>
        <Typography variant="h4" className={classes.title}>
          Oh, No!
        </Typography>
      </DialogTitle>
      <DialogContent dividers className={classes.dialogContent}>
        <Grid container direction="column" justifyContent="center" alignItems="center">
          <Grid item xs={12}>
            <Typography className={classes.error}>{errorMessage}</Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.footer}>
        <Grid container justifyContent="flex-end" alignItems="flex-end" spacing={constants.gridSpacing}>
          <Grid item xs={12} sm={6} className={classes.totals} />
          <Grid item xs={12} sm={6} className={classes.actions}>
            <Button variant="contained" color="primary" onClick={onShowCartModal} className={classes.button}>
              Edit Order
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default ModalOrderError;
