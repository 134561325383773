import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
// import ReactHtmlParser from 'react-html-parser';

// Feature Flags
// import { FeatureFlag } from 'react-unleash-flags';

// Date
import dayjs from 'dayjs';
import DayJsUtils from '@date-io/dayjs';
import isTomorrow from 'dayjs/plugin/isTomorrow';

// Material-UI
import { makeStyles } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import CheckCircle from '@material-ui/icons/CheckCircle';
import Collapse from '@material-ui/core/Collapse';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import InfoIcon from '@material-ui/icons/Info';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';

// Config
import constants from '../config/constants';
import routes from '../config/routes';

// Actions
import { orderCartItemsOnAccount } from '../actions/cart';
import { setError } from '../actions/notifications';

// Helpers
import { getCartHasProduct } from '../helpers/getCartHasProduct';
import { getOrderDeliveryDates } from '../helpers/getOrderDeliveryDates';
import { getProductDeliveryCutoff } from '../helpers/getProductDeliveryCutoff';
import { localTime } from '../helpers/date';

// Hooks
import cacheBuster from '../hooks/useCacheBuster';
import useAction from '../hooks/useAction';
import useCartDate from '../hooks/useCartDate';
import useCartItems from '../hooks/useCartItems';
import useDeliveryDateNext from '../hooks/useDeliveryDateNext';
import useOrderNumber from '../hooks/useOrderNumber';
import useRegisterKeepAlive from '../hooks/useRegisterKeepAlive';
import useUserPayOnAccount from '../hooks/useUserPayOnAccount';

// Reducers
import { getDeliveryDates, getDeliveryCutoff } from '../reducers';

// Components
import Banners from './shared/Banners';
import CartList from './shared/CartList';
import Layout from './shared/Layout';
import Loading from './shared/Loading';
import OrderTotals from './shared/OrderTotals';
import ProductDeliveryWarning from './shared/ProductDeliveryWarning';
import CheckoutForm from './payment/StripeEmbeddedPaymentForm';

// import LinkLoadMore from './shared/LinkLoadMore';
// import ProductFilter from './shared/ProductFilter';
// import ProductsList from './shared/ProductsList';

// Styles
const useStyles = makeStyles((theme) => ({
  drop: theme.drop,
  dropHalf: theme.dropHalf,
  fullWidth: {
    width: '100%',
  },
  collapse: {
    marginLeft: 72,
  },
  button: {
    marginRight: theme.spacing(1),
  },
  capitalize: {
    textTransform: 'capitalize',
  },
  total: {
    height: 30,
    textAlign: 'right',
  },
  heading: {
    margin: theme.spacing(3, 2),
  },
  step: {
    backgroundColor: '#eee',
    width: 35,
    height: 35,
    borderRadius: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  selectedItem: {
    textTransform: 'capitalize',
    color: theme.palette.primary.secondary,
    marginLeft: theme.spacing(1),
  },
  box: {
    border: '1px solid #eee',
    borderRadius: 3,
    margin: theme.spacing(1),
    position: 'relative',
  },
  note: {
    margin: '0 auto',
    padding: theme.spacing(2, 3, 2, 6),
    position: 'relative',
    width: 'fit-content',

    '& a': {
      color: theme.palette.primary.main,
      textDecoration: 'none',
      '&:hover': {
        color: theme.palette.primary.dark,
      },
    },
  },
  noteIcon: {
    position: 'absolute',
    left: 14,
  },
  helpIcon: {
    fontSize: 14,
    position: 'absolute',
  },
  tooltip: {
    fontSize: 14,
    color: '#fff',
    padding: theme.spacing(1),
  },
}));

const Order = ({ currentUrl, search }) => {
  const classes = useStyles();

  // Confirm the user is using the latest build.
  cacheBuster();

  dayjs.extend(isTomorrow);

  // Step 0 - Global Behavour
  const { payOnAccount } = useUserPayOnAccount();

  const orderNo = useOrderNumber(true);
  const [open, setOpen] = useState('date');
  const { cartLoading, cartItems, cartLength, cartTotal, cartNet, cartGst, cartDelivery } = useCartItems();
  const hasSandwiches = getCartHasProduct('sandwiches/wraps', cartItems);
  const [hasError, setHasError] = useState(false);
  const [openStripeCheckout, setOpenStripeCheckout] = useState(false);

  // Step 1 - Type

  // const [selectedType, setType] = useState('single');

  // const onTypeStepClickSingle = useCallback(() => {
  //   setType('single');
  //   setOpen('date');
  // }, []);

  // const onTypeStepClickRecurring = useCallback(() => {
  //   showNotImplementedError();
  //   // setType('recurring');
  //   // setOpen('date');
  // }, [showNotImplementedError]);

  // Step 2 - Dates

  const { deliveryDateNext, deliveryDateNextLoading } = useDeliveryDateNext();
  const defaultDeliveryCutoff = getProductDeliveryCutoff('default', false);
  const cartDeliveryCutoff = useSelector(getDeliveryCutoff);
  const deliveryDates = useSelector(getDeliveryDates);
  const deliveryDatesAffectedByCartProducts = getOrderDeliveryDates(deliveryDates, cartItems);
  const isValidDeliveryDateNext = deliveryDateNext && dayjs(deliveryDateNext).isValid();
  const isNextDayDelivery = (isValidDeliveryDateNext && deliveryDateNext.isTomorrow()) || false;
  const [selectedDate, setSelectedDate] = useCartDate();
  const [selectedDateComplete, setDateComplete] = useState(false);

  // This confirms that the first available date is selected by default.
  useEffect(() => {
    if (!deliveryDateNextLoading && selectedDate.isBefore(deliveryDateNext)) {
      setSelectedDate(deliveryDateNext);
    }
  }, [deliveryDateNextLoading, deliveryDateNext, selectedDate, setSelectedDate]);

  const onDateStepNext = useCallback(() => {
    setDateComplete(!!selectedDate);
    setOpen('confirm');
  }, [selectedDate]);

  const onDateStepBack = useCallback(() => {
    setDateComplete(false);
    setOpen('type');
  }, []);

  const onDateChange = useCallback(
    (date) => {
      isValidDeliveryDateNext && setSelectedDate(dayjs(date));
    },
    [isValidDeliveryDateNext, setSelectedDate],
  );

  const disableDays = useCallback(
    (date) => {
      const day = date.format('dddd').toLowerCase();
      const dayDate = date.format('YYYY-MM-DD');
      const { deliveryHoliday } = constants;

      // If the current delivery date is a holiday, add one day and try again.
      const isHoliday = deliveryHoliday.includes(dayDate);

      return isHoliday || (deliveryDatesAffectedByCartProducts && !deliveryDatesAffectedByCartProducts[day]);
    },
    [deliveryDatesAffectedByCartProducts],
  );

  // Step 3 - Products

  // const [selectedProductsComplete, setProductsComplete] = useState(false);

  // const onProductStepDate = useCallback(() => {
  //   setProductsComplete(false);
  //   setOpen('date');
  // }, []);

  // const onProductStepConfirm = useCallback(() => {
  //   setProductsComplete(true);
  //   setOpen('confirm');
  // }, []);

  // // Search
  // const [searchString, setSearch] = useState(search || search);
  // const [optionCategory, setCategories] = useState(false);
  // const [optionSubcategory, setSubcategories] = useState(false);
  // const [optionSize, setSizes] = useState(false);
  // const [optionPackaging, setPackagings] = useState(false);
  // const [optionSliced, setSliced] = useState(false);
  // const [optionFlavour, setFlavours] = useState(false);

  // // Products
  // const limit = 12;
  // const [nextLimit, setLimit] = useState(limit);
  // const [nextOffset] = useState(0);

  // const { productsLoading, products, productsLimitNext, productsOptions } = useProductsLoad(
  //   nextLimit,
  //   nextOffset,
  //   searchString,
  //   optionCategory,
  //   optionSubcategory,
  //   optionSize,
  //   optionPackaging,
  //   optionSliced,
  //   optionFlavour,
  // );

  // // Load the favourite information, which is used to flag a product as a favourite or not.
  // useFavouritesLoad('all');

  // const onLoadMore = useCallback(() => {
  //   setLimit(nextLimit + limit);
  // }, [nextLimit, limit]);

  // // Search on the search string of filtering options.
  // const onSearch = useCallback((searchTerm, category, subcategory, size, packaging, sliced, flavour) => {
  //   setSearch(searchTerm);
  //   setCategories(category);
  //   setSubcategories(subcategory);
  //   setSizes(size);
  //   setPackagings(packaging);
  //   setSliced(sliced);
  //   setFlavours(flavour);
  // }, []);

  // Step 4 - Confirm

  const [selectedConfirmComplete, setConfirmComplete] = useState(false);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const onCartOrderConfirm = useAction(() => orderCartItemsOnAccount(selectedDate, cartItems));
  const onRegisterKeepAlive = useRegisterKeepAlive(orderNo, cartTotal);
  const showTokenRegisterError = useAction(() =>
    setError(
      'Sorry, there is an issue with credit card payments. Please refresh and try again. If the issue is still happening, please let us know so we can sort the issue out.',
    ),
  );

  const onConfirmStepBack = useCallback(() => {
    setConfirmComplete(false);
    setOpen('products');
  }, []);

  const onAccountSubmit = useCallback(() => {
    setConfirmComplete(true);
    onCartOrderConfirm();
  }, [setConfirmComplete, onCartOrderConfirm]);

  const onCreditCardSubmit = useCallback(() => {
    setConfirmComplete(true);
    setPaymentLoading(true);

    onRegisterKeepAlive()
      .then(() => setOpenStripeCheckout(true))
      .catch(() => {
        setPaymentLoading(false);
        showTokenRegisterError();
      });
  }, [onRegisterKeepAlive, showTokenRegisterError]);

  const onCloseStripeCheckout = useCallback(() => {
    setOpenStripeCheckout(false);
    setPaymentLoading(false);
  }, []);

  const [state, setState] = React.useState({
    confirmQuantities: false,
    confirmDate: false,
  });

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const { confirmQuantities, confirmDate } = state;

  // const onStepSelectType = useCallback(() => setOpen('type'), []);
  const onStepSelectDate = useCallback(() => setOpen('date'), []);
  // const onStepSelectProducts = useCallback(() => setOpen('products'), []);
  const onStepSelectConfirm = useCallback(() => {
    if (!selectedDate || !dayjs(selectedDate).isValid() || cartLoading) return false;

    setDateComplete(true);
    setOpen('confirm');
    return true;
  }, [selectedDate, cartLoading]);

  // If the user navigates here with no items in the cart, redirect them back to products.
  if (!cartLoading && cartLength === 0) {
    return <Redirect push to={routes.private.products} />;
  }

  return (
    <>
      <Loading loading={paymentLoading} mode="full" title="Preparing order for payment..." />
      <Layout currentUrl={currentUrl}>
        <Grid container spacing={constants.gridSpacing} className={classes.dropHalf}>
          <Grid container item xs={12} spacing={constants.gridSpacing}>
            <Typography variant="h3" className={classes.heading}>
              Place an order
            </Typography>
          </Grid>
          <Grid container item xs={12} spacing={constants.gridSpacing}>
            <List component="nav" aria-labelledby="nested-list-subheader" className={classes.fullWidth}>
              {/* <ListItem button onClick={onStepSelectType}>
              <ListItemIcon>
                {!selectedType ? (
                  <Box className={classes.step}>
                    <Typography variant="h6" className={classes.capitalize}>
                      1
                    </Typography>
                  </Box>
                ) : (
                  <CheckCircle style={{ fontSize: 35 }} color="secondary" />
                )}
              </ListItemIcon>
              <ListItemText>
                <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
                  <Typography variant="h6" className={classes.capitalize}>
                    Select Order Type:
                  </Typography>
                  <Typography variant="h6" color="primary" className={classes.selectedItem}>
                    {selectedType}
                  </Typography>
                </Grid>
              </ListItemText>
            </ListItem>
            <Collapse in={open === 'type'} timeout="auto" unmountOnExit className={classes.collapse}>
              <Grid container item xs={12} spacing={constants.gridSpacing}>
                <Grid item xs={12} className={classes.box}>
                  <Typography className={classes.note}>
                    <InfoIcon color="primary" className={classes.noteIcon} />
                    {ReactHtmlParser(
                      `If this is an order that you regularly place and would like it delivered every week, consider setting up a "recurring" order.
                      This order will come every week until you decide to stop.
                      <br><br>Need to pause or make adjustments?
                      Don't worry, you can view the <a href="${routes.private.orders}/scheduled">order</a> and make any changes there.
                      Please call or <a href="mailto:sales@vseinternational.com.au">email</a> the VSE office for more information.`,
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Button variant="outlined" color="primary" className={classes.button} onClick={onTypeStepClickSingle}>
                    Single
                  </Button>
                  <Button variant="outlined" color="default" className={classes.button} onClick={onTypeStepClickRecurring}>
                    Recurring
                  </Button>
                </Grid>
              </Grid>
            </Collapse> */}

              <ListItem button className={classes.dropHalf} onClick={onStepSelectDate}>
                <ListItemIcon>
                  {!selectedDateComplete ? (
                    <Box className={classes.step}>
                      <Typography variant="h6" className={classes.capitalize}>
                        1
                      </Typography>
                    </Box>
                  ) : (
                    <CheckCircle style={{ fontSize: 35 }} color="secondary" />
                  )}
                </ListItemIcon>
                <ListItemText>
                  <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
                    <Typography variant="h6" className={classes.capitalize}>
                      Select Delivery Date:
                    </Typography>
                    <Typography variant="h6" color="primary" className={classes.selectedItem}>
                      {(selectedDate && selectedDate.format('dddd, MMMM D, YYYY')) || `Never`}
                    </Typography>
                  </Grid>
                </ListItemText>
              </ListItem>
              <Collapse in={open === 'date'} timeout="auto" unmountOnExit className={classes.collapse}>
                <Grid container item xs={12} spacing={constants.gridSpacing}>
                  <MuiPickersUtilsProvider utils={DayJsUtils}>
                    <DatePicker
                      fullWidth
                      orientation="portrait"
                      variant="static"
                      openTo="date"
                      minDate={deliveryDateNext}
                      shouldDisableDate={disableDays}
                      value={selectedDate && dayjs(selectedDate).isValid() && selectedDate.toISOString()}
                      onChange={onDateChange}
                    />
                  </MuiPickersUtilsProvider>
                  <Grid item xs={7}>
                    <Grid item xs={12} className={classes.box}>
                      <Typography className={classes.note}>
                        <InfoIcon color="primary" className={classes.noteIcon} />
                        What days are available for delivery are based on our delivery runs. If you would prefer other days, please get in touch and we can
                        discuss options.
                      </Typography>
                    </Grid>

                    {defaultDeliveryCutoff === cartDeliveryCutoff && (
                      <Grid item xs={12} className={classes.box}>
                        <Typography className={classes.note}>
                          <InfoIcon color="primary" className={classes.noteIcon} />
                          For next day deliveries, orders must be placed by no later than {localTime(cartDeliveryCutoff)} the preceding day.
                        </Typography>
                      </Grid>
                    )}
                    {isNextDayDelivery && defaultDeliveryCutoff !== cartDeliveryCutoff && <ProductDeliveryWarning deliveryCutoff={cartDeliveryCutoff} />}

                    {hasSandwiches && (
                      <Grid item xs={12} className={classes.box}>
                        <Typography className={classes.note}>
                          <InfoIcon color="primary" className={classes.noteIcon} />
                          Sandwiches &amp; Wraps can only be delivered on certain days. This has restricted your available delivery options.{' '}
                          {cartLength > 0 && `Consider placing two orders if you need your other cart items sooner.`}
                        </Typography>
                      </Grid>
                    )}

                    {(!selectedDate || cartLoading) && (
                      <Grid item xs={12} className={classes.box}>
                        <Typography className={classes.note}>
                          <InfoIcon color="error" className={classes.noteIcon} />
                          Unable to find any days suitable for delivery. Please get in touch and we&apos;ll be happy to process this order manually and confirm
                          you are assigned to the right delivery route.
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Button variant="outlined" color="primary" className={classes.button} onClick={onDateStepBack}>
                      Back
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      disabled={!selectedDate || !dayjs(selectedDate).isValid() || cartLoading}
                      className={classes.button}
                      onClick={onDateStepNext}
                    >
                      Next
                    </Button>
                  </Grid>
                </Grid>
              </Collapse>

              {/* <ListItem button className={classes.dropHalf} onClick={onStepSelectProducts}>
              <ListItemIcon>
                <CheckCircle color={selectedProductsComplete ? 'secondary' : 'inherit'} />
              </ListItemIcon>
              <ListItemText primary="Select Products:" />
            </ListItem>
            <Collapse in={open === 'products'} timeout="auto" unmountOnExit>
              <Grid container spacing={constants.gridSpacing} justifyContent="flex-end" className={classes.dropHalf}>
                <Grid item xs={12} md={3}>
                  <ProductFilter searchString={searchString} options={productsOptions} onSearch={onSearch} />
                  <Button variant="outlined" color="primary" className={`${classes.dropHalf} ${classes.button}`} onClick={onProductStepDate}>
                    Back
                  </Button>
                  <Button variant="outlined" color="primary" className={`${classes.dropHalf} ${classes.button}`} onClick={onProductStepConfirm}>
                    Next
                  </Button>
                </Grid>
                <Grid item xs={12} md={9}>
                  <Grid container spacing={constants.gridSpacing}>
                    <ProductsList products={products} loading={productsLoading} showLinks />
                  </Grid>
                </Grid>
                <LinkLoadMore loading={productsLoading} hidden={nextLimit >= productsLimitNext} onClick={onLoadMore} />
              </Grid>
            </Collapse> */}

              <ListItem button className={classes.dropHalf} onClick={onStepSelectConfirm}>
                <ListItemIcon>
                  {!selectedConfirmComplete ? (
                    <Box className={classes.step}>
                      <Typography variant="h6" className={classes.capitalize}>
                        2
                      </Typography>
                    </Box>
                  ) : (
                    <CheckCircle style={{ fontSize: 35 }} color="secondary" />
                  )}
                </ListItemIcon>
                <Typography variant="h6" className={classes.capitalize}>
                  Confirm Your Order:
                </Typography>
              </ListItem>
              <Collapse in={open === 'confirm'} timeout="auto" unmountOnExit className={classes.collapse}>
                <Grid container item xs={12} spacing={constants.gridSpacing}>
                  <Grid item xs={12}>
                    <Grid item xs={12}>
                      <CartList onError={setHasError} />
                    </Grid>
                  </Grid>

                  <OrderTotals delivery={cartDelivery} net={cartNet} gst={cartGst} total={cartTotal} showDeliveryNote />

                  <Grid item xs={12} className={classes.drop}>
                    <FormControl component="fieldset">
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox checked={confirmQuantities} onChange={handleChange} name="confirmQuantities" />}
                          label="I confirm that the quantities on this order are correct"
                        />
                        {selectedDate && (
                          <FormControlLabel
                            control={<Checkbox checked={confirmDate} onChange={handleChange} name="confirmDate" />}
                            label={`I confirm that ${selectedDate && selectedDate.format('dddd, MMMM D, YYYY')} is the correct delivery date for this order`}
                          />
                        )}
                      </FormGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <Button variant="outlined" color="primary" className={`${classes.button} ${classes.dropHalf}`} onClick={onConfirmStepBack}>
                      Back
                    </Button>

                    {payOnAccount && (
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={hasError || !confirmQuantities || !confirmDate || cartLength < 1 || cartLoading}
                        className={`${classes.button} ${classes.dropHalf}`}
                        onClick={onAccountSubmit}
                      >
                        Pay on Account
                      </Button>
                    )}

                    {/* <FeatureFlag name="payment_gateway"> */}
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={hasError || !confirmQuantities || !confirmDate || cartLength < 1 || cartLoading}
                      className={`${classes.button} ${classes.dropHalf}`}
                      onClick={onCreditCardSubmit}
                    >
                      Pay with Credit Card
                    </Button>
                    <Modal open={openStripeCheckout} onClose={onCloseStripeCheckout}>
                      <CheckoutForm orderNo={orderNo} cartItems={cartItems} cartGst={cartGst} onClose={onCloseStripeCheckout} />
                    </Modal>
                    {/* </FeatureFlag> */}
                  </Grid>
                </Grid>
              </Collapse>
            </List>
          </Grid>
        </Grid>

        <Grid container spacing={constants.gridSpacing} className={classes.drop}>
          <Banners />
        </Grid>
      </Layout>
    </>
  );
};

Order.propTypes = {
  currentUrl: PropTypes.string,
  search: PropTypes.string,
};

Order.defaultProps = {
  currentUrl: '/',
  search: '',
};

export default Order;
