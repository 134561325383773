import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

// Material-UI
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

// Config
import constants from '../config/constants';
import routes from '../config/routes';

// Hooks
import useOrdersDataTableLoad from '../hooks/useOrdersDataTableLoad';
import useOrdersNext from '../hooks/useOrdersNext';
import useUserPayOnAccount from '../hooks/useUserPayOnAccount';

// Components
import Layout from './shared/Layout';
import TitlePage from './shared/TitlePage';
import OrdersList from './shared/OrdersList';
import Banners from './shared/Banners';

// Styles
const useStyles = makeStyles((theme) => ({
  drop: theme.drop,
  dropHalf: theme.dropHalf,
  tabs: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  tab: {
    margin: '0 auto',
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(0, 3),
    },
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}));

const Orders = ({ currentUrl, tab }) => {
  const classes = useStyles();
  const history = useHistory();

  const [tabSelected, setTabSelected] = useState(tab);

  const handleChange = (event, newTab) => {
    setTabSelected(newTab);

    // Redirect - Update the URL to include the tab slection, this allowing the open the correct tab on page refresh.
    history.push(`${routes.private.orders}/${newTab}`);
  };

  // Orders
  const { orderDataTableLoading, ordersDataTable } = useOrdersDataTableLoad(tabSelected, 9999, 0);
  const { ordersNext } = useOrdersNext();

  // The user can only edit the order if it's sceduled for the future and they are on the "On Account" payment method
  const { payOnAccount } = useUserPayOnAccount();
  const disabled = !payOnAccount || tabSelected === 'past';

  return (
    <Layout currentUrl={currentUrl}>
      <Grid className={classes.dropHalf}>
        <TitlePage loading={orderDataTableLoading} orderDate={(ordersNext && ordersNext.despatchDate) || ''} />
      </Grid>
      <Grid container spacing={constants.gridSpacing} className={classes.drop}>
        <Tabs
          value={tabSelected}
          onChange={handleChange}
          orientation="horizontal"
          indicatorColor="primary"
          textColor="primary"
          centered
          className={classes.tabs}
        >
          <Tab className={classes.tab} value="scheduled" label="Scheduled Orders" />
          <Tab className={classes.tab} value="past" label="Past Orders" />
        </Tabs>
      </Grid>
      <Grid container spacing={constants.gridSpacing}>
        <Grid item xs={12}>
          <OrdersList key={`OrdersList-${tabSelected}`} orders={ordersDataTable} loading={orderDataTableLoading} disabled={disabled} />
        </Grid>
      </Grid>
      <Grid container spacing={constants.gridSpacing} className={classes.drop}>
        <Banners />
      </Grid>
    </Layout>
  );
};

Orders.propTypes = {
  currentUrl: PropTypes.string,
  tab: PropTypes.string,
};

Orders.defaultProps = {
  currentUrl: '/',
  tab: 'scheduled',
};

export default Orders;
