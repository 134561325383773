import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

// API
import { registerKeepAlive } from '../api/Authentication';

// Actions
import { reauthenticateUser } from '../actions/users';

// Helpers
import { getAccountNumber } from '../helpers/getAccountNumber';
import { getAuthenticationToken } from '../helpers/getAuthenticationToken';
import { getCartDate } from '../helpers/getCartDate';
import { getDebtorId } from '../helpers/getDebtorId';

// Hooks
import useCartItems from './useCartItems';

const useRegisterKeepAlive = (orderNo, orderAmount) => {
  const { cartItemsSummary } = useCartItems();
  const accountNo = getAccountNumber();
  const debtorId = getDebtorId();
  const orderDate = getCartDate();
  const token = getAuthenticationToken();

  const dispatch = useDispatch();

  return useCallback(async () => {
    try {
      await dispatch(reauthenticateUser());

      const cartItemsSummaryString = new URLSearchParams(cartItemsSummary).toString();
      return await registerKeepAlive({
        token,
        debtorId,
        accountNo,
        orderNo,
        orderAmount,
        orderDate,
        cart: cartItemsSummaryString,
      }).then((response) => response);
    } catch (error) {
      throw new Error(error.message);
    }
  }, [dispatch, cartItemsSummary, token, debtorId, accountNo, orderNo, orderAmount, orderDate]);
};

export default useRegisterKeepAlive;
