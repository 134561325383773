import findKey from 'lodash/findKey';
import isEmpty from 'lodash/isEmpty';

export const getOrderWithinOrders = (oid, orders) => {
  if (!oid || isEmpty(orders)) return {};
  const foundKey = findKey(orders, (o) => o.oid === oid);
  return { order: orders[foundKey] || {}, key: foundKey };
};

export { getOrderWithinOrders as default };
