import { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import mergeWith from 'lodash/mergeWith';
import uniq from 'lodash/uniq';

// Actions
import { loadProducts } from '../actions/products';

// Helpers
import { combineProductOptionsAll, cleanOptions } from '../helpers/getProductOptions';
import { getProductsFiltered } from '../helpers/getProductsFiltered';

// Reducers
import { getProducts, getProductsLoading } from '../reducers';

const useProductsLoad = (limit = 12, offset = 0, search = '', category = '', subcategory = '', size = '', packaging = '', sliced = '', flavour = '') => {
  const dispatch = useDispatch();
  const productsLoading = useSelector(getProductsLoading);
  const productsRaw = useSelector(getProducts);
  const length = useMemo(() => Object.keys(productsRaw).length || 0, [productsRaw]);

  useEffect(() => {
    dispatch(loadProducts());
  }, [dispatch]);

  // NOTE: when displaying a product list, ingredients are displayed after all other products.

  // We need to recreate the paginate object when one of the search criteria change,
  // as we need a fresh filter, since the productsFiltered memo function mutates paginate.
  // TODO: Refactor to avoid mutations.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const paginate = useMemo(
    () => (length > 1 ? cloneDeep(productsRaw) : {}),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [search, category, subcategory, size, packaging, sliced, flavour, productsRaw, length],
  );

  // PRODUCTS

  const productsFiltered = useMemo(() => {
    if (length < 1) return [];
    return getProductsFiltered('products', paginate, category, subcategory, size, packaging, sliced, flavour, search);
  }, [search, category, subcategory, size, packaging, sliced, flavour, length, paginate]);

  const productsCount = useMemo(() => productsFiltered.length, [productsFiltered]);

  // INGREDIENTS

  const ingredientsFiltered = useMemo(() => {
    if (length < 1) return [];
    return getProductsFiltered('ingredients', paginate, category, subcategory, size, packaging, sliced, flavour, search);
  }, [search, category, subcategory, size, packaging, sliced, flavour, length, paginate]);

  const ingredientsCount = useMemo(() => ingredientsFiltered.length, [ingredientsFiltered]);

  // OPTIONS - Used in the filter.

  // Get all product options.
  const options = useMemo(() => {
    if (length < 1) return {};
    return Object.keys(productsRaw).reduce((a, k) => cleanOptions(combineProductOptionsAll(a, productsRaw[k].productOptionsAll)), []);
  }, [length, productsRaw]);

  // Get the product options for our filtered down list of products, based on whats being searched.
  const productsOptionsFiltered = useMemo(() => {
    if (productsCount < 1) return {};
    return productsFiltered.reduce((a, k) => cleanOptions(combineProductOptionsAll(a, paginate[k].productOptionsAll)), []);
  }, [productsCount, productsFiltered, paginate]);

  // Get the ingredient options for our filtered down list of ingredients, based on whats being searched.
  const ingredientsOptionsFiltered = useMemo(() => {
    if (ingredientsCount < 1) return {};
    return ingredientsFiltered.reduce((a, k) => cleanOptions(combineProductOptionsAll(a, paginate[k].productOptionsAll)), []);
  }, [ingredientsCount, ingredientsFiltered, paginate]);

  // Combine the product options with the ingredients
  const optionsFiltered = mergeWith(productsOptionsFiltered, ingredientsOptionsFiltered, (object1, object2) => uniq(object1, object2));

  // PAGINATION

  const productsLimitNext = limit === 0 ? 0 : productsCount;
  const products = useMemo(
    () =>
      productsFiltered

        // Paginate the products
        .slice(offset, limit)

        // Convert into the object
        .reduce(
          (a, k) => ({
            ...a,
            [k]: paginate[k],
          }),
          {},
        ),
    [paginate, productsFiltered, offset, limit],
  );

  // NOTE: as ingredients are displayed after all other products, calculate the limits for ingredients.
  // Ingredients will only be added to the list of products once we have showed them all.

  const ingredientsOffset = Math.max(0, offset - productsCount);
  const ingredientsLimit = Math.max(0, limit - productsCount);
  const ingredientsLimitNext = limit === 0 ? 0 : productsCount;

  const ingredients = useMemo(
    () =>
      ingredientsFiltered

        // Paginate the ingredients
        .slice(ingredientsOffset, ingredientsLimit)

        // Convert into the object
        .reduce(
          (a, k) => ({
            ...a,
            [k]: paginate[k],
          }),
          {},
        ),
    [paginate, ingredientsFiltered, ingredientsOffset, ingredientsLimit],
  );

  return {
    productsLoading,
    products,
    productsCount,
    productsLimitNext,

    ingredientsLoading: productsLoading,
    ingredients,
    ingredientsCount,
    ingredientsLimitNext,

    // Even though we want the filtered options, we always want all options for the category. So take from the unfiltered options.
    options,
    optionsFiltered: { ...optionsFiltered, category: options.category },
  };
};

export default useProductsLoad;
