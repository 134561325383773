import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import rootReducer from '../reducers';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const reducers = require('../reducers');

export default function configureStore(initialState) {
  const store = createStore(rootReducer, initialState, composeEnhancers(applyMiddleware(thunk)));

  if (module.hot) {
    module.hot.accept('../reducers', () => store.replaceReducer(reducers.default));
  }

  return store;
}
