import React from 'react';
import PropTypes from 'prop-types';
import { RemoveScroll } from 'react-remove-scroll';

// Material-UI
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// Config
import constants from '../../config/constants';

const useStyles = makeStyles((theme) => ({
  modeElement: {
    height: '-webkit-fill-available',
    margin: 0,
    padding: 0,
    position: 'relative',
    width: 'auto',
  },
  modeSkeleton: {
    height: 'inherit',
    position: 'absolute',
    top: '-18px',
  },
  modeFull: {
    height: '100vh',
    left: 0,
    margin: 0,
    position: 'absolute',
    top: 0,
    width: '100%',
    zIndex: 999,
  },
  backgroundSolid: {
    background: theme.palette.grey[100],
  },
  backgroundTransparent: {
    background: `${theme.palette.grey[100]}cc`,
  },
  spinner: {
    left: 'calc(50% - 3rem)',
    position: 'absolute',
    top: 'calc(50% - 3rem)',
  },
  title: {
    position: 'absolute',
    top: 'calc(50% + 5rem)',
    width: '100%',
    textAlign: 'center',
  },
}));

const Loading = ({ loading, mode, background, title }) => {
  const classes = useStyles();

  if (!loading) return false;

  const classBackground = background === 'solid' ? classes.backgroundSolid : classes.backgroundTransparent;
  const classMode = `
    ${classBackground}
    ${mode === 'element' ? classes.modeElement : ''}
    ${mode === 'skeleton' ? classes.modeSkeleton : ''}
    ${mode === 'full' ? classes.modeFull : ''}
  `;

  return (
    <RemoveScroll>
      <Grid container className={classMode} spacing={constants.gridSpacing}>
        <CircularProgress size="6rem" color="secondary" className={classes.spinner} />
        <Typography variant="h4" component="h4" color="primary" className={classes.title}>
          {title}
        </Typography>
      </Grid>
    </RemoveScroll>
  );
};

Loading.propTypes = {
  loading: PropTypes.bool,
  mode: PropTypes.string,
  background: PropTypes.string,
  title: PropTypes.string,
};

Loading.defaultProps = {
  loading: false,
  mode: 'element',
  background: 'transparent',
  title: '',
};

export default Loading;
