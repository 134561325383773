import { createTheme } from '@material-ui/core/styles';

// Config
import constants from '../config/constants';

// Colours
const theme = createTheme();
const whiteColor = '#ffffff';
const blackColor = '#454851';
const greyColor = '#939292';
const borderColor = '#eee';
const primaryColor = '#00A1E4';
const secondaryColor = '#B0DF2B';
const errorColor = '#A81C03';

// Theme Styling
export default createTheme({
  // Override Material UI Colours
  palette: {
    common: {
      black: blackColor,
    },
    primary: {
      // light: will be calculated from palette.primary.main,
      main: primaryColor,
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      // light: will be calculated from palette.primary.main,
      main: secondaryColor,
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    error: {
      // light: will be calculated from palette.primary.main,
      main: errorColor,
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    background: {
      paper: whiteColor,
      default: '#fafafa',
      dark: '#eee',
    },
    action: {
      // disabled: 'none',
      // disabledBackground: 'none',
    },
  },

  // Override Material UI Typography
  typography: {
    htmlFontSize: 20,
    fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'],
    fontSize: 18,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,

    h1: {
      fontWeight: 'bold',
      fontSize: '3.5rem',
      lineHeight: '82.2%',
      color: blackColor,
      letterSpacing: '0.4px',
    },
    h2: {
      fontWeight: 900,
      fontSize: '2.5rem',
      lineHeight: '82.2%',
      color: blackColor,
      marginBottom: theme.spacing(2),
      letterSpacing: '0.4px',
    },
    h3: {
      fontWeight: 'bold',
      fontSize: '2.25rem',
      lineHeight: '82.2%',
      color: blackColor,
      letterSpacing: '0.4px',
    },
    h4: {
      fontSize: '1.75rem',
      fontWeight: 'bold',
      lineHeight: '1.25',
      color: blackColor,
      letterSpacing: '0.4px',
    },
    h5: {
      fontWeight: 500,
      fontSize: '1rem',
      lineHeight: '140.62%',
      color: greyColor,
      padding: '0',
      letterSpacing: '0.4px',
    },
    h6: {
      fontWeight: 500,
      fontSize: '1.1rem',
      lineHeight: '140.62%',
      color: blackColor,
      letterSpacing: '0.4px',
      // textTransform: 'uppercase',
    },
    body1: {
      fontWeight: 400,
      fontSize: '0.9rem',
      color: blackColor,
      letterSpacing: '0.4px',
    },
    body2: {
      textTransform: 'uppercase',
      fontSize: '0.875rem',
      fontWeight: '600',
      letterSpacing: '0.4px',
      color: blackColor,
    },
    subtitle1: {
      marginBottom: theme.spacing(1),
      color: blackColor,
    },
    subtitle2: {
      fontWeight: 400,
      fontSize: '0.875rem',
      lineHeight: '140.62%',
      color: greyColor,
      display: 'block',
      textDecoration: 'none',
      marginBottom: theme.spacing(0.5),
    },
  },

  // Override Material UI Styles
  overrides: {
    // Buttons - https://material-ui.com/components/buttons/
    MuiButton: {
      root: {
        borderRadius: '50px',
        // boxSizing: 'border-box',
        // padding: theme.spacing(1, 2),
        fontSize: '0.875rem',
        letterSpacing: '0.6px',
        padding: theme.spacing(0, 2),
        height: '40px',
        // minWidth: '200px',
      },
      outlined: {
        // padding: theme.spacing(1, 2),
      },
      contained: {
        // boxShadow: 'none',
      },
      containedPrimary: {
        color: whiteColor,
      },
      containedSecondary: {
        color: whiteColor,
      },
    },

    // Menu
    MuiMenuItem: {
      root: {
        textTransform: 'capitalize',
      },
    },

    // Chips (Tags) - https://material-ui.com/components/chips/
    MuiChip: {
      root: {
        padding: theme.spacing(1, 2),
      },
    },

    // Select Input - https://material-ui.com/components/selects/
    MuiSelect: {
      select: {
        textTransform: 'capitalize',
      },
    },

    MuiFormHelperText: {
      root: {
        width: 'max-content',
      },
    },

    // Divider - https://material-ui.com/api/divider/
    MuiDivider: {
      root: {
        // backgroundColor: 'secondaryColor',
        height: '1px',
      },
    },

    // Cards - https://material-ui.com/components/cards/
    MuiCardMedia: {
      root: {
        position: 'relative',
        width: '100%',
        paddingTop: '75%' /* 4:3 Aspect Ratio */,
      },
    },

    // Badges - https://material-ui.com/components/badges/
    MuiBadge: {
      anchorOriginTopRightRectangular: {
        backgroundColor: errorColor,
        color: theme.palette.background.paper,
        zIndex: 0,
        right: 17,
      },
    },

    // List Items Text - https://material-ui.com/api/list-item-text/#listitemtext-api
    MuiListItemText: {
      secondary: {
        fontSize: '1rem',
      },
    },

    // SVG - https://material-ui.com/api/svg-icon/
    MuiSvgIcon: {
      root: {
        zIndex: 10,
      },
    },

    // Accordion - https://material-ui.com/components/accordion/
    MuiAccordion: {
      root: {
        margin: theme.spacing(2, 0),
        boxShadow: 'none',
        borderStyle: 'solid',
        borderColor,
        borderWidth: '0 1px 1px 1px',
        '&:before': {
          opacity: '0',
        },
      },
    },
    MuiAccordionSummary: {
      root: {
        background: '#eee',
        color: '#333',
      },
      expandIcon: {
        color: '#333',
      },
    },

    // Dialog - https://material-ui.com/components/dialogs/
    DialogContent: {
      dividers: {
        padding: '16px',
      },
    },

    // Snackbar (Alerts) - https://material-ui.com/components/snackbars/
    // MuiSnackbarContent: {
    //   action: {
    //     minWidth: '20px',
    //   },
    // },

    // Tabs - https://material-ui.com/components/tabs/
    MuiTab: {
      root: {
        margin: '0 12px !important',
        letterSpacing: '0.6px',
        fontWeight: '600',
        whiteSpace: 'nowrap',
      },
    },

    // Tables - https://www.material-ui-datatables.com/
    MUIDataTable: {
      paper: {
        backgroundColor: 'transparent',
      },
    },
    MUIDataTableToolbar: {
      root: {
        backgroundColor: '',
      },
    },
    MUIDataTableHeadCell: {
      root: {
        '&:nth-child(4)': {
          textAlign: 'right',
          justifyContent: 'flex-end',
          display: 'flex',
        },
      },
      fixedHeader: {
        backgroundColor: 'transparent',
        '&:nth-child(4)': {
          textAlign: 'right',
        },
      },
    },
    MUIDataTableHeadRow: {
      root: {
        borderBottom: `2px solid ${secondaryColor}`,
      },
    },
    MUIDataTableSelectCell: {
      headerCell: {
        backgroundColor: '',
      },
    },
    MUIDataTableBodyRow: {
      root: {
        backgroundColor: '',
        '&:nth-child(even)': {
          backgroundColor: '#B0DF2B0D',
        },
      },
    },
    MUIDataTableBodyCell: {
      root: {
        fontWeight: 400,
        fontSize: '1rem',
      },
    },
    MuiTableRow: {
      root: {
        fontSize: '1rem',
      },
      footer: {},
    },
    MuiTableCell: {
      root: {
        textTransform: 'none',
      },
    },
    MuiTablePagination: {
      root: {
        fontSize: '1rem',
        padding: '0',
      },
      caption: {
        fontSize: '1rem',
      },
    },

    // Date Picker - https://material-ui-pickers.dev/

    MuiPickersStaticWrapper: {
      staticWrapperRoot: {
        boxShadow: '0 8px 20px -12px rgba(0,0,0,0.3)',
        borderRadius: '10px',
        marginTop: '1.25rem',
        marginLeft: '.75rem',
        marginRight: '1rem',
        marginBottom: '1rem',
      },
    },
    MuiPickersToolbarText: {
      toolbarBtnSelected: {
        color: blackColor,
      },
      toolbarTxt: {
        lineHeight: '2rem',
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: borderColor,
      },
    },
    MuiPickersToolbarButton: {
      toolbarBtn: {
        height: 'auto',
        justifyContent: 'flex-start',
        margin: 0,
        marginRight: 0,
      },
    },
    MuiPickersCalendarHeader: {
      dayLabel: {
        width: '46px',
      },
    },
    MuiPickersBasePicker: {
      pickerView: {
        padding: '0 1.25rem 1.75rem 1.25rem',
      },
    },
    MuiPickersDay: {
      day: {
        width: '46px',
        height: '46px',
      },
      daySelected: {
        color: blackColor,
        backgroundColor: secondaryColor,
      },
    },

    // Debugging

    // MuiContainer: {
    //   root: {
    //     border: '1px dotted red',
    //   },
    // },
    // MuiGrid: {
    //   item: {
    //     border: '1px dotted grey',
    //   },
    // },
  },

  MuiPaper: {
    root: {
      backgroundColor: 'transparent',
    },
  },

  // Custom Styling
  drop: {
    marginTop: theme.spacing(constants.dropSpacing),
  },
  dropHalf: {
    marginTop: theme.spacing(constants.dropSpacing / 2),
  },
});
