import { combineReducers } from 'redux';
import isEmpty from 'lodash/isEmpty';

// Helpers
import { getOrderWithinOrders } from '../helpers/getOrderWithinOrders';

const past = (state = [], action) => {
  switch (action.type) {
    case 'SET_ORDERS_LOADING':
      return 'past' in action ? { ...state, loading: true } : state;
    case 'SET_ORDERS':
      return 'past' in action ? { ...state, orders: action.past.orders, loading: action.past.loading } : state;
    case 'REMOVE_ORDER': {
      const filteredOrders = isEmpty(state.orders) ? {} : state.orders.filter((order) => order.oid !== action.order.oid);
      return { ...state, orders: filteredOrders, loading: false };
    }
    default:
      return state;
  }
};

const favourite = (state = [], action) => {
  switch (action.type) {
    case 'SET_ORDERS_LOADING':
      return 'favourite' in action ? { ...state, loading: true } : state;
    case 'SET_ORDERS':
      return 'favourite' in action ? { ...state, orders: action.favourite.orders, loading: action.favourite.loading } : state;
    case 'REMOVE_ORDER': {
      const filteredOrders = isEmpty(state.orders) ? {} : state.orders.filter((order) => order.oid !== action.order.oid);
      return { ...state, orders: filteredOrders, loading: false };
    }
    default:
      return state;
  }
};

const scheduled = (state = [], action) => {
  switch (action.type) {
    case 'SET_ORDERS_LOADING':
      return 'scheduled' in action ? { ...state, loading: true } : state;
    case 'SET_ORDERS':
      return 'scheduled' in action ? { ...state, orders: action.scheduled.orders, loading: action.scheduled.loading } : state;
    case 'REMOVE_ORDER': {
      const filteredOrders = isEmpty(state.orders) ? {} : state.orders.filter((order) => order.oid !== action.order.oid);
      return { ...state, orders: filteredOrders, loading: false };
    }
    case 'SET_ORDER': {
      const orders = (state.orders && [...state.orders]) || [];

      if (isEmpty(action.order)) {
        return { ...state, orders };
      }

      const { class: responseClass, request, ...order } = action.order;

      // Find the order within all orders.
      const found = getOrderWithinOrders(action.order.oid, state.orders);

      // If it was found...
      // Update the order within the full list of orders and store into state.
      // eslint-disable-next-line fp/no-mutation
      if (found.key) orders[found.key] = order;

      return { ...state, orders };
    }
    default:
      return state;
  }
};

const ordersLoading = (state = false, action) => {
  switch (action.type) {
    case 'SET_ORDERS_LOADING':
      return true;
    case 'SET_ORDERS':
      return false;
    default:
      return state;
  }
};

const order = (state = {}, action) => {
  switch (action.type) {
    case 'SET_ORDER_LOADING':
      return {};
    case 'SET_ORDER':
      return action.order;
    case 'SHOW_MODAL':
      return action.order || state;
    default:
      return state;
  }
};

const orderLoading = (state = false, action) => {
  switch (action.type) {
    case 'SET_ORDER_LOADING':
      return true;
    case 'SET_ORDER':
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  past,
  favourite,
  scheduled,
  ordersLoading,
  order,
  orderLoading,
});

// eslint-disable-next-line no-shadow
export const getOrders = ({ past, favourite, scheduled }) => ({ past, favourite, scheduled });
export const getOrdersLoading = (state) => state.ordersLoading;
export const getOrder = (state) => state.order;
export const getOrderLoading = (state) => state.orderLoading;
