import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

const useAction = (actionCreator) => {
  const dispatch = useDispatch();
  const onAction = useCallback((...args) => dispatch(actionCreator(...args)), [dispatch, actionCreator]);
  return onAction;
};

export default useAction;
