import React from 'react';
import ReactDOM from 'react-dom';

// Sentry
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

// Feature Flags
// import { FlagsProvider } from 'react-unleash-flags';

// Config
import constants from './config/constants';

// Components
import App from './App';
import Provider from './Provider';

// App Version
const packageJson = require('../package.json');

const appName = packageJson.name;
const appVersion = packageJson.version;

// Sentry
if (constants.sentryEnabled) {
  console.log(`> Initiating Sentry. Set to "${constants.sentryEnvironment || 'development'}" environment`);

  Sentry.init({
    dsn: constants.sentryDsn,
    autoSessionTracking: true,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    environment: constants.sentryEnvironment || 'development',
    release: `${appName}@${appVersion}`,
  });
}

ReactDOM.render(
  // <FlagsProvider>
  <Provider>
    <App />
  </Provider>,
  // </FlagsProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.ready
    .then((registration) => {
      registration.unregister();
    })
    .catch((error) => console.log('ServiceWorker Warning: ', error));
}
