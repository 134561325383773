import React from 'react';
import PropTypes from 'prop-types';

// Material-UI
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import InfoIcon from '@material-ui/icons/Info';
import Typography from '@material-ui/core/Typography';

// Helpers
import { getCartHasProduct } from '../../helpers/getCartHasProduct';
import { getProductDeliveryCutoff } from '../../helpers/getProductDeliveryCutoff';
import { localTime } from '../../helpers/date';

// Hooks
import useCartItems from '../../hooks/useCartItems';

// Styles
const useStyles = makeStyles((theme) => ({
  drop: theme.drop,
  dropHalf: theme.dropHalf,
  box: {
    border: '1px solid #eee',
    borderRadius: 3,
    margin: theme.spacing(1),
    position: 'relative',
  },
  note: {
    margin: '0 auto',
    padding: theme.spacing(2, 3, 2, 6),
    position: 'relative',
    width: 'fit-content',
  },
  noteIcon: {
    left: 14,
    position: 'absolute',
  },
  noteCapitise: {
    display: 'inline',
    textTransform: 'capitalize',
  },
  bold: {
    display: 'inline',
    // fontWeight: 'bold',
  },
}));

const ProductDeliveryWarning = ({ category, productName, deliveryCutoff }) => {
  const classes = useStyles();

  // Fetch the default and the product delivery cutoff.
  const defaultDeliveryCutoff = getProductDeliveryCutoff('default', false);
  const productDeliveryCutoff = getProductDeliveryCutoff(category, false);

  // Identify which items in the cart might be affecting the cutoff.
  const { cartItems } = useCartItems();
  const hasSandwiches = getCartHasProduct('sandwiches/wraps', cartItems);
  const hasHighEndDesserts = getCartHasProduct('high end desserts', cartItems);
  const hasMacarons = getCartHasProduct('macarons', cartItems);

  if ((deliveryCutoff || productDeliveryCutoff) === defaultDeliveryCutoff) return null;

  return (
    <Grid item xs={12} className={classes.box}>
      <Typography className={classes.note}>
        <InfoIcon color="primary" className={classes.noteIcon} />
        {(productName || category) && <>Some products affect your next available delivery date.{` `}</>}
        {productName && (
          <>
            For <Box className={classes.noteCapitise}>{productName}</Box> please
          </>
        )}
        {!productName && category && (
          <>
            For <Box className={classes.noteCapitise}>{category}</Box> please
          </>
        )}
        {!productName && !category && hasSandwiches && !hasHighEndDesserts && !hasMacarons && (
          <>
            You have a <Box className={classes.bold}>sandwich or wrap</Box> in your cart which affects your next available delivery date. Please
          </>
        )}
        {!productName && !category && !hasSandwiches && hasHighEndDesserts && !hasMacarons && (
          <>
            You have a <Box className={classes.bold}>High end desserts</Box> in your cart which affects your next available delivery date. Please
          </>
        )}
        {!productName && !category && !hasSandwiches && !hasHighEndDesserts && hasMacarons && (
          <>
            You have a <Box className={classes.bold}>High end desserts</Box> in your cart which affects your next available delivery date. Please
          </>
        )}
        {!productName && !category && !hasSandwiches && !hasHighEndDesserts && !hasMacarons && <>One or more products in your cart have an </>}
        <Box className={classes.bold}> order before {localTime(deliveryCutoff || productDeliveryCutoff)} to confirm next day delivery</Box>.
      </Typography>
    </Grid>
  );
};

ProductDeliveryWarning.propTypes = {
  category: PropTypes.string,
  productName: PropTypes.string,
  deliveryCutoff: PropTypes.number,
};

ProductDeliveryWarning.defaultProps = {
  category: '',
  productName: null,
  deliveryCutoff: null,
};

export default ProductDeliveryWarning;
