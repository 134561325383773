import { useMemo } from 'react';
import { useSelector } from 'react-redux';

// Date
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

// Config
import constants from '../config/constants';

// Helpers
import { getOrderDeliveryDates } from '../helpers/getOrderDeliveryDates';
import { getProductDeliveryCutoff } from '../helpers/getProductDeliveryCutoff';
import { getCartHasCategory } from '../helpers/getCartHasCategory';

// Hooks
import useCartItems from './useCartItems';

// Reducers
import { getDeliveryDates, getDeliveryCutoff } from '../reducers';

const useDeliveryDateNext = () => {
  const { cartItems } = useCartItems();
  const deliveryCutoff = useSelector(getDeliveryCutoff);
  const deliveryDates = useSelector(getDeliveryDates);
  const { deliveryHoliday } = constants;

  const deliveryDateNext = useMemo(() => {
    // Extend dayjs with the timezone plugins
    dayjs.extend(utc);
    dayjs.extend(timezone);

    // Get the days of the week that are available for delivery.
    // Product in the cart affect what days are available. Make sure to factor them in to the available dates.
    const deliveryDatesAffectedByCartProducts = getOrderDeliveryDates(deliveryDates, cartItems);

    // Get the default delivery cutoff time.
    const defaultDeliveryCutoff = getProductDeliveryCutoff('default', false);
    const sundayDeliveryCutoff = getProductDeliveryCutoff('sunday', false);

    // Get the current time.
    const dateNow = dayjs().tz('Australia/Sydney');

    // Force the current time to be the cutoff, resulting in it being to late for next day delivery
    // const dateNow = dayjs().tz('Australia/Sydney').hour(defaultDeliveryCutoff);

    // Get the next delivery cutoff time.
    // If Sunday, the Sydney deadline is 12 pm which is 1 am UTC
    // Otherwise default to 3 pm for Mon to Saturday
    // If set, use the cutoff set in Redux. This is set by some special products that can have a 12 pm or 2pm cutoff. Only set if these are added to cart.
    const dateDeliveryCutOff =
      dateNow.format('dddd').toLowerCase() === 'sunday'
        ? dateNow.hour(sundayDeliveryCutoff).minute(0).second(0).millisecond(0)
        : dateNow
            .hour(deliveryCutoff || defaultDeliveryCutoff)
            .minute(0)
            .second(0)
            .millisecond(0);

    // If now is before todays cutoff time, then the next available delivery date is tomorrow.
    // If now is after todays cutoff time, then the next available delivery date is the following date.
    let deliveryDate = dateNow < dateDeliveryCutOff ? dateDeliveryCutOff.add(1, 'day') : dateDeliveryCutOff.add(2, 'day');

    const hasIngredients = getCartHasCategory('ingredients', cartItems);
    // eslint-disable-next-line fp/no-mutation
    if (hasIngredients) deliveryDate = deliveryDate.add(constants.deliveryDelayIngredient, 'day');

    const hasSalads = getCartHasCategory('salads', cartItems);
    // eslint-disable-next-line fp/no-mutation
    if (hasSalads) deliveryDate = deliveryDate.add(constants.deliveryDelaySalads, 'day');

    const hasSauces = getCartHasCategory('sauces', cartItems);
    // eslint-disable-next-line fp/no-mutation
    if (hasSauces) deliveryDate = deliveryDate.add(constants.deliveryDelaySauces, 'day');

    const hasCocktailQuiches = getCartHasCategory('cocktail quiche', cartItems);
    // eslint-disable-next-line fp/no-mutation
    if (hasCocktailQuiches) deliveryDate = deliveryDate.add(constants.deliveryDelayCocktailQuiches, 'day');

    let validDay = false;
    let tries = 0;
    let dayName;
    let dayDate;

    // Some debtors aren't on a delivery run that goes out on every day. The available days are stored in Redux.
    // We now need to loop though the weekdays and find the next available delivery run.

    // Attempt to find a valid delivery date within the next month.
    while (!validDay && tries < 30) {
      // eslint-disable-next-line fp/no-mutation
      dayName = deliveryDate.format('dddd').toLowerCase();
      // eslint-disable-next-line fp/no-mutation
      dayDate = deliveryDate.format('YYYY-MM-DD');

      // If the current delivery date is a holiday, add one day and try again.
      const isHoliday = deliveryHoliday.includes(dayDate);

      // If the current delivery date is valid for this debtor, stop and return.
      if (!isHoliday && deliveryDatesAffectedByCartProducts && deliveryDatesAffectedByCartProducts[dayName]) {
        // eslint-disable-next-line fp/no-mutation
        validDay = true;
      }

      // If this is not a valid delivery date for the debtor, add one day and try again.
      else {
        // eslint-disable-next-line fp/no-mutation
        deliveryDate = deliveryDate.add(1, 'day');
      }

      // Increase the number of tries we have attempted to get a valid delivery date.
      // eslint-disable-next-line fp/no-mutation
      tries += 1;
    }

    return { deliveryDateNext: tries < 30 ? deliveryDate : false, deliveryDateNextLoading: !deliveryCutoff };
  }, [deliveryDates, cartItems, deliveryCutoff, deliveryHoliday]);

  return deliveryDateNext;
};

export default useDeliveryDateNext;
