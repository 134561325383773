import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import find from 'lodash/find';
import times from 'lodash/times';

// Material-UI
import Button from '@material-ui/core/Button';
import EmojiFoodBeverageOutlinedIcon from '@material-ui/icons/EmojiFoodBeverageOutlined';
import FavoriteBorderOutlinedIcon from '@material-ui/icons/FavoriteBorderOutlined';
import Grid from '@material-ui/core/Grid';

// Config
import constants from '../../config/constants';
import routes from '../../config/routes';

// Actions
import { showModal } from '../../actions/modals';

// Helpers
import { getProductsCount } from '../../helpers/getProductsCount';
import { getProductBySortOrder } from '../../helpers/getProductBySortOrder';

// Components
import EmptyList from './EmptyList';
import Link from './Link';
import ProductItem, { optionsVariant } from './ProductItem';

const ProductsList = ({ columns, loading, loadingPlaceholderCount, isFavourites, showLinks, showEmptyList, imageQuality, products }) => {
  const dispatch = useDispatch();

  // Products
  const hasProducts = getProductsCount(products) > 0;

  // On cart add click, show the popup with options.
  const onCartAdd = () => (event) => {
    event.preventDefault();
    showModal('cart_add', true);
    dispatch(showModal('cart', true));
  };

  const showEmptyFavourites = !loading && !hasProducts && isFavourites;
  const showEmptyProducts = !loading && !hasProducts && !isFavourites;
  const showProducts = hasProducts;
  const showLoading = loading;

  return (
    <>
      {showEmptyList && showEmptyProducts && (
        <Grid item xs={12}>
          <EmptyList
            key="EmptyList-Products"
            title={`We didn't find any products`}
            description={`This might be because your search didn't find a match. Try removing a filter or searching on a different word.`}
            icon={EmojiFoodBeverageOutlinedIcon}
          />
        </Grid>
      )}
      {showEmptyList && showEmptyFavourites && (
        <Grid item xs={12}>
          <EmptyList
            key="EmptyList-Favourites"
            title={`You don't have any favourites yet`}
            description={`Well, we're sure you do, but you haven't tagged them as your favourites yet. Don't worry it's easy. Just find a product you like and click the heart in the top right corner and that product will show here.`}
            icon={FavoriteBorderOutlinedIcon}
          >
            <Link to={routes.private.products}>
              <Button variant="contained" color="secondary" startIcon={<EmojiFoodBeverageOutlinedIcon />}>
                Browse Products
              </Button>
            </Link>
          </EmptyList>
        </Grid>
      )}
      {showProducts && (
        <>
          {Object.entries(products).map(([productName, product]) => {
            // Get the product variations sorted by size.
            const productVariationsSorted = getProductBySortOrder(product);

            // Get the first product variation.
            const productVariation = find(productVariationsSorted);

            return (
              <Grid
                key={`ProductItems-${productVariation.oid}`}
                item
                xs={columns === 'auto' ? 12 : columns}
                sm={columns === 'auto' ? 6 : columns}
                md={columns === 'auto' ? 4 : columns}
              >
                <ProductItem
                  product={productVariation}
                  productId={productVariation.oid}
                  showLinks={showLinks}
                  imageQuality={showLinks ? imageQuality : 'large'}
                  onCartAdd={onCartAdd}
                />
              </Grid>
            );
          })}
        </>
      )}
      {showLoading && (
        <Grid container spacing={constants.gridSpacing}>
          {times(loadingPlaceholderCount, (key) => (
            <Grid
              key={`ProductItem-Loading-${key}`}
              item
              xs={columns === 'auto' ? 12 : columns}
              sm={columns === 'auto' ? 6 : columns}
              md={columns === 'auto' ? 4 : columns}
            >
              <ProductItem loading showLinks={showLinks} />
            </Grid>
          ))}
        </Grid>
      )}
    </>
  );
};

ProductsList.propTypes = {
  columns: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  loading: PropTypes.bool,
  loadingPlaceholderCount: PropTypes.number,
  showLinks: PropTypes.bool,
  showEmptyList: PropTypes.bool,
  isFavourites: PropTypes.bool,
  imageQuality: PropTypes.oneOf(Object.keys(optionsVariant)),
  products: PropTypes.shape({
    oid: PropTypes.string,
    itemCode: PropTypes.string,
    webProductName: PropTypes.string,
    webProductDescription: PropTypes.string,
    productLength: PropTypes.string,
    productHeight: PropTypes.string,
    productWidth: PropTypes.string,
    productDimensionsUnit: PropTypes.string,
    servingSize: PropTypes.string,
    storageConditions: PropTypes.string,
    shelfLifeDays: PropTypes.string,
    rBKcountryOfOrigin: PropTypes.shape({
      country: PropTypes.string,
    }),
  }),
};

ProductsList.defaultProps = {
  columns: 'auto',
  loading: true,
  loadingPlaceholderCount: 12,
  showLinks: true,
  showEmptyList: false,
  isFavourites: false,
  imageQuality: 'large',
  products: {},
};

export default ProductsList;
