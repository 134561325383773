import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// Material-UI
import { makeStyles } from '@material-ui/core/styles';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

// Config
import constants from '../../config/constants';

// Hooks
import useForm from '../../hooks/useForm';

// Styles
const useStyles = makeStyles((theme) => ({
  drop: theme.drop,
  dropHalf: theme.dropHalf,
  form: {
    width: '100%', // Fix IE 11 issue.
    margin: theme.spacing(3, 0),
  },
  radioGroup: {
    flexDirection: 'row',
  },
  radioLabel: {
    margin: 'auto 16px auto 0',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    padding: theme.spacing(0, 4),
  },
}));

const ContactForm = ({ onSubmit, loggedIn }) => {
  const classes = useStyles();
  const user = useSelector((state) => state.users);

  const { inputs, handleInputChange, handleSubmit } = useForm(
    {
      name: user && user.data && user.data.contactName && loggedIn ? user.data.contactName : '',
      companyName: user && user.data && user.data.invoiceName && loggedIn ? user.data.invoiceName : '',
      telephone: user && user.data && user.data.contactMobile && loggedIn ? user.data.contactMobile : '',
      email: user && user.data && user.data.email && loggedIn ? user.data.email : '',
      message: '',
      is_customer: loggedIn ? 'yes' : 'no',
      subscribe: 'yes',
    },
    onSubmit,
  );
  return (
    <ValidatorForm className={classes.form} onSubmit={handleSubmit}>
      <Grid container spacing={constants.gridSpacing}>
        <Grid item xs={12} sm={6}>
          <TextValidator
            id="companyName"
            name="companyName"
            value={inputs.companyName}
            label="Company Name"
            variant="outlined"
            fullWidth
            autoFocus
            autoComplete="fname"
            validators={['required', 'isString']}
            errorMessages={['Please enter a company name', 'Please enter a valid company name']}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextValidator
            id="name"
            name="name"
            value={inputs.name}
            label="Contact Name"
            variant="outlined"
            fullWidth
            autoFocus
            autoComplete="fname"
            validators={['required', 'isString']}
            errorMessages={['Please enter a first name', 'Please enter a valid first name']}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextValidator
            id="email"
            name="email"
            value={inputs.email}
            label="Email Address"
            variant="outlined"
            fullWidth
            autoComplete="email"
            validators={['required', 'isEmail']}
            errorMessages={['Please enter an email address', 'Please enter a valid email address']}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextValidator
            id="telephone"
            name="telephone"
            value={inputs.telephone}
            label="Telephone"
            variant="outlined"
            fullWidth
            autoComplete="tel"
            validators={['required', 'isNumber']}
            errorMessages={['Please enter a telephone number', 'Please enter a valid telephone number']}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl component="fieldset" fullWidth>
            <RadioGroup aria-label="is_customer" name="is_customer" value={inputs.is_customer} className={classes.radioGroup} onChange={handleInputChange}>
              <FormLabel component="legend" className={classes.radioLabel}>
                Are you a VSE Customer?
              </FormLabel>
              <FormControlLabel value="yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="no" control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextValidator
            id="message"
            name="message"
            value={inputs.message}
            label="Message"
            variant="outlined"
            fullWidth
            validators={['required', 'isString']}
            errorMessages={['Please enter a message', 'Please enter a valid message']}
            onChange={handleInputChange}
            multiline
            rows="10"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox id="subscribe" name="subscribe" checked={inputs.subscribe === 'yes'} value="yes" color="primary" onChange={handleInputChange} />}
            label="I want to receive inspiration, marketing promotions and updates via email."
          />
        </Grid>
        <Grid container item xs={12} justifyContent="flex-end">
          <Button type="submit" variant="contained" color="primary" className={classes.submit} position="right">
            Submit
          </Button>
        </Grid>
      </Grid>
    </ValidatorForm>
  );
};

ContactForm.propTypes = {
  onSubmit: PropTypes.func,
  loggedIn: PropTypes.bool,
};

ContactForm.defaultProps = {
  onSubmit: () => {},
  loggedIn: false,
};

export default ContactForm;
