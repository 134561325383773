import { useState, useCallback } from 'react';
import { parseFloat2dp } from '../helpers/number';

const useForm = (initialValues, callback) => {
  const [inputs, setInputs] = useState(initialValues);

  const handleSubmit = useCallback(
    (event) => {
      if (event) event.preventDefault();
      callback(inputs);
    },
    [callback, inputs],
  );

  // Array input name syntax - Add a new set of inputs.
  const handleAddRowClick = useCallback(
    (groupName, attributes) => (event) => {
      if (event) event.preventDefault();

      // Set the key, uses in the React component
      const newInputs = inputs[groupName].map((input, index) => ({ ...input, key: index }));

      // Update our form state.
      setInputs({
        ...inputs,
        [groupName]: [...newInputs, { ...attributes, key: newInputs.length }],
      });
    },
    [inputs],
  );

  // Array input name syntax - Remove an existing set of inputs.
  const handleRemoveRowClick = useCallback(
    (groupName, key) => (event) => {
      if (event) event.preventDefault();

      // Filter out the group of inputs that match the key, leaving all others.
      const filteredInputs = [...inputs[groupName]]
        .filter((item, index) => index !== Number.parseInt(key, 10))
        // Reset the key, uses in the React component
        .map((input, index) => ({ ...input, key: index }));

      // Update our form state.
      setInputs({ ...inputs, [groupName]: filteredInputs });
    },
    [inputs],
  );

  const setInputValue = useCallback(
    (name, value) => {
      const isNumber = !isNaN(value) && Number.isInteger(parseFloat2dp(value));
      const convertedValue = isNumber ? Number(value) : value;

      // Array input name syntax
      if (name.includes('[')) {
        // Break down the input name into sections.
        const [groupName, key, attributes] = name.split(/[[\]]/).filter(Boolean);

        // Clone the current input state.
        const clone = { ...inputs };

        // Update the changed value.
        // eslint-disable-next-line fp/no-mutation
        clone[groupName][key][attributes] = convertedValue;

        // Update our form state.
        setInputs(() => clone);
      }

      // Standard input name syntax
      else {
        setInputs({ ...inputs, [name]: convertedValue });
      }
    },
    [inputs],
  );

  const handleInputChange = useCallback(
    (event) => {
      event.persist();

      // If this is a checkbox or radio
      if (event.target.type === 'checkbox' || event.target.type === 'radio') {
        setInputValue(event.target.name, event.target.checked ? event.target.value : 'no');
      }

      // If this is any other input.
      else {
        setInputValue(event.target.name, event.target.value);
      }
    },
    [setInputValue],
  );

  return {
    handleSubmit,
    handleInputChange,
    handleAddRowClick,
    handleRemoveRowClick,
    setInputValue,
    inputs,
  };
};

export default useForm;
