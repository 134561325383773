import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Material-UI
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';

// Helpers
import { sizeWithUnitsPerCarton } from '../../helpers/getProductsUnits';

// Hooks
import useProductOptions from '../../hooks/useProductOptions';

// Components
import ProductOptions from './ProductOptions';

// Styles
const useStyles = makeStyles((theme) => ({
  popover: {
    // pointerEvents: 'none',
  },
  paper: {
    overflow: 'visible',
    width: 580,
    display: 'flex',
  },
  spacing: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(2),
  },
  value: {
    marginLeft: theme.spacing(2),
    fontSize: '1.5rem',
  },
  removeIcon: {
    padding: 0,
    opacity: 0.3,
    transition: 'ease .2s all',
    '&:hover': {
      backgroundColor: 'transparent',
      opacity: 1,
      transition: 'ease .2s all',
    },
  },
  addIcon: {
    marginRight: theme.spacing(1),
  },
  row: {
    padding: theme.spacing(1),
  },
  form: {
    padding: 12,
  },
  total: {
    backgroundColor: '#eee',
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    padding: theme.spacing(0, 1),
  },
}));

const ModalCartAdd = ({ productId, product }) => {
  const classes = useStyles();
  const { productOptionsMatrix } = useProductOptions(product);

  // Popup Modal
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? `popover-${product.oid}` : undefined;
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  return (
    <>
      <Button aria-describedby={id} size="small" color="primary" onClick={handleClick}>
        Add to Cart
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <ProductOptions
          productId={productId}
          itemCode={product.itemCode}
          webProductName={product.webProductName}
          category={product.options.category}
          subCategory={product.options.subcategory}
          sellingPrice={product.sellingPrice}
          quantity={1}
          maximumOrderQty={product.maximumOrderQty}
          exemptGst={product.exemptGst}
          size={sizeWithUnitsPerCarton(product.options.size, product.unitsPerCtn)}
          packaging={product.options.packaging}
          sliced={product.options.sliced}
          options={productOptionsMatrix}
          disabled={false}
          showAddRow
          showDeleteRow
          showProductName={false}
          showProductCost={false}
          showActions
          onProductAdd={handleClose}
          // onProductChange
          // onProductDelete
          className={classes.paddingLeft}
        />
      </Popover>
    </>
  );
};

ModalCartAdd.propTypes = {
  productId: PropTypes.string,
  product: PropTypes.shape({
    oid: PropTypes.string,
    itemCode: PropTypes.string,
    webProductName: PropTypes.string,
    sellingPrice: PropTypes.number,
    maximumOrderQty: PropTypes.number,
    exemptGst: PropTypes.bool,
    unitsPerCtn: PropTypes.number,
    options: PropTypes.shape({
      category: PropTypes.string,
      subcategory: PropTypes.string,
      size: PropTypes.string,
      packaging: PropTypes.string,
      sliced: PropTypes.string,
    }),
  }),
};

ModalCartAdd.defaultProps = {
  productId: null,
  product: {
    oid: '1234.123',
    itemCode: '',
    webProductName: '',
    sellingPrice: 0,
    maximumOrderQty: 255,
    exemptGst: false,
    unitsPerCtn: 0,
    options: {
      category: '',
      subcategory: '',
      size: '',
      packaging: '',
      sliced: '',
    },
  },
};

export default ModalCartAdd;
