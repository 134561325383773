export const showErrors = (type) => ({
  type: 'SHOW_ERROR',
  show: type,
});

export const hideErrors = () => ({
  type: 'HIDE_ERROR',
  show: false,
});

export const setError = (message, error) => ({
  type: 'SET_ERROR',
  message,
  error,
});

export const setSuccess = (message) => ({
  type: 'SET_SUCCESS',
  message,
});

export const setErrorModal = (message) => ({
  type: 'SET_ERROR_MODAL',
  message,
});

export const setSuccessModal = (message) => ({
  type: 'SET_SUCCESS_MODAL',
  message,
});
