import Fuse from 'fuse.js';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';

export const getProductsFiltered = (type, products, category, subcategory, size, packaging, sliced, flavour, search) => {
  const paginate = cloneDeep(products);

  const fuseOptions = {
    includeScore: true,
    threshold: 0.2,
    shouldSort: false,
    keys: ['webProductName', 'itemCode', 'options.category', 'options.subcategory', 'options.size', 'options.packaging', 'options.sliced', 'options.flavour'],
  };

  return (
    Object.keys(paginate)

      // Filter the products down to the ones that match our selected type
      .filter((x) => {
        // Confirm that we are filtering on a category.
        if (!type) return true;

        Object.keys(paginate[x]).forEach((key) => {
          if (key === 'productOptionsAll' || key === 'productOptionsMatrix') return;

          // If the product has no category or the filtered category isn't tagged in this product, remove it.
          const product = paginate[x][key];

          if (type === 'ingredients' && !isEmpty(product.options) && product.options.category !== 'ingredients') {
            // eslint-disable-next-line fp/no-delete
            delete paginate[x][key];
          }
          if (type !== 'ingredients' && !isEmpty(product.options) && product.options.category === 'ingredients') {
            // eslint-disable-next-line fp/no-delete
            delete paginate[x][key];
          }
        });

        return true;
      })

      // Filter the products down to the ones that match our selected category
      .filter((x) => {
        // Confirm that we are filtering on a category.
        if (!category) return true;

        Object.keys(paginate[x]).forEach((key) => {
          if (key === 'productOptionsAll' || key === 'productOptionsMatrix') return;

          // If the product has no category or the filtered category isn't tagged in this product, remove it.
          const product = paginate[x][key];
          // eslint-disable-next-line fp/no-delete
          if (!isEmpty(product.options) && product.options.category !== category) delete paginate[x][key];
        });

        return true;
      })

      // Filter the products down to the ones that match our selected subcategory
      .filter((x) => {
        // Confirm that we are filtering on a subcategory.
        if (!subcategory) return true;

        Object.keys(paginate[x]).forEach((key) => {
          if (key === 'productOptionsAll' || key === 'productOptionsMatrix') return;

          // If the product has no subcategory or the filtered subcategory isn't tagged in this product, remove it.
          const product = paginate[x][key];
          // eslint-disable-next-line fp/no-delete
          if (product.options.subcategory !== subcategory) delete paginate[x][key];
        });

        return true;
      })

      // Filter the products down to the ones that match our selected size
      .filter((x) => {
        // Confirm that we are filtering on a size.
        if (!size) return true;

        Object.keys(paginate[x]).forEach((key) => {
          if (key === 'productOptionsAll' || key === 'productOptionsMatrix') return;

          // If the product has no size or the filtered size isn't tagged in this product, remove it.
          const product = paginate[x][key];
          // eslint-disable-next-line fp/no-delete
          if (product.options.size !== size) delete paginate[x][key];
        });

        return true;
      })

      // Filter the products down to the ones that match our selected packaging
      .filter((x) => {
        // Confirm that we are filtering on a packaging.
        if (!packaging) return true;

        Object.keys(paginate[x]).forEach((key) => {
          if (key === 'productOptionsAll' || key === 'productOptionsMatrix') return;

          // If the product has no packaging or the filtered packaging isn't tagged in this product, remove it.
          const product = paginate[x][key];
          // eslint-disable-next-line fp/no-delete
          if (product.options.packaging !== packaging) delete paginate[x][key];
        });

        return true;
      })

      // Filter the products down to the ones that match our selected sliced
      .filter((x) => {
        // Confirm that we are filtering on a sliced.
        if (!sliced) return true;

        Object.keys(paginate[x]).forEach((key) => {
          if (key === 'productOptionsAll' || key === 'productOptionsMatrix') return;

          // If the product has no sliced or the filtered sliced isn't tagged in this product, remove it.
          const product = paginate[x][key];
          // eslint-disable-next-line fp/no-delete
          if (product.options.sliced !== sliced) delete paginate[x][key];
        });

        return true;
      })

      // Filter the products down to the ones that match our selected flavour
      .filter((x) => {
        // Confirm that we are filtering on a flavour.
        if (!flavour) return true;

        Object.keys(paginate[x]).forEach((key) => {
          if (key === 'productOptionsAll' || key === 'productOptionsMatrix') return;

          // If the product has no flavour or the filtered flavour isn't tagged in this product, remove it.
          const product = paginate[x][key];
          // eslint-disable-next-line fp/no-delete
          if (product.options.flavour !== flavour) delete paginate[x][key];
        });

        return true;
      })

      // Filter the products down to the ones that match our search string
      .filter((x) => {
        // Confirm that we are filtering on a search string.
        if (!search) return true;

        Object.keys(paginate[x]).forEach((key) => {
          if (key === 'productOptionsAll' || key === 'productOptionsMatrix') return;

          // Use fuse to do a fuzy search.
          const fuse = new Fuse([paginate[x][key]], fuseOptions);
          const result = fuse.search(search);

          // If the product is a match for the search string, add the score to the product.
          if (!isEmpty(result) && result[0].score) {
            // eslint-disable-next-line fp/no-mutation
            paginate[x][key].score = result[0].score;

            // If the product is not a match for the search string, remove it.
          } else {
            // eslint-disable-next-line fp/no-delete
            delete paginate[x][key];
          }
        });

        return true;
      })

      // Remove all products that don't have at least one valid product.
      .filter((x) => {
        // Confirm that we are filtering on a anything.
        if (!search && !category && !subcategory && !size && !packaging && !sliced && !flavour) return true;

        const hasProducts = Object.keys(paginate[x]).some((value) => value !== 'productOptionsAll' && value !== 'productOptionsMatrix');
        // eslint-disable-next-line fp/no-delete
        if (!hasProducts) delete paginate[x];

        return true;
      })

      .filter((x) => !isEmpty(paginate[x]))
  );
};

export { getProductsFiltered as default };
