import React from 'react';
import PropTypes from 'prop-types';

// Material-UI
import { makeStyles } from '@material-ui/core/styles';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Link from '../shared/Link';

// Config
import constants from '../../config/constants';
import routes from '../../config/routes';

// Hooks
import useForm from '../../hooks/useForm';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    margin: theme.spacing(3, 0),
  },
  link: {
    color: theme.palette.primary.main,
    fontSize: '1rem',
    fontWeight: '400',
    margin: theme.spacing(2, 0, 0, 0),
    position: 'relative',
    top: 20,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    padding: theme.spacing(0, 4),
  },
}));

const LogInForm = ({ onSubmit, onForgot }) => {
  const classes = useStyles();

  const { inputs, handleInputChange, handleSubmit } = useForm({ email: '', password: '' }, onSubmit);

  return (
    <ValidatorForm className={classes.form} onSubmit={handleSubmit}>
      <Grid container spacing={constants.gridSpacing}>
        <Grid item xs={12}>
          <TextValidator
            id="email"
            name="email"
            value={inputs.email}
            label="User ID"
            variant="outlined"
            fullWidth
            autoFocus
            validators={['required']}
            errorMessages={['Please enter an user id', 'Please enter a valid user id']}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextValidator
            id="password"
            name="password"
            value={inputs.password}
            label="Password"
            type="password"
            variant="outlined"
            fullWidth
            validators={['required']}
            errorMessages={['Please enter a password']}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid container item xs={12} justifyContent="center">
          <Grid item xs={6}>
            <Link to={routes.auth.forgot} onClick={onForgot}>
              <Typography component="h1" className={classes.link} variant="h6">
                Forgot password?
              </Typography>
            </Link>
          </Grid>
          <Grid container item xs={6} justifyContent="flex-end">
            <Button type="submit" variant="contained" color="primary" className={classes.submit} position="right">
              Sign In
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </ValidatorForm>
  );
};

LogInForm.propTypes = {
  onForgot: PropTypes.func,
  onSubmit: PropTypes.func,
};

LogInForm.defaultProps = {
  onForgot: () => {},
  onSubmit: () => {},
};

export default LogInForm;
