import axios from 'axios';
import https from 'https';

// Date
import dayjs from 'dayjs';

// Config
import constants from '../config/constants';

// Helpers
import { translateApiError, ApiError } from '../helpers/translateApiError';

export const savePayment = async (token, debtorId, orderNo, accountNo, amount, orderDate, items, paymentDate, paymentReference, apiUrl, bankAccount) => {
  if (!token) return Promise.reject(new Error('Saving Order Failed: Debtor token is missing'));
  if (!debtorId) return Promise.reject(new Error('Saving Order Failed: Debtor ID is missing'));
  if (!orderNo) return Promise.reject(new Error('Saving Order Failed: Order Number is missing'));
  if (!accountNo) return Promise.reject(new Error('Saving Order Failed: Account Number is missing'));
  if (!amount) return Promise.reject(new Error('Saving Order Failed: Payment amount is missing'));
  if (!orderDate) return Promise.reject(new Error('Saving Order Failed: Order date is missing'));
  if (!items) return Promise.reject(new Error('Saving Order Failed: Cart items are missing'));
  if (!paymentDate) return Promise.reject(new Error('Saving Order Failed: Payment date is missing'));

  const cashbookData = {
    command: 'UpdateObject',
    parameters: {
      class: 'BKcashbookDebtors',
      token,
      oid: '',
      rCBbankAccount: bankAccount || constants.bankAccount,
      date: dayjs(paymentDate).format('YYYY-MM-DD'),
      particulars: orderNo,
      bankReference: paymentReference,
    },
  };

  // At request level
  const agent = new https.Agent({
    rejectUnauthorized: false,
  });

  console.log(`API REQUEST : STEP 1 : UpdateObject BKcashbookDebtors : apiUrl = ${apiUrl || constants.apiUrl} :: `, JSON.stringify(cashbookData));

  const cashbookId = await axios
    .post(apiUrl || constants.apiUrl, cashbookData, { httpsAgent: agent })
    .then((response) => translateApiError(response))
    .then((response) => {
      // console.log(`API REQUEST : STEP 1B : savePayment : response = `, response);

      const responseId = response.data.data.changedObjects[0].savedOid || null;
      if (!responseId) return Promise.reject(new ApiError('Payment Failed: Cashbook transaction is missing', 'notification', false, response));
      return responseId;
    });

  const receiptData = {
    command: 'UpdateObject',
    parameters: {
      class: 'BKdebtorReceipt',
      token,
      oid: '',
      rDBdebtor: debtorId,
      date: dayjs(paymentDate).format('YYYY-MM-DD'),
      receipt: amount,
      reference: paymentReference,
      payMethod: 'Credit Card',
      rCBtransaction: cashbookId,
    },
  };

  console.log(`API REQUEST : STEP 2 : UpdateObject BKdebtorReceipt : apiUrl = ${apiUrl || constants.apiUrl} :: `, JSON.stringify(receiptData));

  const receiptId = await axios
    .post(apiUrl || constants.apiUrl, receiptData, { httpsAgent: agent })
    .then((response) => translateApiError(response))
    .then((response) => {
      const responseId = response.data.data.changedObjects[0].savedOid || null;
      if (!responseId) return Promise.reject(new ApiError('Payment Failed: Cashbook transaction is missing', 'notification', false, response));
      return responseId;
    });

  console.log(`API REQUEST : STEP 3A : convert before : items = `, JSON.stringify(items));

  // eslint-disable-next-line no-unused-vars
  const docketLines = Object.entries(items).reduce(
    (a, [k, item]) => [
      ...a,
      item.unitPrice ? { rBKproduct: item.oid, quantity: item.quantity, unitPrice: item.unitPrice } : { rBKproduct: item.oid, quantity: item.quantity },
    ],
    [],
  );

  console.log(`API REQUEST : STEP 3B : convert after : docketLines = `, JSON.stringify(docketLines));

  const docketSaveData = {
    command: 'UpdateObject',
    parameters: {
      class: 'BKdocket',
      token,
      rBKdebtor: debtorId,
      date: dayjs(orderDate).format('YYYY-MM-DD'),
      orderNo,
      rBKdocketLine: docketLines,
    },
  };

  console.log(`API REQUEST : STEP 4 : UpdateObject BKdocket : apiUrl = ${apiUrl || constants.apiUrl} :: `, JSON.stringify(docketSaveData));

  const docketId = await axios
    .post(apiUrl || constants.apiUrl, docketSaveData, { httpsAgent: agent })
    .then((response) => translateApiError(response))
    .then((response) => {
      const responseId = response.data.data.changedObjects[0].savedOid || null;
      if (!responseId) return Promise.reject(new ApiError('Payment Failed: Cashbook transaction is missing', 'notification', false, response));
      return responseId;
    });

  const docketData = {
    command: 'GetClassObjects',
    parameters: {
      class: 'BKdocket',
      token,
      oids: docketId,
      properties: {
        rBKdebtorInvoice: '',
      },
    },
  };

  console.log(`API REQUEST : STEP 5 : GetClassObjects BKdocket : apiUrl = ${apiUrl || constants.apiUrl} :: `, JSON.stringify(docketData));

  const invoiceId = await axios
    .post(apiUrl || constants.apiUrl, docketData, { httpsAgent: agent })
    .then((response) => translateApiError(response))
    .then((response) => {
      const responseId = response.data.data.objects[0].rBKdebtorInvoice.oid || null;
      if (!responseId) return Promise.reject(new ApiError('Payment Failed: Cashbook transaction is missing', 'notification', false, response));
      return responseId;
    });

  const allocationData = {
    command: 'CreateCashAllocation',
    parameters: {
      token,
      destination: receiptId,
      source: invoiceId,
      gross: amount,
    },
  };

  console.log(`API REQUEST : STEP 6 : CreateCashAllocation : apiUrl = ${apiUrl || constants.apiUrl} :: `, JSON.stringify(allocationData));

  const allocationId = await axios
    .post(apiUrl || constants.apiUrl, allocationData, { httpsAgent: agent })
    .then((response) => translateApiError(response))
    .then((response) => {
      const responseId = response.data.data.oid || null;
      if (!responseId) return Promise.reject(new ApiError('Payment Failed: Cashbook transaction is missing', 'notification', false, response));
      return responseId;
    });

  return {
    cashbookId,
    receiptId,
    docketId,
    invoiceId,
    allocationId,
  };
};

export default savePayment;
