const formatter = new Intl.NumberFormat('en-AU', {
  style: 'currency',
  currency: 'AUD',
});

const dollars = (number) => formatter.format(number);
const parseFloat2dp = (number) => Math.round(parseFloat(number) * 100) / 100;

export { dollars, parseFloat2dp };
export default dollars;
