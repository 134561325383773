export const getProductsCount = (products) => {
  let count = 0;

  Object.entries(products).forEach((product) => {
    Object.entries(product).forEach(([productOid, productVariation]) => {
      // Only count the product options, not the options.
      if (productOid !== 'productOptionsAll' && productOid !== 'productOptionsMatrix' && productVariation) {
        // eslint-disable-next-line fp/no-mutation
        count += 1;
      }
    });
  });

  return count;
};

export { getProductsCount as default };
