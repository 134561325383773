import React from 'react';
import { useSelector } from 'react-redux';

// Material-UI
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

// Actions
import { hideModal } from '../../actions/modals';

// Hooks
import useAction from '../../hooks/useAction';
import useModalShow from '../../hooks/useModalShow';

// Reducers
import { getErrorModal } from '../../reducers';

// Styles
const useStyles = makeStyles((theme) => ({
  dialog: {},
  dialogContent: {
    textAlign: 'center',
    padding: theme.spacing(2, 2, 3, 2),
  },
  button: {
    marginLeft: theme.spacing(2),
  },
  header: {
    backgroundColor: theme.palette.action.selected,
    textAlign: 'center',
  },
  title: {
    padding: theme.spacing(2, 0, 0, 0),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  error: {
    whiteSpace: 'pre-wrap',
  },
  footer: {
    backgroundColor: theme.palette.action.selected,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    padding: theme.spacing(2),

    [theme.breakpoints.down('xs')]: {
      textAlign: 'right',
    },
  },
  totals: {
    [theme.breakpoints.up('sm')]: {
      order: 2,
    },
  },
  tooltip: {
    fontSize: 14,
    color: '#fff',
    padding: theme.spacing(1),
  },
}));

const ModalError = () => {
  const classes = useStyles();

  const { openModal } = useModalShow();
  const onHideModal = useAction(hideModal);
  const errorModalMessage = useSelector(getErrorModal);
  const errorMessage = errorModalMessage || `We're sorry but your order cannot be placed right now.`;

  return (
    <Dialog open={openModal === 'error'} onClose={onHideModal} scroll="paper" fullWidth maxWidth="sm" className={classes.dialog}>
      <DialogTitle className={classes.header} disableTypography>
        <IconButton className={classes.closeButton} onClick={onHideModal}>
          <CloseIcon />
        </IconButton>
        <Typography variant="h4" className={classes.title}>
          There&apos;s an issue with our website.
        </Typography>
      </DialogTitle>
      <DialogContent dividers className={classes.dialogContent}>
        <Grid container direction="column" justifyContent="center" alignItems="center">
          <Grid item xs={12}>
            <Typography className={classes.error}>{errorMessage}</Typography>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ModalError;
