import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

function ScrollToTop({ history }) {
  useEffect(() => {
    const unlisten = history.listen((location) => {
      if (!location.state || !location.state.disableScroll || location.state.disableScroll !== true) window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, [history]);

  return null;
}

ScrollToTop.propTypes = {
  history: PropTypes.shape({
    listen: PropTypes.func.isRequired,
  }).isRequired,
};

export default withRouter(ScrollToTop);
