import React from 'react';
import PropTypes from 'prop-types';

// Material-UI
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

// Styles
const useStyles = makeStyles((theme) => ({
  columnOne: {
    width: 300,
    verticalAlign: 'top',

    [theme.breakpoints.down('sm')]: {
      width: 100,
    },
  },
}));

function createData(name, description) {
  return { name, description };
}

const ProductDetail = ({ product }) => {
  const classes = useStyles();

  const dimensionsUnit = (product && product.productDimensionsUnit && product.productDimensionsUnit.toLowerCase()) || 'mm';

  const rows = [
    createData('Product Code:', `${product.itemCode || ''}`),
    createData('Product Name:', `${product.webProductName || ''}`),
    createData('Product Description:', `${product.webProductDescription || ''}`),
    createData(
      'Dimensions:',
      product.productDiameter
        ? `${product.productDiameter || '0'}${dimensionsUnit} (diameter)
            x ${product.productHeight || '0'}${dimensionsUnit} (height)`
        : `${product.productLength || '0'}${dimensionsUnit} (length)
            x ${product.productWidth || '0'}${dimensionsUnit} (width)
            x ${product.productHeight || '0'}${dimensionsUnit} (height)`,
    ),
    createData('Units Per Package:', `${product.unitsPerCtn || '0'}`),
    createData('Serving Size:', `${product.servingSize || '0'}g`),
    createData('Storage:', `${product.storageConditions || ''}`),
    createData('Shelf Life:', `${product.shelfLifeDays || '0'} days`),
    createData('Country of Origin:', `${(product.rBKcountryOfOrigin && product.rBKcountryOfOrigin.country) || 'Australia'}`),
    createData('Serving Suggestions:', ''),
  ];

  return (
    <Grid item xs={12}>
      <Table aria-label="simple table">
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.name}>
              <TableCell component="th" scope="row" className={classes.columnOne}>
                <Typography variant="h5">{row.name}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle1">{row.description}</Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Grid>
  );
};

ProductDetail.propTypes = {
  product: PropTypes.shape({
    oid: PropTypes.string,
    itemCode: PropTypes.string,
    webProductName: PropTypes.string,
    webProductDescription: PropTypes.string,
    productLength: PropTypes.number,
    productHeight: PropTypes.number,
    productWidth: PropTypes.number,
    productDiameter: PropTypes.number,
    productDimensionsUnit: PropTypes.string,
    unitsPerCtn: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    servingSize: PropTypes.number,
    storageConditions: PropTypes.string,
    shelfLifeDays: PropTypes.number,
    rBKcountryOfOrigin: PropTypes.shape({
      country: PropTypes.string,
    }),
  }),
};

ProductDetail.defaultProps = {
  product: {
    oid: '',
    itemCode: '',
    webProductName: '',
    webProductDescription: '',
    productLength: 0,
    productHeight: 0,
    productWidth: 0,
    productDiameter: 0,
    productDimensionsUnit: '',
    unitsPerCtn: 0,
    servingSize: 0,
    storageConditions: '',
    shelfLifeDays: 0,
    rBKcountryOfOrigin: {
      country: '',
    },
  },
};

export default ProductDetail;
