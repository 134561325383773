import { getCartHasProduct } from './getCartHasProduct';

// Get the days of the week that are available for delivery.
// Product in the cart affect what days are available. Make sure to factor them in to the available dates.
export const getOrderDeliveryDates = (deliveryDates, cartItems) => {
  const hasSandwiches = getCartHasProduct('sandwiches/wraps', cartItems);

  if (hasSandwiches) {
    return { ...deliveryDates, tuesday: false, thursday: false, saturday: false, sunday: false };
  }

  return deliveryDates;
};

export { getOrderDeliveryDates as default };
