import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

// Actions
import { setCartDate } from '../actions/cart';

// Hooks
import useAction from './useAction';

// Reducers
import { getCartDate } from '../reducers';

const useCartDate = () => {
  const cartDate = useSelector(getCartDate);
  const saveCartDate = useAction(setCartDate);

  // Store the date into localStorage. The date will be used if the user refreshes the page.
  useEffect(() => {
    if (!isEmpty(cartDate)) localStorage.setItem('cartDate', cartDate);

    // saveCartDate;
  }, [cartDate]);

  return [cartDate, saveCartDate];
};

export default useCartDate;
