// API
import { getProductsLoading, getProductsLoaded, getProductLoading } from '../reducers';
import { fetchProducts, fetchProduct, fetchProductImage, fetchProductsRelated } from '../api/Products';

// Actions
import { setError } from './notifications';
import { setUserExpired } from './users';

// Config
import blacklistProductCodes from '../config/blacklistProductCodes';

// Helpers
import { getApiProductOptions, getProductOptions } from '../helpers/getProductOptions';

export const setBlacklistProducts = (products) => ({
  type: 'SET_BLACKLIST_PRODUCTS',
  products,
});

export const resetBlacklistProduct = () => ({
  type: 'RESET_BLACKLIST_PRODUCT',
});

export const setProducts = (products) => ({
  type: 'SET_PRODUCTS',
  products,
});

export const setProductsFilter = (search) => ({
  type: 'SET_PRODUCTS_FILTER',
  search,
});

export const setProductsLoading = () => ({
  type: 'SET_PRODUCTS_LOADING',
});

export const resetProducts = () => ({
  type: 'RESET_PRODUCTS',
});

export const setProductsRelated = (productsRelated) => ({
  type: 'SET_PRODUCTS_RELATED',
  productsRelated,
});

export const setProductsRelatedLoading = () => ({
  type: 'SET_PRODUCTS_RELATED_LOADING',
});

export const setProduct = (product) => ({
  type: 'SET_PRODUCT',
  product,
});

export const setProductLoading = (oid) => ({
  type: 'SET_PRODUCT_LOADING',
  oid,
});

export const resetProduct = () => ({
  type: 'RESET_PRODUCT',
});

export const setProductImage = (oid, picture, width) => ({
  type: 'SET_PRODUCT_IMAGE',
  product: {
    oid,
    picture,
    width,
  },
});

export const setProductImageLoading = (oid, width) => ({
  type: 'SET_PRODUCT_IMAGE_LOADING',
  oid,
  width,
});

export const loadProducts = () => async (dispatch, getState) => {
  const state = getState();
  const cacheExpiresIn = 1 * 60 * 60 * 1000; // 1 hour

  if (getProductsLoading(state)) {
    return Promise.resolve();
  }

  if (getProductsLoaded(state) + cacheExpiresIn > Date.now()) {
    return Promise.resolve();
  }

  dispatch(setProductsLoading());

  const token = localStorage.getItem('tokens'); // Retrieve the token from localStorage. The token will be used if the user refreshes the page.
  const debtorId = localStorage.getItem('debtor_id'); // Retrieve the debtor_id from localStorage. The debtor_id is used to fetch related records from the API
  const cachedProducts = localStorage.getItem('products');
  const cachedBlacklistProducts = localStorage.getItem('blacklist_products');

  if (cachedProducts) {
    dispatch(setProducts(JSON.parse(cachedProducts)));
    dispatch(setBlacklistProducts(JSON.parse(cachedBlacklistProducts)));
    return Promise.resolve();
  }

  // Fetch the products available to the debtor.
  return fetchProducts(token, debtorId)
    .then((response) => {
      const priceBook = {
        ...response.rBKpriceBook.rBKpriceBookLine,
      };

      console.time('Processing sortedProducts');

      const blacklistProducts = {};

      // eslint-disable-next-line fp/no-mutating-methods
      const sortedProducts = Object.entries(priceBook)

        // Sort the list of products by product name.
        .slice()
        .filter((item, index) => item[1].rBKproductFinished.obsolete === false)

        // Remove the blacklisted products e.g. surcharge, delivery charge etc
        .filter((item, index) => {
          if (blacklistProductCodes.includes(item[1].rBKproductFinished.itemCode.trim().toLowerCase())) {
            // eslint-disable-next-line fp/no-mutation
            blacklistProducts[item[1].rBKproductFinished.webProductName] = item[1].rBKproductFinished;
            return false;
          }
          return true;
        })

        .sort((a, b) =>
          a[1].rBKproductFinished.webProductName.trim().localeCompare(b[1].rBKproductFinished.webProductName.trim(), undefined, {
            numeric: true,
            sensitivity: 'base',
          }),
        )

        // Manipulate the result into something more suitable for our application.
        .reduce(
          (a, [, product]) => ({
            ...a,
            [product.rBKproductFinished.webProductName]: getApiProductOptions(a[product.rBKproductFinished.webProductName], product),
          }),
          {},
        );

      console.timeEnd('Processing sortedProducts');

      // Cache sorted products for page reloads.
      localStorage.setItem('products', JSON.stringify(sortedProducts));
      localStorage.setItem('blacklist_products', JSON.stringify(blacklistProducts));

      // Store the products into Redux state
      dispatch(setProducts(sortedProducts));
      dispatch(setBlacklistProducts(blacklistProducts));
      return response;
    })
    .catch((error) => {
      if (error.message) dispatch(setError(String(error.message), error));
      if (error.expired) dispatch(setUserExpired());
      return error;
    });
};

export const loadProduct = (oid) => async (dispatch, getState) => {
  if (!oid) return false;
  const state = getState();

  if (getProductLoading(state, oid)) {
    return Promise.resolve();
  }

  dispatch(resetProduct());
  dispatch(setProductLoading(oid));

  const token = localStorage.getItem('tokens'); // Retrieve the token from localStorage. The token will be used if the user refreshes the page.

  return fetchProduct(token, oid)
    .then((response) => {
      if (response.errorCode !== 0) throw new Error(response.errorMessage);
      if (response.tokenStatus !== 0) throw new Error('Login Expired');

      // Use the helper to identify all the options (categories, sizes and packaging) for these products.
      const options = getProductOptions(response.data);

      // Store the product and options
      dispatch(setProduct({ ...response.data, ...options }));

      return response;
    })
    .catch((error) => {
      if (error.message) dispatch(setError(String(error.message), error));
      if (error.expired) dispatch(setUserExpired());
      return error;
    });
};

export const loadProductImage = (oid, itemCode, width) => async (dispatch, getState) => {
  if (!oid || !itemCode) return false;

  const token = localStorage.getItem('tokens'); // Retrieve the token from localStorage. The token will be used if the user refreshes the page.

  dispatch(setProductImageLoading(oid, width));

  return fetchProductImage(token, itemCode, width)
    .then((response) => {
      const { url } = response;
      dispatch(setProductImage(oid, url, width));
      return url;
    })
    .catch((error) => {
      if (error.message && !error.message.includes('404') && !error.message.includes('Network Error')) dispatch(setError(String(error.message), error));
      if (error.expired) dispatch(setUserExpired());
      return error;
    });
};

export const loadProductsRelated = (oid, limit, offset) => async (dispatch) => {
  dispatch(setProductsRelatedLoading());
  const productsRelated = await fetchProductsRelated(oid, limit, offset);
  dispatch(setProductsRelated(productsRelated));
  return productsRelated;
};
