import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

// Material-UI
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

// Actions
import useAction from '../../hooks/useAction';
import { showModal } from '../../actions/modals';
import { setError } from '../../actions/notifications';

// Hooks
import useCartItems from '../../hooks/useCartItems';
import useOrdersLoad from '../../hooks/useOrdersLoad';
import useDeliveryDateNext from '../../hooks/useDeliveryDateNext';

// Components
import Footer from './Footer';
import Header from './Header';
import NavigationOrder from './NavigationOrder';

// Styles
const useStyles = makeStyles(() => ({
  body: {},
}));

const Layout = ({ currentUrl, children }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const showNotImplementedError = useAction(() => setError('TODO: This feature has not been implemented yet.'));

  // Cart
  const { cartLength, hasDelivery } = useCartItems();
  const onShowModal = useCallback(() => dispatch(showModal('cart', true)), [dispatch]);

  // Orders
  const { ordersLength } = useOrdersLoad('scheduled', 1, 0);

  // Delivery Dates
  const { deliveryDateNext } = useDeliveryDateNext();

  return (
    <Container className={classes.body}>
      <Header currentUrl={currentUrl} />
      <NavigationOrder
        deliveryDate={deliveryDateNext}
        cartLength={hasDelivery ? cartLength - 1 : cartLength}
        ordersLength={ordersLength}
        onPlaceOrderClick={showNotImplementedError} // onShowModal
        onViewOrdersClick={showNotImplementedError}
        onAddCartClick={showNotImplementedError}
        onViewCartClick={onShowModal}
      />
      {children}
      <Footer />
    </Container>
  );
};

Layout.propTypes = {
  currentUrl: PropTypes.string,
  children: PropTypes.node,
};

Layout.defaultProps = {
  currentUrl: '/',
  children: '',
};

export default Layout;
