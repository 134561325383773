import React from 'react';
import PropTypes from 'prop-types';

// Material-UI
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

// Config
import constants from '../../config/constants';

// Assets
import logo from '../../assets/images/logo.png';
import logoMini from '../../assets/images/logo-mini.png';
import logoWhite from '../../assets/images/logo-white.png';

// Define valid options for variants
export const optionsVariant = {
  normal: logo,
  white: logoWhite,
  mini: logoMini,
};

// Styles
const useStyles = makeStyles(() => ({
  logo: {
    maxWidth: '100%',
  },
}));

const SiteLogo = ({ variant, height }) => {
  const classes = useStyles();

  return (
    <Grid item>
      {height ? (
        <img src={optionsVariant[variant]} height={height} className={classes.logo} alt={constants.appName} />
      ) : (
        <img src={optionsVariant[variant]} className={classes.logo} alt={constants.appName} />
      )}
    </Grid>
  );
};

SiteLogo.propTypes = {
  variant: PropTypes.oneOf(Object.keys(optionsVariant)),
  height: PropTypes.string,
};

SiteLogo.defaultProps = {
  variant: 'normal',
  height: 'auto',
};

export default SiteLogo;
