import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import humanizeString from 'humanize-string';

// Material-UI
import { makeStyles } from '@material-ui/core/styles';
import { TextValidator, SelectValidator } from 'react-material-ui-form-validator';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import Typography from '@material-ui/core/Typography';

// Helpers
import { dollars } from '../../helpers/number';

// Hooks
import useProductFilter from '../../hooks/useProductFilter';

// Styles
const useStyles = makeStyles((theme) => ({
  drop: theme.drop,
  dropHalf: theme.dropHalf,

  removeButton: {
    flexGrow: 'inherit',
    left: -10,
    position: 'relative',
    top: 5,
    width: 48,
  },
  text: {
    position: 'relative',
  },
  titleText: {
    fontSize: '0.9rem',
    fontWeight: 300,
    position: 'absolute',
    top: '42px',
    transform: 'translateY(-50%)',
  },
  quantity: {
    maxWidth: 50,
  },
  inputQuantity: {
    minWidth: 30,
  },
  total: {
    [theme.breakpoints.up('sm')]: {
      maxWidth: 100,
    },
  },
  totalText: {
    fontSize: '0.9rem',
    fontWeight: 300,
    position: 'absolute',
    right: 12,
    top: '42px',
    transform: 'translateY(-50%)',
  },
  input: {
    textTransform: 'capitalize',
    minWidth: 101,
  },
}));

const ProductOptionsLineItems = ({
  index,
  options,
  lineItem,
  disabled,
  showAddRow,
  showDeleteRow,
  showProductName,
  showProductCost,
  handleInputChange,
  handleInputBlur,
  handleRemoveRowClick,
  onInputChange,
}) => {
  const classes = useStyles();

  // Use the line item information to confirm the selected product is correct.
  const selectedProduct = useProductFilter(lineItem, lineItem.size, lineItem.packaging, lineItem.sliced);

  // If the product is not set or a selected option has changed, push the update up to the parent.
  useEffect(() => {
    if (!isEmpty(selectedProduct) && (isEmpty(lineItem.oid) || lineItem.oid !== selectedProduct.oid)) {
      onInputChange(index, selectedProduct);
    }
  }, [index, selectedProduct, lineItem, onInputChange]);

  return (
    <>
      {showProductName && (
        <Grid item xs={12} sm className={classes.text}>
          <Typography className={classes.titleText}>{lineItem.webProductName}</Typography>
        </Grid>
      )}

      <Grid key={`ModalCartAddLineItems-${index}`} item xs={12} sm className={classes.quantity}>
        <TextValidator
          id={`quantity-${index}`}
          name={`products[${index}][quantity]`}
          value={lineItem.quantity}
          label="Quantity"
          fullWidth
          autoFocus
          type="number"
          disabled={disabled}
          validators={['required', 'isNumber', 'minNumber:0', `maxNumber:${selectedProduct.maximumOrderQty > 0 ? selectedProduct.maximumOrderQty : 255}`]}
          errorMessages={[
            'Please enter a quantity',
            'Please enter a valid quantity',
            `Please enter a quantity greater than 0`,
            `The maximum available is ${
              selectedProduct.maximumOrderQty > 0 ? selectedProduct.maximumOrderQty : 255
            }. Please reduce your quantity or call us to see what we can do.`,
          ]}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          className={classes.inputQuantity}
        />
      </Grid>

      {options && options.size && (
        <Grid item xs={12} sm>
          <SelectValidator
            id={`size-${index}`}
            name={`products[${index}][size]`}
            value={lineItem.size}
            label="Size"
            fullWidth
            disabled={disabled}
            validators={['required']}
            errorMessages={['Please select a size']}
            onChange={handleInputChange}
            className={classes.input}
          >
            {options.size.map((option) => (
              <MenuItem key={`ProductOptionsLineItems-Size-${option}`} value={option} className={classes.select}>
                {humanizeString(option)}
              </MenuItem>
            ))}
          </SelectValidator>
        </Grid>
      )}

      {options && options.packaging && (
        <Grid item xs={12} sm>
          <SelectValidator
            id={`packaging-${index}`}
            name={`products[${index}][packaging]`}
            value={lineItem.packaging}
            label="Packaging"
            fullWidth
            disabled={disabled}
            validators={['required']}
            errorMessages={['Please select a packaging option']}
            onChange={handleInputChange}
            className={classes.input}
          >
            {options.packaging.map((option) => (
              <MenuItem key={`ProductOptionsLineItems-Packaging-${option}`} value={option}>
                {humanizeString(option)}
              </MenuItem>
            ))}
          </SelectValidator>
        </Grid>
      )}

      {options && options.sliced && (
        <Grid item xs={12} sm>
          <SelectValidator
            id={`sliced-${index}`}
            name={`products[${index}][sliced]`}
            value={lineItem.sliced}
            label="Portion"
            fullWidth
            disabled={disabled}
            validators={['required']}
            errorMessages={['Please select a sliced option']}
            onChange={handleInputChange}
            className={classes.input}
          >
            {options.sliced.map((option) => (
              <MenuItem key={`ProductOptionsLineItems-Sliced-${option}`} value={option}>
                {humanizeString(option)}
              </MenuItem>
            ))}
          </SelectValidator>
        </Grid>
      )}

      {showProductCost && (
        <Grid item xs={10} sm className={`${classes.text} ${classes.total}`}>
          <Typography align="right" className={classes.totalText}>
            {dollars(lineItem.sellingPrice * lineItem.quantity)}
          </Typography>
        </Grid>
      )}

      {showDeleteRow && (
        <Grid item xs={2} sm className={classes.removeButton}>
          <IconButton onClick={handleRemoveRowClick('products', index)}>
            <RemoveCircleIcon />
          </IconButton>
        </Grid>
      )}
    </>
  );
};

ProductOptionsLineItems.propTypes = {
  index: PropTypes.number,
  options: PropTypes.shape({
    size: PropTypes.arrayOf(PropTypes.string),
    packaging: PropTypes.arrayOf(PropTypes.string),
    sliced: PropTypes.arrayOf(PropTypes.string),
  }),
  lineItem: PropTypes.shape({
    oid: PropTypes.string,
    webProductName: PropTypes.string,
    quantity: PropTypes.number,
    packaging: PropTypes.string,
    size: PropTypes.string,
    sliced: PropTypes.string,
    sellingPrice: PropTypes.number,
  }),
  disabled: PropTypes.bool,
  showAddRow: PropTypes.bool,
  showDeleteRow: PropTypes.bool,
  showProductName: PropTypes.bool,
  showProductCost: PropTypes.bool,
  onInputChange: PropTypes.func,
  handleInputChange: PropTypes.func,
  handleInputBlur: PropTypes.func,
  handleRemoveRowClick: PropTypes.func,
};

ProductOptionsLineItems.defaultProps = {
  index: 0,
  options: {
    size: [],
    packaging: [],
    sliced: [],
  },
  lineItem: {
    oid: '',
    webProductName: '',
    quantity: 0,
    packaging: '',
    size: '',
    sliced: '',
    sellingPrice: 0,
  },
  disabled: false,
  showAddRow: false,
  showDeleteRow: false,
  showProductName: false,
  showProductCost: false,
  onInputChange: () => {},
  handleInputBlur: () => {},
  handleInputChange: () => {},
  handleRemoveRowClick: () => {},
};

export default ProductOptionsLineItems;
