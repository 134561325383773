import first from 'lodash/first';

import { useSelector } from 'react-redux';
import { getOrders } from '../reducers';

const useOrdersNext = () => {
  const { scheduled } = useSelector(getOrders);
  const { loading, orders } = scheduled;
  return { ordersNextLoading: loading, ordersNext: first(orders) };
};

export default useOrdersNext;
