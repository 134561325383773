import { activateUser, reactivateUser, fetchUser } from '../api/Users';

// Actions
import { setError, setErrorModal } from './notifications';
import { showModal } from './modals';

export const saveUser = (userId, details) => async (dispatch) => {
  dispatch(setError('TODO: This feature has not been implemented yet. Waiting on SSi to finalise a new API'));
};

export const saveUserPassword = (userId, password, passwordConf) => async (dispatch) => {
  dispatch(setError('TODO: This feature has not been implemented yet. Waiting on SSi to finalise a new API'));
};

export const setUserLoaded = () => ({
  type: 'SET_USER_LOADED',
});

export const setUserLoading = () => ({
  type: 'SET_USER_LOADING',
});

export const setUserSaving = () => ({
  type: 'SET_USER_SAVING',
});

export const setUserSaved = () => ({
  type: 'SET_USER_SAVED',
});

export const setUserExpired = () => ({
  type: 'SET_SESSION_EXPIRED',
});

export const authenticateUser = (username, password) => async (dispatch) => {
  if (!username || !password) return false;

  dispatch(setUserLoading());

  return activateUser(username, password)
    .then((response) => {
      const debtor = 'rBKwebUserLink' in response.data && 'rBKdebtor' in response.data.rBKwebUserLink[0] ? response.data.rBKwebUserLink[0].rBKdebtor : {};
      const user = { token: response.data.token, tokenLifetimeMins: response.data.tokenLifetimeMins, ...debtor };
      dispatch({ type: 'SET_SESSION_DATA', user });
      return response;
    })
    .catch((error) => {
      if (error.message) dispatch(setError(String(error.message), error));
      dispatch(setUserSaved());
      return error;
    });
};

export const reauthenticateUser = () => async (dispatch) => {
  const token = localStorage.getItem('tokens'); // Retrieve the token from localStorage. The token will be used if the user refreshes the page.
  if (!token) return false;

  return reactivateUser(token)
    .then((response) => response)
    .catch((error) => {
      if (error.message) dispatch(setError(String(error.message), error));
      if (error.expired) dispatch(setUserExpired());
      return error;
    });
};

export const setAuthenticationToken = (token) => ({
  type: 'SET_AUTH_TOKEN',
  token,
});

export const loadUser = (debtorId) => async (dispatch) => {
  if (!debtorId) {
    dispatch(setUserLoaded());
    return false;
  }

  dispatch(setUserLoading());

  const token = localStorage.getItem('tokens'); // Retrieve the token from localStorage. The token will be used if the user refreshes the page.
  if (!token) {
    dispatch(setUserExpired());
    return false;
  }

  return fetchUser(token, debtorId)
    .then((response) => {
      dispatch({ type: 'SET_SESSION_DATA', user: { token, ...response.data } });
      return response;
    })
    .catch((error) => {
      // If the API request timed out or there was a network error, so a message asking them to order manually.
      if (error.message.toLowerCase().includes('network error') || error.message.toLowerCase().includes('timeout of')) {
        dispatch(
          setErrorModal(
            'Please contact us via email or phone and let us know.\n\nWe will be happy to take your order manually until we can sort out this issue. ',
          ),
        );
        dispatch(showModal('error', true));
        dispatch(setUserLoaded());
      }

      // For all other errors, show the message using the notification error.
      else {
        if (error.message) if (error.message) dispatch(setError(String(error.message), error));
        if (error.expired) dispatch(setUserExpired());
      }
      return error;
    });
};
