import React from 'react';

// Material-UI
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Divider } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Hidden from '@material-ui/core/Hidden';

// Config
import constants from '../../config/constants';
import routes from '../../config/routes';

// Hooks
import useAction from '../../hooks/useAction';

// Actions
import { setUserExpired } from '../../actions/users';

// Components
// import Amazon from '../../assets/images/payment/8.png';
// import AmericanExpress from '../../assets/images/payment/22.png';
// import Cirrus from '../../assets/images/payment/4.png';
// import Discover from '../../assets/images/payment/14.png';
// import GoogleWallet from '../../assets/images/payment/11.png';
import Link from './Link';
import Maestro from '../../assets/images/payment/3.png';
import MasterCard from '../../assets/images/payment/2.png';
import PayPal from '../../assets/images/payment/5.png';
import SiteLogo from './SiteLogo';
import Visa from '../../assets/images/payment/1.png';

const useStyles = makeStyles((theme) => ({
  right: {
    lineHeight: '40px',
    textAlign: 'right',
  },
  logo: {
    width: '12.5%',
    marginLeft: '5px',
  },
  topSpacing: {
    marginTop: theme.spacing(6),
  },
  bottomSpacing: {
    marginBottom: theme.spacing(1),
  },
  topPadding: {
    paddingTop: theme.spacing(3),
  },
  link: {
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}));

export default function BottomAppBar() {
  const classes = useStyles();
  const theme = useTheme();

  // Responsive
  const isMobile = !useMediaQuery(theme.breakpoints.up(constants.isMobileBreakpoint));

  const onLogout = useAction(() => setUserExpired());

  const handleLogout = (event) => {
    event.preventDefault();
    onLogout();
  };

  return (
    <>
      <Divider className={classes.topSpacing} light />
      <Grid container spacing={constants.gridSpacing} className={classes.topPadding}>
        <Grid item xs={12} md={8}>
          <Typography variant="h5" color="textPrimary" className={classes.bottomSpacing}>
            VSE International
          </Typography>
          <Typography variant="subtitle2">Postal Address:</Typography>
          <Link color="inherit" isInternal={false} target="_blank" to="https://maps.app.goo.gl/bJvsYZn3zZNkWAgS8">
            <Typography variant="subtitle2" className={classes.link}>
              6-8 O&apos;Connell St, Newtown NSW 2042, Australia
            </Typography>
          </Link>
          <Link color="inherit" isInternal={false} target="_blank" to="tel:0061293174377">
            <Typography variant="subtitle2" className={classes.link}>
              P: 02 9317 4377
            </Typography>
          </Link>
          <Link color="inherit" isInternal={false} target="_blank" to="tel:0061293174377">
            <Typography variant="subtitle2" className={classes.link}>
              F: 02 9317 4954
            </Typography>
          </Link>
          <Link color="inherit" isInternal={false} to="mailto:sales@vseinternational.com.au">
            <Typography variant="subtitle2" className={classes.link}>
              E: sales@vseinternational.com.au
            </Typography>
          </Link>
          <Typography variant="subtitle2">ABN: 20203426501</Typography>
        </Grid>
        <Hidden xsUp>
          <Grid item xs={12} md={4}>
            <Typography variant="h5" color="textPrimary" className={classes.bottomSpacing}>
              We Accept
            </Typography>
            <img src={Visa} alt="Visa" className={classes.logo} />
            <img src={MasterCard} alt="MasterCard" className={classes.logo} />
            <img src={Maestro} alt="Maestro" className={classes.logo} />
            {/* <img src={Cirrus} alt={'Cirrus'} className={classes.logo} /> */}
            <img src={PayPal} alt="PayPal" className={classes.logo} />
            {/* <img src={Amazon} alt={'Amazon'} className={classes.logo} /> */}
            {/* <img src={GoogleWallet} alt={'GoogleWallet'} className={classes.logo} /> */}
            {/* <img src={Discover} alt={'Discover'} className={classes.logo} /> */}
            {/* <img src={AmericanExpress} alt={'AmericanExpress'} className={classes.logo} /> */}
          </Grid>
        </Hidden>
        <Grid item xs={12} md={4} className={`${isMobile ? '' : classes.right}`}>
          <Link color="inherit" to={routes.private.favourites}>
            <SiteLogo variant="normal" height={`${isMobile ? '60' : '100'}`} />
          </Link>
          <Typography color="textSecondary" variant="subtitle2">
            Copyright © 2020 VSE. All Rights Reserved.
          </Typography>
          <Link color="inherit" to={routes.auth.logout} onClick={handleLogout}>
            <Typography variant="subtitle2" className={classes.link}>
              Logout
            </Typography>
          </Link>
        </Grid>
      </Grid>
    </>
  );
}
