import trim from 'lodash/trim';

// Config
import routes from '../config/routes';

export const identifyCurrentRoute = (location) => {
  let currentRoute = false;

  const pathName = location.pathname;
  const pathNameWithoutParams = pathName.split('/', 2).join('/');

  // Find the current page in the routes
  const sections = Object.entries(routes).reduce((obj, [key, route]) => {
    // eslint-disable-next-line fp/no-mutation
    currentRoute = trim(
      Object.values(route).find((subRoutes) => subRoutes === pathNameWithoutParams || subRoutes === pathName),
      '/',
    );

    return currentRoute ? [...obj, key] : obj;
  }, []);

  return [sections, currentRoute];
};

export { identifyCurrentRoute as default };
