import React, { useState, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { parseName } from 'humanparser';
import PropTypes from 'prop-types';

// Material-UI
import Button from '@material-ui/core/Button';
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';

// Config
import constants from '../config/constants';
import routes from '../config/routes';

// Hooks
import useFavouritesLoad from '../hooks/useFavouritesLoad';
import useOrdersLoad from '../hooks/useOrdersLoad';

// Reducers
import { getUser, getUserOutstanding } from '../reducers';

// Components
import Banners from './shared/Banners';
import Layout from './shared/Layout';
import Link from './shared/Link';
import ProductsList from './shared/ProductsList';
import TitleWelcome from './shared/TitleWelcome';

// Hooks
import useOrdersNext from '../hooks/useOrdersNext';

// Images
import BannerCookies from '../assets/images/banners/banner-471x942-cookies.jpg';
import BannerBakedBread from '../assets/images/banners/banner-1197x319-baked-bread.jpg';

// Styles
const useStyles = makeStyles((theme) => ({
  drop: theme.drop,
  dropHalf: theme.dropHalf,
  loadMoreButton: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    '&:hover': {
      backgroundColor: 'rgb(0, 112, 159)',
    },
  },
  titleWelcome: {
    paddingLeft: theme.spacing(1),
  },
  banner: {
    width: '100%',
  },
}));

const Favourites = ({ currentUrl }) => {
  const classes = useStyles();

  // User
  const user = useSelector(getUser);
  const contactName = useMemo(() => (user ? parseName(user.contactName) : ''), [user]);

  // Orders
  useOrdersLoad('past', 1, 0);
  const outstanding = useSelector(getUserOutstanding);

  // Products
  const limit = 12;
  const [nextLimit, setLimit] = useState(limit);
  const [nextOffset] = useState(0);
  const { favouritesLoading, favouriteProducts, favouriteProductsCount, favouriteIngredients, favouriteIngredientsCount, favouritesLimit } = useFavouritesLoad(
    'favourite',
    nextLimit,
    nextOffset,
  );
  const { ordersNext } = useOrdersNext();
  const onLoadMore = useCallback(() => {
    setLimit(nextLimit + limit);
  }, [nextLimit, limit]);

  return (
    <Layout currentUrl={currentUrl}>
      <Grid className={classes.dropHalf}>
        <Grid container justifyContent="space-between">
          <Hidden smDown>
            <Grid item xs={8}>
              <Link to={`${routes.private.products}?category=bread`}>
                <img src={BannerBakedBread} alt="View our freshly baked bread" className={classes.banner} />
              </Link>
            </Grid>
          </Hidden>
          <Grid container item sm={12} md={4} spacing={constants.gridSpacing} className={classes.titleWelcome} alignContent="center">
            <TitleWelcome name={contactName.firstName} balance={outstanding} invoiceDate={(ordersNext && ordersNext.despatchDate) || ''} />
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={constants.gridSpacing} className={classes.dropHalf}>
        <Grid item xs={12} md={9}>
          <Grid container spacing={constants.gridSpacing}>
            <ProductsList products={favouriteProducts} loading={favouritesLoading} isFavourites />
            <ProductsList products={favouriteIngredients} loading={favouritesLoading} isFavourites />
            <ProductsList loading={favouritesLoading} showEmptyList={favouriteProductsCount + favouriteIngredientsCount < 1} isFavourites />
          </Grid>
        </Grid>
        <Hidden smDown>
          <Grid item xs={3} md={3}>
            <Link to={`${routes.private.products}?subcategory=cookies%2Fbiscuits%2Fmacarons`}>
              <img src={BannerCookies} alt="View our cookie range" className={classes.banner} />
            </Link>
          </Grid>
        </Hidden>
        {nextLimit < favouritesLimit && (
          <Grid item xs={12} md={9} container direction="row" justifyContent="center" alignItems="center">
            <Button disabled={favouritesLoading} disableFocusRipple disableRipple className={classes.loadMoreButton} onClick={onLoadMore}>
              Load More
              <ExpandMoreOutlinedIcon />
            </Button>
          </Grid>
        )}
      </Grid>
      <Grid container spacing={constants.gridSpacing} className={classes.drop}>
        <Banners />
      </Grid>
    </Layout>
  );
};

Favourites.propTypes = {
  currentUrl: PropTypes.string,
};

Favourites.defaultProps = {
  currentUrl: '/',
};

export default Favourites;
