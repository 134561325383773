import { useDispatch } from 'react-redux';

// Actions
import { setCartLoading, setCartItems, setCartDate, setCartOrderNumber } from '../actions/cart';

const useCartRestoreItems = () => {
  const dispatch = useDispatch();

  // Update store to say that the cart items are loading.
  dispatch(setCartLoading());

  // Fetch the cart from localStorage. The cart will be used if the user refreshes the page.
  const cartItems = JSON.parse(localStorage.getItem('cartItems'));

  // Add the cart to Redux
  dispatch(setCartItems(cartItems));

  // Fetch the date from localStorage. The date will be used if the user refreshes the page.
  const cartDate = localStorage.getItem('cartDate');

  // Add the date to Redux
  if (cartDate) dispatch(setCartDate(cartDate));

  // Fetch the date from localStorage. The date will be used if the user refreshes the page.
  const cartOrderNumber = localStorage.getItem('cartOrderNumber');

  // Add the date to Redux
  if (cartOrderNumber) dispatch(setCartOrderNumber(cartOrderNumber));
};

export default useCartRestoreItems;
