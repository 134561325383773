import { combineReducers } from 'redux';
import dayjs from 'dayjs';

const defaultDate =
  dayjs() > dayjs().hour(15) ? dayjs().add(1, 'days').hour(15).minute(0).second(0).millisecond(0) : dayjs().hour(15).minute(0).second(0).millisecond(0);

const cartItems = (state = {}, action) => {
  switch (action.type) {
    case 'SET_CART_ITEMS':
      return {
        ...state,
        ...action.items,
      };

    case 'SET_CART_ITEM':
      return {
        ...state,
        [action.item.oid]: action.item,
      };

    case 'UPDATE_CART_ITEM': {
      const { product } = action.item;
      const { [action.item.oid]: removed, ...prevState } = state;
      const nextState = { ...prevState, [product.oid]: product };
      localStorage.setItem('cartItems', JSON.stringify(nextState)); // Update the cartItems from localStorage.
      return nextState;
    }

    case 'REMOVE_CART_ITEM': {
      const { [action.item.oid]: removed, ...nextState } = state;
      localStorage.setItem('cartItems', JSON.stringify(nextState)); // Update the cartItems from localStorage.
      return nextState;
    }

    case 'REMOVE_ALL_CART_ITEMS': {
      localStorage.removeItem('cartDate'); // Remove the cartDate from localStorage.
      localStorage.removeItem('cartItems'); // Remove the cartItems from localStorage.
      localStorage.removeItem('cartItems'); // Remove the cartItems from localStorage.
      localStorage.removeItem('cartOrderNumber'); // Remove the cartOrderNumber from localStorage.
      localStorage.removeItem('cartPaymentReference'); // Remove the cartPaymentReference from localStorage.
      return {};
    }

    default:
      return state;
  }
};

const cartLoading = (state = true, action) => {
  switch (action.type) {
    case 'SET_CART_LOADING':
      return true;
    case 'SET_CART_ERROR':
    case 'SET_CART_ITEMS':
      return false;
    default:
      return state;
  }
};

const cartDate = (state = defaultDate, action) => {
  switch (action.type) {
    case 'SET_CART_DATE': {
      const newDate = typeof action.date === 'undefined' ? defaultDate : dayjs(action.date);
      newDate.hour(15).minute(0).second(0).millisecond(0);
      return newDate;
    }
    default:
      return state;
  }
};

const cartOrderNumber = (state = null, action) => {
  switch (action.type) {
    case 'SET_CART_ORDER_NUMBER': {
      return action.cartOrderNumber;
    }
    case 'REMOVE_ALL_CART_ITEMS': {
      return null;
    }
    default:
      return state;
  }
};

const cartPaymentReference = (state = null, action) => {
  switch (action.type) {
    case 'SET_CART_PAYMENT_REFERENCE': {
      return action.paymentReference;
    }
    default:
      return state;
  }
};

export default combineReducers({
  cartDate,
  cartItems,
  cartLoading,
  cartOrderNumber,
  cartPaymentReference,
});

export const getCartDate = (state) => state.cartDate;
export const getCartItems = (state) => state.cartItems;
export const getCartLoading = (state) => state.cartLoading;
export const getCartOrderNunber = (state) => state.cartOrderNumber;
export const getCartPaymentReference = (state) => state.cartPaymentReference;
