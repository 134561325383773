// Date
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

export const localDate = (date) => {
  // Extend dayjs with the timezone plugins
  dayjs.extend(utc);
  dayjs.extend(timezone);

  return dayjs(date).tz(dayjs.tz.guess()).format('dddd, MMMM D, YYYY');
};

export const localTime = (time) => {
  // Extend dayjs with the timezone plugins
  dayjs.extend(timezone);

  // Convert the time from Sydney to users local time.
  const dateInSydney = typeof time === 'object' ? time : dayjs().tz('Australia/Sydney').hour(time);
  return dayjs(dateInSydney).tz(dayjs.tz.guess()).format('h a');
};
