import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

// Material-UI
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import FormatListBulletedOutlinedIcon from '@material-ui/icons/FormatListBulletedOutlined';
import Typography from '@material-ui/core/Typography';

// Config
import constants from '../config/constants';
import routes from '../config/routes';

// Helpers
import { isActiveLink } from '../helpers/isActiveLink';
import { ucwords, slugify } from '../helpers/string';

// Components
import EmptyList from './shared/EmptyList';
import Link from './shared/Link';

// Styles
const useStyles = makeStyles(() => ({
  link: {
    display: 'block',
    height: '100%',
    textTransform: 'capitalize',
  },
}));

const ProductsCategories = ({ currentUrl, loading, categories, length, onSelectSubcategory }) => {
  const classes = useStyles();

  // Products
  const hasCategories = categories.length > 0;

  const categoriesList = useMemo(
    () =>
      categories.map((category) => ({
        name: category,
        label: ucwords(category),
        slug: slugify(category),
        isActiveCategory: isActiveLink(currentUrl, `${routes.private.products}?subcategory=${category}`),
        onSelectCategory: () => onSelectSubcategory(category),
      })),
    [categories, currentUrl, onSelectSubcategory],
  );

  return (
    <div className={classes.root}>
      {!loading && !hasCategories ? (
        <EmptyList
          title="We where unable to find any product categories"
          description="Something has gone terribly wrong. Please refresh your browser and these should load, otherwise how can you navigate though our delicious products."
          icon={FormatListBulletedOutlinedIcon}
        />
      ) : (
        <Grid container spacing={constants.gridSpacing}>
          {categoriesList.map(({ name, slug, label, isActiveCategory, onSelectCategory }) => (
            <Grid key={`ProductsCategories-${slug}`} item xs={6} sm={6} md={3} lg={3}>
              <Link to={`${routes.private.products}?subcategory=${name}`} onClick={onSelectCategory}>
                <Typography className={classes.link} color={isActiveCategory ? 'secondary' : 'initial'}>
                  {label}
                </Typography>
              </Link>
            </Grid>
          ))}
        </Grid>
      )}
    </div>
  );
};

ProductsCategories.propTypes = {
  currentUrl: PropTypes.string,
  loading: PropTypes.bool,
  categories: PropTypes.arrayOf(PropTypes.string),
  length: PropTypes.number,
  onSelectSubcategory: PropTypes.func,
};

ProductsCategories.defaultProps = {
  currentUrl: '/',
  loading: true,
  categories: [],
  length: 12,
  onSelectSubcategory: () => {},
};

export default ProductsCategories;
