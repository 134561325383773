import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// Material-UI
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';

// Config
import constants from '../config/constants';

// Hooks
import useAction from '../hooks/useAction';

// Actions
import { setError } from '../actions/notifications';

// Reducers
import { getUser, getContact } from '../reducers';

// Components
import Layout from './shared/Layout';

const human = require('humanparser');

// Styles
const useStyles = makeStyles((theme) => ({
  drop: theme.drop,
  dropHalf: theme.dropHalf,
  margin: {
    marginTop: theme.spacing(8),
    paddingLeft: theme.spacing(1),
  },
  container: {
    width: '100%', // Fix IE 11 issue.
    maxWidth: '960px',
    margin: '12px auto 0 auto',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  link: {
    margin: theme.spacing(2, 0, 0, 0),
  },
}));

const Profile = ({ currentUrl }) => {
  const classes = useStyles();
  const user = useSelector(getUser);
  const contact = useSelector(getContact);

  const contactName = (contact && contact.name && human.parseName(contact.name)) || { firstName: '', lastName: '' };
  const showNotImplementedError = useAction(() => setError('TODO: This feature has not been implemented yet. Waiting on SSi to finalise a new API.'));

  // On form submit...
  const onSubmit = (event) => {
    event.preventDefault();
    showNotImplementedError();
  };

  return (
    <Layout currentUrl={currentUrl}>
      <Grid className={classes.container}>
        <Typography component="h3" variant="h3" className={classes.margin}>
          My Contact
        </Typography>
      </Grid>
      <form className={classes.container} onSubmit={onSubmit} noValidate>
        <Grid container spacing={constants.gridSpacing}>
          <Grid item xs={12}>
            <Typography>This will be VSE&apos;s main point of contact incase of enquires.</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField name="firstName" label="First Name" variant="outlined" fullWidth required autoFocus autoComplete="fname" value={contactName.firstName} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField name="lastName" label="Last Name" variant="outlined" fullWidth required autoComplete="lname" value={contactName.lastName} />
          </Grid>
          <Grid item xs={12}>
            <TextField name="contactPhone" label="Telephone" variant="outlined" fullWidth required autoComplete="tel" value={contact.phone} />
          </Grid>
          <Grid item xs={12}>
            <TextField name="email" label="Email Address" variant="outlined" fullWidth required autoComplete="email" value={contact.email} />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={<Checkbox id="allowExtraEmails" name="allowExtraEmails" value="yes" color="primary" />}
              label="I want to receive inspiration, marketing promotions and updates via email."
            />
          </Grid>
        </Grid>
        <Grid container spacing={constants.gridSpacing} className={classes.drop}>
          <Grid item xs={12}>
            <Typography variant="h5" component="h3">
              My Business Profile
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField name="companyName" label="Company Name" variant="outlined" fullWidth required autoComplete="org" value={user.name} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField name="companyTelephone" label="Company Telephone" variant="outlined" fullWidth required autoComplete="tel" value={user.contactPhone} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField name="companyEmail" label="Company Email" variant="outlined" fullWidth required autoComplete="email" value={user.email} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField name="gstNo" label="ABN" variant="outlined" fullWidth required value={user.gstNo} />
          </Grid>
        </Grid>
        <Grid container spacing={constants.gridSpacing} className={classes.drop}>
          <Grid item xs={12}>
            <Typography variant="h5" component="h3">
              Delivery
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>Please note these details cannot be edited here. To change these details please contact your VSE representative here.</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="address"
              label="Delivery Address"
              variant="outlined"
              fullWidth
              required
              autoComplete="shipping street-address"
              value={user.deliveryAddress1}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField name="instructions" label="Delivery Instructions" variant="outlined" fullWidth />
          </Grid>
        </Grid>
        <Grid container spacing={constants.gridSpacing} className={classes.drop}>
          <Grid item xs={12}>
            <Typography variant="h5" component="h3">
              Change Password
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField id="password" name="password" label="Current Password" type="password" variant="outlined" fullWidth autoComplete="current-password" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField id="password_confirm" name="password_confirm" label="New Password" type="password" variant="outlined" fullWidth />
          </Grid>
          <Grid container item xs={12} justifyContent="flex-end">
            <Button type="submit" variant="contained" color="primary" className={classes.submit} position="right">
              Update
            </Button>
          </Grid>
        </Grid>
      </form>
    </Layout>
  );
};

Profile.propTypes = {
  currentUrl: PropTypes.string,
};

Profile.defaultProps = {
  currentUrl: '/',
};

export default Profile;
