import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

// Material-UI
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import useMediaQuery from '@material-ui/core/useMediaQuery';

// Config
import constants from '../../config/constants';

// Components
import NavigationDesktop from './NavigationDesktop';
import NavigationMobile from './NavigationMobile';
import NavigationSide from './NavigationSide';

const useStyles = makeStyles((theme) => ({
  list: {
    width: 280,
  },
}));

const Header = ({ currentUrl }) => {
  const classes = useStyles();
  const theme = useTheme();

  // Responsive
  const isMobile = !useMediaQuery(theme.breakpoints.up(constants.isMobileBreakpoint));

  // Menu
  const drawPosition = useMediaQuery(theme.breakpoints.up(constants.isMobileBreakpoint)) ? 'right' : 'left';
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const onOpenDrawer = useCallback(
    (event) => {
      if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) return;
      setIsMenuOpen(true);
    },
    [setIsMenuOpen],
  );

  const onCloseDrawer = useCallback(
    (event) => {
      if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) return;
      setIsMenuOpen(false);
    },
    [setIsMenuOpen],
  );

  return (
    <>
      {isMobile && <NavigationMobile currentUrl={currentUrl} onOpenDrawer={onOpenDrawer} />}
      {!isMobile && <NavigationDesktop currentUrl={currentUrl} onOpenDrawer={onOpenDrawer} />}
      <Drawer anchor={drawPosition} open={isMenuOpen} onClose={onCloseDrawer}>
        <div className={classes.list} role="presentation" onClick={onCloseDrawer} onKeyDown={onCloseDrawer}>
          <NavigationSide currentUrl={currentUrl} />
        </div>
      </Drawer>
    </>
  );
};

Header.propTypes = {
  currentUrl: PropTypes.string,
};

Header.defaultProps = {
  currentUrl: '/',
};
export default Header;
