import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getUser, getUserLoading } from '../reducers';

// Actions
import { loadUser } from '../actions/users';

// Helpers
import { slugify } from '../helpers/string';

const useUserPayOnAccount = () => {
  const debtorId = localStorage.getItem('debtor_id'); // Retrieve the debtor_id from localStorage. The debtor_id is used to fetch related records from the API.

  const dispatch = useDispatch();
  const userLoading = useSelector(getUserLoading);
  const user = useSelector(getUser);
  const [payOnAccount, setPayOnAccount] = useState(false);

  useEffect(() => {
    dispatch(loadUser(debtorId));
  }, [dispatch, debtorId]);

  useEffect(() => {
    if (userLoading) setPayOnAccount(false);
    setPayOnAccount(user && user.statementFrequency && slugify(user.statementFrequency) !== 'cod');
  }, [userLoading, user]);

  return { payOnAccount };
};

export default useUserPayOnAccount;
