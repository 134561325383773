import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

// Material-UI
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';

// Actions
import { updateCartItem, removeCartItem } from '../../actions/cart';

// Hooks
import useProductOptions from '../../hooks/useProductOptions';

// Components
import ProductOptions from './ProductOptions';

// Styles
const useStyles = makeStyles((theme) => ({}));

const CartItem = ({ loading, item, onInputUpdate, children }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const hasItem = !isEmpty(item);
  const { productOptionsMatrix } = useProductOptions(item);
  const quantity = (hasItem && parseInt(item.quantity, 10)) || 1;

  const onUpdateCartItem = useCallback(
    (productId, inputs) => {
      const hasError = inputs.products[0].maximumOrderQty !== 0 && inputs.products[0].quantity > inputs.products[0].maximumOrderQty;

      onInputUpdate(productId, inputs.products[0].oid, inputs.products[0].quantity);

      if (!hasError) {
        dispatch(
          updateCartItem({
            oid: productId,
            // Add the product as well ad the productOptionsMatrix, as this is required by the ProductOptions component.
            product: { ...inputs.products[0], productOptionsMatrix },
          }),
        );
      }
    },
    [dispatch, productOptionsMatrix, onInputUpdate],
  );

  const onDeleteCartItem = useCallback(() => {
    onInputUpdate(item.oid);

    dispatch(
      removeCartItem({
        oid: item.oid,
      }),
    );
  }, [dispatch, item.oid, onInputUpdate]);

  if (children) return <ListItem key={`order-item-${item ? item.oid : 0}`}>{children}</ListItem>;

  return (
    <ListItem key={`order-item-${item ? item.oid : 0}`}>
      <ProductOptions
        key={`cartItems-${item.oid}`}
        index={item.oid}
        productId={item.oid}
        itemCode={item.itemCode}
        webProductName={item.webProductName}
        category={item.category}
        subCategory={item.subCategory}
        sellingPrice={item.sellingPrice}
        quantity={quantity}
        maximumOrderQty={item.maximumOrderQty}
        exemptGst={item.exemptGst}
        size={item.size}
        packaging={item.packaging}
        sliced={item.sliced}
        options={productOptionsMatrix}
        showProductName
        showDeleteRow
        showProductCost
        className={classes.paddingLeft}
        onProductChange={onUpdateCartItem}
        onProductDelete={onDeleteCartItem}
      />
    </ListItem>
  );
};

CartItem.propTypes = {
  loading: PropTypes.bool,
  item: PropTypes.shape({
    oid: PropTypes.string,
    itemCode: PropTypes.string,
    webProductName: PropTypes.string,
    category: PropTypes.string,
    subCategory: PropTypes.string,
    quantity: PropTypes.number,
    maximumOrderQty: PropTypes.number,
    exemptGst: PropTypes.bool,
    sellingPrice: PropTypes.number,
    size: PropTypes.string,
    packaging: PropTypes.string,
    sliced: PropTypes.string,
    favourite: PropTypes.bool,
    options: PropTypes.shape({}),
    product: PropTypes.shape({
      oid: PropTypes.string,
    }),
  }),
  onInputUpdate: PropTypes.func,
  children: PropTypes.node,
};

CartItem.defaultProps = {
  loading: false,
  item: {
    oid: '',
    itemCode: '',
    webProductName: '',
    category: '',
    subCategory: '',
    quantity: 0,
    maximumOrderQty: 255,
    exemptGst: false,
    sellingPrice: 0,
    size: '',
    packaging: '',
    sliced: '',
    favourite: false,
    options: {},
    product: {
      oid: '',
    },
  },
  onInputUpdate: () => {},
  children: null,
};

export default CartItem;
