// Define the constants for the application

const blacklistProductCodes = [
  'cut14',
  'del',
  'hodbj',
  'hodi',
  'hodjbm',
  'homb',
  'homc',
  'hoslac',
  'hoslban',
  'hoslcar',
  'hoslcb',
  'hoslcmud',
  'hoslmc',
  'hosloa',
  'hoslsd',
  'hospccm',
  'hospcwm',
  'surao',
  'surcc',
  'wc',
  'wcts',
];

export default blacklistProductCodes;
