import React from 'react';
import ReactPlayer from 'react-player';
import ReactHtmlParser from 'react-html-parser';
import PropTypes from 'prop-types';

// Material-UI
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Grid from '@material-ui/core/Grid';
// import GridList from '@material-ui/core/GridList';
// import GridListTile from '@material-ui/core/GridListTile';
import Typography from '@material-ui/core/Typography';

// Config
import constants from '../config/constants';

// Components
import Layout from './shared/Layout';

// Styles
const useStyles = makeStyles((theme) => ({
  drop: theme.drop,
  dropHalf: theme.dropHalf,

  gridList: {
    width: '100%',
    marginBottom: '24px',
  },
  container: {
    maxWidth: '960px',
    margin: 'auto',
  },
  border: {
    borderColor: theme.palette.background.dark,
  },
  margin: {
    marginTop: theme.spacing(2),
  },
  header: {
    textTransform: 'uppercase',
    fontSize: '0.875rem',
    fontWeight: '600',
    letterSpacing: '0.4px',
  },
  videoTitle: {
    fontSize: '1rem',
    marginTop: theme.spacing(1),
    letterSpacing: '0.4px',
    lineHeight: '1.25',
  },
  answer: {
    fontSize: '0.9rem',
    lineHeight: '1.75',
    letterSpacing: '0.4px',
    marginTop: theme.spacing(1),

    '& a': {
      color: theme.palette.primary.main,
      textDecoration: 'none',
      '&:hover': {
        color: theme.palette.primary.dark,
      },
    },
  },
  title: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(2),
    letterSpacing: '0.4px',
    textTransform: 'uppercase',
    fontWeight: '600',
  },
  reactPlayerWrapper: {
    height: '0px',
    paddingTop: '56.25%',
    position: 'relative',
    width: '100%',
  },
  reactPlayer: {
    height: '100%',
    left: 0,
    position: 'absolute',
    top: 0,
    width: '100%',
  },
}));

const sections = [
  {
    id: 1,
    title: 'Customer Care',
    description: '',
    questions: [
      {
        id: 1,
        title: 'Do you have a sales representative?',
        answer:
          'Yes we do! If you are interested in speaking or meeting with our Sales Rep please call or <a href="mailto:sales@vseinternational.com.au">email</a> the VSE office to make an appointment.',
        video: '',
      },
      {
        id: 2,
        title: 'Can I get updated on what is happening at VSE?',
        answer:
          'If you would like to keep informed on specials, public holiday notices, launches, and other exciting things you are welcome to LIKE us on <a href="https://www.facebook.com/vseinternational/" target="_blank">Facebook</a> or <a href="https://www.instagram.com/p/BvP90T_Fty0/" target="_blank">Instagram</a> or go on our emailing list!',
        video: '',
      },
      {
        id: 3,
        title: 'What are the contact details for VSE International?',
        answer:
          'Monday through to Friday, 8.30am - 3:00pm<br>Phone: <a href="tel:0061293174377">02 9317 4377</a><br>Email: <a href="mailto:sales@vseinternational.com.au">sales@vseinternational.com.au</a><br>Location: <a href="https://maps.app.goo.gl/bJvsYZn3zZNkWAgS8">6-8 O&apos;Connell St, Newtown NSW 2042, Australia</a>',
        video: '',
      },
      {
        id: 4,
        title: 'How do I contact you over the weekend?',
        answer:
          'Unfortunately our phone lines are turned off over the weekend & there is no voicemail option. The best way to reach us is via <a href="mailto:sales@vseinternational.com.au">email</a> and the sales team will get back to you on Sunday before 12pm. If the email is sent after 12pm on Sunday, it will be attended to on the next business day.',
        video: '',
      },
    ],
  },
  {
    id: 2,
    title: 'Product Information',
    description: '',
    questions: [
      {
        id: 1,
        title: 'Not all my products look the same?',
        answer:
          'Unless specified otherwise, all VSE International products are made in our warehouse. This means there may be cooking variations & that these are completely normal & not something we can prevent. However, if you are unsure please email the image to our VSE sales <a href="mailto:sales@vseinternational.com.au">email</a>.',
        video: '',
      },
      {
        id: 2,
        title: 'Where can I get the allergen/ingredient/kilojoule information?',
        answer:
          '<a href="mailto:sales@vseinternational.com.au">Email VSE sales</a> a list of the products you are wanting the product specifications for and our sales team will send them to you! As we have over 200 products we are unable to send all of them, so we recommend picking out your favourites & starting there.',
        video: '',
      },
      {
        id: 3,
        title: 'Do we supply cutlery, placing boards, or catering utensils?',
        answer: 'Unfortunately no. We identify as a wholesale patisserie and keep packaging simple & efficient.',
        video: '',
      },
    ],
  },
  {
    id: 3,
    title: 'Order Information',
    description: '',
    questions: [
      {
        id: 1,
        title: "Can I place an order if I haven't set up an account?",
        answer: 'Yes you can! The order will be required to be pre-paid and a photo of the front of the premises will be needed',
        video: '',
      },
      {
        id: 2,
        title: 'Is there another way to make an order?',
        answer:
          '<a href="mailto:sales@vseinternational.com.au">Email</a> is the best way to make an order. Clearly identifying the product description or the correct product codes. We do recommend to include the best contact number in case we need to contact you.',
        video: '',
      },
      {
        id: 3,
        title: 'What if I need to amend/cancel my order?',
        answer:
          "Any amendments or cancellations MUST be made by 3pm for the next day's delivery or by 12pm Sunday for Monday's delivery. Any amendments or cancellations will not be honored if requested after this time & we will be unable to accept return of the product or raise any credits.",
        video: '',
      },
      {
        id: 4,
        title: 'What do I do if something is wrong or I am not happy with my order?',
        answer:
          'If you experience any discrepancies with your order, VSE requires to be notified either on the day or the very next day (48 hours notice) via <a href="tel:0061293174377">phone call</a> or <a href="mailto:sales@vseinternational.com.au">email</a>. The product is to not be used or disposed of unless advised otherwise. The VSE team will work with you to resolve the issue as smoothly as possible.',
        video: '',
      },
    ],
  },
  {
    id: 4,
    title: 'Payment Information',
    description: '',
    questions: [
      {
        id: 1,
        title: 'What is the VSE credit policy?',
        answer:
          '<ul><li>Any discrepancies must be notified within 48 hours (two days) either via <a href="tel:0061293174377">phone</a> or <a href="mailto:sales@vseinternational.com.au">email</a>, if this falls on a weekend you have until Monday/next business day to alert us.</li><li>If the manifest has been signed by a staff member/manager this indicates to us that they have checked the order and everything has been checked off and accepted/received as having all been received in full with no issues.</li><li>Notes can be made on the driver\'s manifest of any missing items or reasons why items have been returned. Credit will be raised.</li><li>Credits take 3-5 business days to process.</li><li>The product must be returned for credit to be raised unless the VSE team advises otherwise.</li><li>If we get permission to leave the product unattended the customer forfeits their credit rights to any product/packaging quality issues or for any shortages.</li></ul>',
        video: '',
      },
      {
        id: 2,
        title: 'What does pre-paid refer to?',
        answer:
          'Pre-paid means to make payment before your order is dispatched. Pre-payment can be made by the following:<ul><li>Credit card: Visa/Master/AMEX cards incur a surcharge</li><li>Bank Transfer or BPay: The amount needs to be transferred & proof of payment emailed to <a href="mailto:sales@vseinternational.com.au">sales@vseinternational.com.au</a> prior to the close off time. This is because it takes 2-3 days before the amount is shown on our bank statements. If no proof of payment or no payment is made by the close off time, the VSE office team will be forced to cancel the order.</li></ul>',
        video: '',
      },
      {
        id: 3,
        title: 'Do the prices include GST and are they in Australian Dollars?',
        answer: 'Our price list excludes GST. However, some of our bread range do not incur GST. All prices shown are in AUD only.',
        video: '',
      },
      {
        id: 4,
        title: 'Do you store my credit card?',
        answer:
          'On our online ordering system, we do not store credit cards on our file. The payment gateway system will be using National Australian Bank.  However if a credit card authorization form is filled out we are able to store the credit card on our company software system. ',
        video: '',
      },
    ],
  },
  {
    id: 5,
    title: 'Delivery Information',
    description: '',
    questions: [
      {
        id: 1,
        title: 'What is the minimum for delivery?',
        answer:
          'Our minimum order amount is $40 + GST and $150 for Saturday deliveries to Wollongong, Central Coast, Gosford, Hunter region & Canberra; any orders under this minimum will incur a delivery charge.',
        video: '',
      },
      {
        id: 2,
        title: 'Can I organise same day pick up/delivery?',
        answer: 'Yes you can! There is a $20 additional order fee, on top of the order & product availability varies.',
        video: '',
      },
      {
        id: 3,
        title: 'What is the delivery time frame in my area?',
        answer:
          'Our policy for delivery time frames is to provide our drivers with a 3 hour time window. This can be either, anytime between 6am - 9am or 9am - 12pm. Some areas are earlier or later, depending on the route the drivers take & the volume on the day. VSE recommends that you contact us or enquire about the delivery times when placing the order to obtain accurate delivery time frame available.',
        video: '',
      },
      {
        id: 4,
        title: 'Do you deliver to residential or accept private orders?',
        answer:
          "Yes we do! If you would like a home delivery, we do require the order to be paid prior to dispatching the order and for a photo of the property to be sent to our sales team, to ensure our driver can recognize the correct house for delivery. You will also be required to specify how you'd like the delivery made i.e. left on the porch.",
        video: '',
      },
      {
        id: 5,
        title: 'I have a delivery crate left behind from my delivery, what do I do?',
        answer:
          '<a href="mailto:sales@vseinternational.com.au">Contact the VSE sales team</a> and we will be sure to organise our driver to come by and collect the crate for you!',
        video: '',
      },
    ],
  },
];

const Faq = ({ currentUrl }) => {
  const classes = useStyles();

  return (
    <Layout currentUrl={currentUrl}>
      {/* <Grid container spacing={constants.gridSpacing} justifyContent="center" className={classes.dropHalf}>
        <GridList cellHeight="auto" spacing={constants.gridSpacing * 14} className={classes.gridList} cols={4}>
          <GridListTile key="Faq-Video-1" cols={1}>
            <div className={classes.reactPlayerWrapper}>
              <ReactPlayer url="https://www.youtube.com/watch?v=6jiNS_4CEug" controls light playing className={classes.reactPlayer} width="100" height="100" />
            </div>
            <Typography component="h6" variant="h6" align="left" className={classes.videoTitle}>
              How To Master The Art Of food
            </Typography>
          </GridListTile>
          <GridListTile key="Faq-Video-2" cols={1}>
            <div className={classes.reactPlayerWrapper}>
              <ReactPlayer url="https://vimeo.com/276043363" controls light playing className={classes.reactPlayer} width="100" height="100" />
            </div>
            <Typography component="h6" variant="h6" align="left" className={classes.videoTitle}>
              5 Things Everyone Gets Wrong About food
            </Typography>
          </GridListTile>
          <GridListTile key="Faq-Video-3" cols={1}>
            <div className={classes.reactPlayerWrapper}>
              <ReactPlayer url="https://www.youtube.com/watch?v=6jiNS_4CEug" controls light playing className={classes.reactPlayer} width="100" height="100" />
            </div>
            <Typography component="h6" variant="h6" align="left" className={classes.videoTitle}>
              Is Tech Making food Better or Worse?
            </Typography>
          </GridListTile>
          <GridListTile key="Faq-Video-4" cols={1}>
            <div className={classes.reactPlayerWrapper}>
              <ReactPlayer url="https://vimeo.com/276043363" controls light playing className={classes.reactPlayer} width="100" height="100" />
            </div>
            <Typography component="h6" variant="h6" align="left" className={classes.videoTitle}>
              How To Start food
            </Typography>
          </GridListTile>
        </GridList>
      </Grid> */}

      <Grid container spacing={constants.gridSpacing} justifyContent="flex-end" className={classes.container}>
        <Grid item xs={12}>
          <Typography variant="h3" align="center" className={classes.drop}>
            Frequently Asked Questions
          </Typography>
        </Grid>

        {sections.map((section) => (
          <Grid key={`Faq-${section.id}`} item xs={12} className={classes.container}>
            <Typography className={classes.title} component="h2" variant="h6">
              {ReactHtmlParser(section.title)}
            </Typography>

            {section.questions.map((question) => (
              <Accordion key={`Faq-${section.id}-Question-${question.id}`} className={classes.border}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel-${section.id}-${question.id}-content`}
                  id={`panel-${section.id}-${question.id}-header`}
                >
                  <Typography className={classes.header}>{ReactHtmlParser(question.title)}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container direction="row">
                    <Grid container item xs={12} sm={question.video ? 5 : 12}>
                      <Typography className={classes.answer} component="div">
                        {ReactHtmlParser(question.answer)}
                      </Typography>
                    </Grid>
                    {question.video && (
                      <Grid container item xs={12} sm={7}>
                        <div className={classes.reactPlayerWrapper}>
                          <ReactPlayer url={question.video} controls light playing className={classes.reactPlayer} width="100" height="100" />
                        </div>
                      </Grid>
                    )}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            ))}
          </Grid>
        ))}
      </Grid>
    </Layout>
  );
};

Faq.propTypes = {
  currentUrl: PropTypes.string,
};

Faq.defaultProps = {
  currentUrl: '/',
};

export default Faq;
