import { combineReducers } from 'redux';
import merge from 'lodash/merge';

const favourites = (state = [], action) => {
  switch (action.type) {
    case 'SET_FAVOURITES_RAW':
      localStorage.setItem('favourites', JSON.stringify(action.favourites)); // Store the favourites into localStorage so we don't fetch the favourites on every page load.
      return state;

    case 'SET_FAVOURITES':
      return { ...merge(state, action.favourites) };

    case 'RESET_FAVOURITES':
      return [];

    case 'SET_FAVOURITE':
      return { ...state, ...action.favourite };

    default:
      return state;
  }
};

const favouritesLoading = (state = false, action) => {
  switch (action.type) {
    case 'SET_FAVOURITES_LOADING':
      return true;
    case 'SET_FAVOURITES':
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  favourites,
  favouritesLoading,
});

export const getFavourites = (state) => state.favourites;
export const getFavouritesLoading = (state) => state.favouritesLoading;
