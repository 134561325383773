import axios from 'axios';

// Date
import dayjs from 'dayjs';

// Config
import constants from '../config/constants';

// Mock API Responses
// import orderBelowMin from './mock/orderBelowMin.json';

// Helpers
import { translateApiError } from '../helpers/translateApiError';

const docketFields = {
  confirmed: '',
  date: '',
  despatchDate: '',
  docketNo: '',
  gross: '',
  gst: '',
  noLines: '',
  orderNo: '',
  totalNett: '',
  type: '',
};

const docketLineFields = {
  rBKproduct: {
    itemCode: '',
    webProductName: '',
    sellingPrice: '',
    unitsPerCtn: '',
    '#WebCategory': '',
    '#WebSubCategory': '',
    '#WebFlavour': '',
    '#WebSize': '',
    '#WebWrapped': '',
    '#WebSliced': '',
  },
  quantity: '',
  unitPrice: '',
  extended: '',
};

const getFilterSortByType = (type) => {
  switch (type) {
    case 'past':
      return {
        filter: {
          despatchDate: { '<': 'TODAY' },
        },
        sort: {
          despatchDate: 'D',
        },
      };

    case 'scheduled':
      return {
        filter: {
          despatchDate: { '>=': 'TODAY' },
        },
        sort: {
          despatchDate: 'A',
        },
      };

    default:
      return {
        sort: {},
        filter: {},
      };
  }
};

export const fetchOrders = (token, type) => {
  if (!token) return Promise.reject(new Error('Fetching Orders Failed: Debtor token is missing'));

  const { sort, filter } = getFilterSortByType(type);

  const data = {
    command: 'GetClassObjects',
    parameters: {
      class: 'BKdocket',
      token,
      filter,
      sortby: sort,
      properties: {
        ...docketFields,
        rBKdocketLine: { ...docketLineFields },
      },
    },
  };

  // console.log('API REQUEST : fetchOrders = ', JSON.stringify(data));

  return axios
    .post(constants.apiUrl, data)
    .then((response) => translateApiError(response, data))
    .then((response) => response.data);
};

export const fetchOrder = (token, oid) => {
  if (!token) return Promise.reject(new Error('Fetching Order Failed: Debtor token is missing'));
  if (!oid) return Promise.reject(new Error('Fetching Order Failed: Order ID is missing'));

  const data = {
    command: 'GetClassObject',
    parameters: {
      token,
      oid,
      properties: {
        ...docketFields,
        rBKdocketLine: { ...docketLineFields },
      },
    },
  };

  // console.log(`API REQUEST : fetchOrder :: token = ${token} :: oid = ${oid} :: data = `, JSON.stringify(data));

  return axios
    .post(constants.apiUrl, data)
    .then((response) => translateApiError(response, data))
    .then((response) => response.data);
};

export const fetchOrderByOrderNumber = (token, orderNo) => {
  if (!token) return Promise.reject(new Error('Fetching Order Failed: Debtor token is missing'));
  if (!orderNo) return Promise.reject(new Error('Fetching Order Failed: Order number is missing'));

  const data = {
    command: 'GetClassObjects',
    parameters: {
      token,
      class: 'BKdocket',
      filter: {
        orderNo,
      },
      sortby: {
        orderNo: 'A',
      },
      properties: {
        ...docketFields,
        rBKdocketLine: { ...docketLineFields },
      },
    },
  };

  // console.log('API REQUEST : fetchOrderByOrderNumber = ', JSON.stringify(data));

  return axios
    .post(constants.apiUrl, data)
    .then((response) => translateApiError(response, data))
    .then((response) => ({ data: response?.data?.data?.objects[0] }));
};

export const saveOrder = (token, debtorId, orderNo, date, items, reference) => {
  if (!token || !debtorId) return Promise.reject(new Error('Saving Order Failed: Debtor token is missing'));
  if (!orderNo) return Promise.reject(new Error('Saving Order Failed: Order Number is missing'));
  if (!date) return Promise.reject(new Error('Saving Order Failed: Date is missing'));
  if (!items) return Promise.reject(new Error('Saving Order Failed: Cart items are missing'));

  // eslint-disable-next-line no-unused-vars
  const docketLines = Object.entries(items).reduce(
    (a, [k, item]) => [...a, { rBKproduct: item.oid, quantity: item.quantity, unitPrice: item.sellingPrice }],
    [],
  );

  const data = {
    command: 'UpdateObject',
    parameters: {
      class: 'BKdocket',
      token,
      rBKdebtor: debtorId,
      date: dayjs(date).format('YYYY-MM-DD'),
      orderNo,
      rBKdocketLine: docketLines,
    },
  };

  // console.log('API REQUEST : saveOrder = ', JSON.stringify(data));

  return axios
    .post(constants.apiUrl, data)
    .then((response) => translateApiError(response, items))
    .then((response) => response.data);
};

export const editOrderLineItem = (token, lineItem) => {
  if (!token) return Promise.reject(new Error('Edit Order Prodct Failed: Debtor token is missing'));
  if (!lineItem || !lineItem.oid) return Promise.reject(new Error('Edit Order Prodct Failed: Line Item ID is missing'));

  const { orderNo, ...parameters } = lineItem;

  const data = {
    command: 'UpdateObject',
    parameters: {
      class: 'BKdocketLine',
      token,
      ...parameters,
    },
  };

  // console.log('API REQUEST : editOrderLineItem = ', JSON.stringify(data));

  return axios
    .post(constants.apiUrl, data)
    .then((response) => translateApiError(response, data))
    .then((response) => response.data);
};

export const deleteOrderLineItem = (token, lineItem, callback) => {
  if (!token) return Promise.reject(new Error('Deleting Order Items Failed: Debtor token is missing'));
  if (!lineItem) return Promise.reject(new Error('Deleting Order Items Failed: Cart items are missing'));

  const data = {
    command: 'DeleteObject',
    parameters: {
      class: 'BKdocketLine',
      token,
      oid: lineItem.oid,
      edition: lineItem.edition + 1,
    },
  };

  // console.log('API REQUEST : deleteOrderLineItem = ', JSON.stringify(data));

  return axios
    .post(constants.apiUrl, data)
    .then((response) => translateApiError(response, data))
    .then((response) => response.data)
    .then(() => {
      if (callback && typeof callback === 'function') callback();
    });
};

export const deleteOrder = (token, order) => {
  if (!token) return Promise.reject(new Error('Delete Order Failed: Debtor token is missing'));
  if (!order || !order.oid) return Promise.reject(new Error('Delete Order Failed: Order ID is missing'));

  const deleteLineItems = order.rBKdocketLine.map(
    (lineItem) =>
      new Promise((resolve) => {
        deleteOrderLineItem(token, lineItem, resolve);
      }),
  );

  return Promise.all(deleteLineItems).then(() => {
    const data = {
      command: 'DeleteObject',
      parameters: {
        class: 'BKdocket',
        token,
        oid: order.oid,
        edition: order.edition + 1,
      },
    };

    // console.log('API REQUEST : deleteOrder = ', JSON.stringify(data));

    return axios
      .post(constants.apiUrl, data)
      .then((response) => translateApiError(response, data))
      .then((response) => response.data);
  });
};
