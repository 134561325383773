import isEmpty from 'lodash/isEmpty';

export const getCartHasCategory = (search, cartItems) => {
  const searchString = search.trim().toLowerCase();
  const hasProduct = Object.entries(cartItems).filter((item) => item[1].category.trim().toLowerCase().includes(searchString));

  return !isEmpty(hasProduct);
};

export { getCartHasCategory as default };
