import React from 'react';
import PropTypes from 'prop-types';
import { SnackbarProvider } from 'notistack';

// Material-UI
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';

// Hooks
import useNotifications from '../../hooks/useNotifications';

const useStyles = makeStyles((theme) => ({
  close: {
    borderRadius: 0,
    height: '100%',
    left: 0,
    position: 'absolute',
    top: 0,
    width: '100%',
  },
  closeIcon: {
    color: theme.palette.common.white,
    display: 'none',
    position: 'absolute',
    right: '5px',
    top: '5px',
  },
}));

const Body = ({ children }) => {
  // Display the success and error messages.
  useNotifications();

  return children;
};

const Notifications = ({ children }) => {
  const classes = useStyles();
  const notistackRef = React.createRef();

  const onClickDismiss = (key) => () => {
    notistackRef.current.closeSnackbar(key);
  };

  return (
    <SnackbarProvider
      ref={notistackRef}
      maxSnack={3}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      action={(key) => (
        <Button className={classes.close} onClick={onClickDismiss(key)}>
          <CancelOutlinedIcon className={classes.closeIcon} />
        </Button>
      )}
    >
      <Body>{children}</Body>
    </SnackbarProvider>
  );
};

Notifications.propTypes = {
  children: PropTypes.node,
};

Notifications.defaultProps = {
  children: '',
};

export default Notifications;
