import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

// Material-UI
import { makeStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Grid from '@material-ui/core/Grid';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';

// Config
import constants from '../config/constants';
import routes from '../config/routes';

// Helpers
import { getProductPrice } from '../helpers/getProductPrice';
import { sizeWithUnitsPerCarton } from '../helpers/getProductsUnits';

// Hooks
import useProductLoad from '../hooks/useProductLoad';
import useProductOptions from '../hooks/useProductOptions';

// Components
import Layout from './shared/Layout';
import Link from './shared/Link';
import ProductDeliveryWarning from './shared/ProductDeliveryWarning';
import ProductDetails from './shared/ProductDetails';
import ProductNutrition from './shared/ProductNutrition';
import ProductOptions from './shared/ProductOptions';
import ProductsList from './shared/ProductsList';

// Styles
const useStyles = makeStyles((theme) => ({
  drop: theme.drop,
  dropHalf: theme.dropHalf,
  tabs: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  tab: {
    margin: '0 auto',
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(0, 3),
    },
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  container: {
    margin: '24px auto',
  },
  link: {
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}));

const Product = ({ currentUrl, productId }) => {
  const classes = useStyles();
  const history = useHistory();

  const { productLoading, product } = useProductLoad(productId);
  const { productOptionsMatrix } = useProductOptions(product);
  const [tabSelected, setTabSelected] = useState('details');

  const handleChange = (event, newValue) => {
    setTabSelected(newValue);
  };

  // Manipulate the product to be in a format that works in the ProductsList component.
  const products = product?.webProductName ? { [product.webProductName]: [product] } : {};

  // When the product options have been changed, force the page to redirect to show the new product information.
  const handleProductChange = (formProductId, formValues) => {
    const selectedProduct = (formValues.products && formValues.products[0]) || {};

    if (selectedProduct.oid && selectedProduct.oid !== productId) {
      history.push(`${routes.private.product}/${selectedProduct.oid}`);
    }
  };

  const sellingPrice = getProductPrice(product);

  return (
    <Layout currentUrl={currentUrl}>
      <Grid container spacing={constants.gridSpacing} className={classes.dropHalf}>
        <Grid item xs={12}>
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
            <Link color="inherit" className={classes.link} onClick={() => history.goBack()}>
              Products
            </Link>
            <Typography color="textPrimary">{product.webProductName}</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid container spacing={constants.gridSpacing}>
            <ProductsList columns={12} loadingPlaceholderCount={1} products={products} loading={productLoading} showLinks={false} />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <ProductDeliveryWarning productName={product.webProductName} category={product.WebSubCategory} />

          {!productLoading && (
            <ProductOptions
              productId={productId}
              itemCode={product.itemCode}
              webProductName={product.webProductName}
              category={product.category}
              subCategory={product.WebSubCategory}
              sellingPrice={sellingPrice}
              quantity={1}
              maximumOrderQty={product.maximumOrderQty}
              exemptGst={product.exemptGst}
              size={sizeWithUnitsPerCarton(product.size, product.unitsPerCtn)}
              packaging={product.packaging}
              sliced={product.sliced}
              options={productOptionsMatrix}
              showActions
              onProductChange={handleProductChange}
            />
          )}
        </Grid>

        <Grid item xs={12}>
          <Tabs
            value={tabSelected}
            onChange={handleChange}
            orientation="horizontal"
            indicatorColor="primary"
            textColor="primary"
            centered
            className={`${classes.tabs} ${classes.drop}`}
          >
            <Tab className={classes.tab} value="details" label="Product Details" />
            <Tab className={classes.tab} value="nutrition" label="Nutrition Information" />
          </Tabs>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.drop}>
        {tabSelected === 'details' ? (
          <ProductDetails loading={productLoading} product={product} />
        ) : (
          <ProductNutrition loading={productLoading} product={product} />
        )}
      </Grid>
    </Layout>
  );
};

Product.propTypes = {
  currentUrl: PropTypes.string,
  productId: PropTypes.string,
};

Product.defaultProps = {
  currentUrl: '/',
  productId: '',
};

export default Product;
