import React from 'react';
import PropTypes from 'prop-types';

// Material-UI
import { makeStyles } from '@material-ui/core/styles';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';

// Config
import constants from '../../config/constants';

// Hooks
import useForm from '../../hooks/useForm';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    margin: theme.spacing(3, 0),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    padding: theme.spacing(0, 4),
  },
}));

const RegisterForm = ({ onSubmit }) => {
  const classes = useStyles();

  const { inputs, handleInputChange, handleSubmit } = useForm(
    { firstName: '', lastName: '', telephone: '', email: '', address: '', subscribe: 'yes' },
    onSubmit,
  );

  return (
    <ValidatorForm className={classes.form} onSubmit={handleSubmit}>
      <Grid container spacing={constants.gridSpacing}>
        <Grid item xs={12} sm={6}>
          <TextValidator
            id="companyName"
            name="companyName"
            value={inputs.companyName}
            label="Company Name"
            variant="outlined"
            fullWidth
            autoFocus
            autoComplete="fname"
            validators={['required', 'isString']}
            errorMessages={['Please enter a company name', 'Please enter a valid company name']}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextValidator
            id="name"
            name="name"
            value={inputs.name}
            label="Contact Name"
            variant="outlined"
            fullWidth
            autoFocus
            autoComplete="fname"
            validators={['required', 'isString']}
            errorMessages={['Please enter a first name', 'Please enter a valid first name']}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextValidator
            id="email"
            name="email"
            value={inputs.email}
            label="Email Address"
            variant="outlined"
            fullWidth
            autoComplete="email"
            validators={['required', 'isEmail']}
            errorMessages={['Please enter an email address', 'Please enter a valid email address']}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextValidator
            id="telephone"
            name="telephone"
            value={inputs.telephone}
            label="Telephone"
            variant="outlined"
            fullWidth
            autoComplete="tel"
            validators={['required', 'isNumber']}
            errorMessages={['Please enter a telephone number', 'Please enter a valid telephone number']}
            onChange={handleInputChange}
          />
        </Grid>

        <Grid item xs={12}>
          <TextValidator
            id="address"
            name="address"
            value={inputs.address}
            label="Delivery Address"
            variant="outlined"
            fullWidth
            autoComplete="shipping street-address"
            validators={['required', 'isString']}
            errorMessages={['Please enter a delivery address', 'Please enter a valid delivery address']}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox id="subscribe" name="subscribe" checked={inputs.subscribe === 'yes'} value="yes" color="primary" onChange={handleInputChange} />}
            label="I want to receive inspiration, marketing promotions and updates via email."
          />
        </Grid>
        <Grid container item xs={12} justifyContent="flex-end">
          <Button type="submit" variant="contained" color="primary" className={classes.submit} position="right">
            Register
          </Button>
        </Grid>
      </Grid>
    </ValidatorForm>
  );
};

RegisterForm.propTypes = {
  onSubmit: PropTypes.func,
};

RegisterForm.defaultProps = {
  onSubmit: () => {},
};

export default RegisterForm;
