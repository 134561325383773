import React from 'react';
import PropTypes from 'prop-types';

// Material-UI
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

// Styles
const useStyles = makeStyles((theme) => ({
  drop: theme.drop,
  titleIcon: {
    padding: theme.spacing(0, 2, 0, 0),
    position: 'relative',
    top: '6px',
  },
}));

const EmptyList = ({ icon, title, description, children }) => {
  const classes = useStyles();
  const Icon = icon;

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography variant="h2">
          {icon && <Icon fontSize="inherit" className={classes.titleIcon} />}
          {title}
        </Typography>
        <Typography className={classes.drop}>{description}</Typography>
      </CardContent>
      {children && <CardActions>{children}</CardActions>}
    </Card>
  );
};

EmptyList.propTypes = {
  icon: PropTypes.elementType,
  title: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.node,
};

EmptyList.defaultProps = {
  icon: <></>,
  title: '',
  description: '',
  children: null,
};

export default EmptyList;
