import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getOrder, getOrderLoading } from '../reducers';
import { loadOrder } from '../actions/orders';

const useOrderLoad = (oid) => {
  const dispatch = useDispatch();
  const orderLoading = useSelector(getOrderLoading);
  const order = useSelector(getOrder);

  useEffect(() => {
    dispatch(loadOrder(oid));
  }, [dispatch, oid]);

  return { orderLoading, order };
};

export default useOrderLoad;
