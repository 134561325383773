import indexOf from 'lodash/indexOf';

const orderSize = [
  'large',
  'texan',
  'medium',
  'cocktail',
  'extra large',
  'small',
  'loaf',
  'sliced',
  'individual',
  'large 10 inch',
  'large 11 inch',
  'petite',
  'shortbread',
  'half',
  'portioned',
  'mini',
  'buffet',
  'celebration 16 inch',
  'french stick',
  'log',
  '10 pack',
  '30 pack',
  'long rolls',
  'round rolls',
  'dinner rolls',
];

const orderPackaging = ['unwrapped', 'wrapped'];
const orderSliced = ['unsliced', 'sliced'];

export const getProductBySortOrder = (products) => {
  // eslint-disable-next-line fp/no-mutating-methods
  const sortedProducts = Object.entries(products)

    // Sort the list of products by product name.
    .slice()
    .filter((item) => item[0] !== 'productOptionsAll' && item[0] !== 'productOptionsMatrix')
    .sort((a, b) => {
      // Sort the product option by size and packaging.
      const orderSizeA = indexOf(orderSize, a[1].options.size);
      const orderSizeB = indexOf(orderSize, b[1].options.size);
      const orderPackagingA = indexOf(orderPackaging, a[1].options.packaging);
      const orderPackagingB = indexOf(orderPackaging, b[1].options.packaging);
      const orderSlicedA = indexOf(orderSliced, a[1].options.sliced);
      const orderSlicedB = indexOf(orderSliced, b[1].options.sliced);

      return 3 * orderSizeA + 2 * orderPackagingA + 1 * orderSlicedA > 3 * orderSizeB + 2 * orderPackagingB + 1 * orderSlicedB ? 1 : -1;
    })

    // Manipulate the result into something more suitable for our application.
    .reduce(
      (a, product) => ({
        ...a,
        [product[0]]: product[1],
      }),
      {},
    );

  return sortedProducts;
};

export { getProductBySortOrder as default };
