import React, { useMemo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as QueryString from 'query-string';

// Hooks
import useConfirmPayment from '../hooks/useConfirmPayment';

// Components
import Loading from './shared/Loading';

const PaymentPaid = ({ orderId, search, cart }) => {
  const [loading, setLoading] = useState(false);

  // Convert the query string into an object we can use to populate the product filter.
  const params = useMemo(() => QueryString.parse(search), [search]);
  const { payment_amount: paymentAmount, payment_date: paymentDate, bank_reference: bankReference, payment_number: paymentNumber } = params;

  // Convert the cart string back into an object.
  const cartParams = new URLSearchParams(cart);
  const cartObject = Object.fromEntries(cartParams);

  const onConfirmPayment = useConfirmPayment({ orderId, paymentDate, paymentAmount, bankReference, paymentNumber, cart: cartObject });

  // Finalise the order and save into GlobalBake
  useEffect(() => {
    if (!loading) {
      console.log(`PaymentPaid :: START PROCESS PAYMENT`);

      setLoading(true);
      onConfirmPayment();
    }
  }, [loading, setLoading, onConfirmPayment]);

  return <Loading loading={loading} mode="full" />;
};

PaymentPaid.propTypes = {
  orderId: PropTypes.string,
  cart: PropTypes.string,
  search: PropTypes.string,
};

PaymentPaid.defaultProps = {
  orderId: '',
  cart: '',
  search: '',
};

export default PaymentPaid;
