import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

// Actions
import { setCartOrderNumber } from '../actions/cart';

// Hooks
import useAction from './useAction';

// Reducers
import { getCartOrderNunber } from '../reducers';

const useOrderNumber = () => {
  const saveCartOrderNumber = useAction(setCartOrderNumber);
  const cartOrderNumber = useSelector(getCartOrderNunber);

  useEffect(() => {
    if (isEmpty(cartOrderNumber)) {
      const orderNo = `WEB-${Math.random().toString(36).slice(-7).toUpperCase()}`;

      // Store the date into localStorage. The date will be used if the user refreshes the page.
      localStorage.setItem('cartOrderNumber', orderNo);

      // Store the date into Redux
      saveCartOrderNumber(orderNo);
    } else {
      // Store the date into localStorage. The date will be used if the user refreshes the page.
      localStorage.setItem('cartOrderNumber', cartOrderNumber);
    }
  }, [cartOrderNumber, saveCartOrderNumber]);

  return cartOrderNumber;
};

export default useOrderNumber;
