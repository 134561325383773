// Define the constants for the application

const constants = {
  // App
  appName: process.env.REACT_APP_NAME, // VSE Customer Ordering Portal
  appUrl: process.env.REACT_APP_API_URL, // https://order.vseinternational.com.au/
  lambdaUrl: process.env.REACT_APP_LAMBDA_API_URL,

  // API
  apiUrl: process.env.REACT_APP_GLOBALBAKE_API_URL,
  cacheImageUrl: process.env.REACT_APP_LAMBDA_IMAGES_URL,
  sendEmailUrl: process.env.REACT_APP_LAMBDA_EMAIL_URL,
  sendEmailErrorUrl: process.env.REACT_APP_LAMBDA_EMAIL_ERROR_URL,
  confirmPaymentUrl: process.env.REACT_APP_LAMBDA_CONFIRM_PAYMENT_URL,
  registerKeepAliveUrl: process.env.REACT_APP_LAMBDA_REGISTER_KEEP_ALIVE_URL,
  tokenKeepAliveUrl: process.env.REACT_APP_LAMBDA_TOKEN_KEEP_ALIVE_URL,
  createCheckoutSessionUrl: process.env.REACT_APP_LAMBDA_CREATE_CHECKOUT_SESSION_URL,

  // Responsive
  isMobileBreakpoint: 'md',

  // Grid
  gridSpacing: 3,
  dropSpacing: 6,

  // Navigation
  profileNavigationWidth: 250,

  // Tooltips
  tooltipPlacement: 'top-start',
  tooltipEnterDelay: 500,
  tooltipLeaveDelay: 50,

  // Images
  width: 800,
  height: 600,

  // Payments
  bankAccount: process.env.REACT_APP_GLOBALBAKE_BANK_ACCOUNT,
  deliveryCost: 20,
  gstPercent: 10,

  // Delivery
  deliveryDelayIngredient: 3,
  deliveryDelaySalads: 2,
  deliveryDelaySauces: 2,
  deliveryDelayCocktailQuiches: 2,
  deliverySurchargeOid: process.env.REACT_APP_GLOBALBAKE_DELIVERY_SURCHARGE_OID,
  creditCardSurchargeOid: process.env.REACT_APP_GLOBALBAKE_CREDITCARD_SURCHARGE_OID,
  deliveryHoliday: [
    '2023-12-23',
    '2023-12-24',
    '2023-12-25',
    '2023-12-26',
    '2023-12-27',
    '2023-12-28',
    '2023-12-29',
    '2023-12-30',
    '2023-12-31',
    '2024-01-01',
    '2024-01-02',
    '2024-01-03',
    '2024-01-04',
    '2024-01-05',
    '2024-01-06',
    '2024-01-07',
  ],

  // Firebase
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY, // 'AIzaSyAxYSctgk3Nw-rvd4UMJoeNSoK1IKe6aAA',
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN, // 'vse---ordering-portal.firebaseapp.com',
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID, // 'vse---ordering-portal',
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET, // 'vse---ordering-portal.appspot.com',
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID, // '529729542553',
  appId: process.env.REACT_APP_FIREBASE_APP_ID, // '1:529729542553:web:879edf94fe9b9063489b08',
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID, // 'G-JRZVLQYQ5S',

  // Email
  errorEmailsEnabled: process.env.REACT_APP_ERROR_EMAILS_ENABLED === 'true',

  // Sentry
  sentryEnabled: process.env.REACT_APP_SENTRY_ENABLED === 'true',
  sentryDsn: process.env.REACT_APP_SENTRY_DSN, // https://b1480a5f68da4e8d8024d2ed16e52c77@o489889.ingest.sentry.io/5731953
  sentryEnvironment: process.env.REACT_APP_SENTRY_ENVIRONMENT, // 'live'

  // Sentry
  stripeSecretKey: process.env.REACT_APP_STRIPE_PUBLIC_KEY,
};

export default constants;
