import isEmpty from 'lodash/isEmpty';
import findKey from 'lodash/findKey';

export const getProductWithinProducts = (oid, products) => {
  if (!oid || isEmpty(products)) return {};

  const foundKey = findKey(products, oid);

  return foundKey ? products[foundKey][oid] : {};
};

export { getProductWithinProducts as default };
