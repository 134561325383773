import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

// Components
import ProductFilterItem from './ProductFilterItem';
import ProductFilterSearch from './ProductFilterSearch';

const ProductFilter = ({ search, category, subcategory, size, packaging, sliced, flavour, onSearch, onClearSearch, options }) => {
  const onSearchCategories = useCallback(
    (nextCategory) => onSearch({ category: nextCategory, subcategory: '', size: '', packaging: '', sliced: '', flavour: '' }),
    [onSearch],
  );
  const onSearchSubcategory = useCallback((nextSubcategory) => onSearch({ subcategory: nextSubcategory }), [onSearch]);
  const onSearchSizes = useCallback((nextSize) => onSearch({ size: nextSize }), [onSearch]);
  const onSearchPackagings = useCallback((nextPackaging) => onSearch({ packaging: nextPackaging }), [onSearch]);
  const onSearchSliced = useCallback((nextSliced) => onSearch({ sliced: nextSliced }), [onSearch]);
  const onSearchFlavours = useCallback((nextFlavour) => onSearch({ flavour: nextFlavour }), [onSearch]);

  return (
    <>
      <ProductFilterSearch search={search} onSearch={onSearch} onClearSearch={onClearSearch} />
      <ProductFilterItem title="Categories" options={options.category} selected={category} onFilter={onSearchCategories} />
      <ProductFilterItem title="Sub Categories" options={options.subcategory} selected={subcategory} onFilter={onSearchSubcategory} />
      <ProductFilterItem title="Sizes" options={options.size} selected={size} onFilter={onSearchSizes} />
      <ProductFilterItem title="Packaging" options={options.packaging} selected={packaging} onFilter={onSearchPackagings} />
      <ProductFilterItem title="Sliced" options={options.sliced} selected={sliced} onFilter={onSearchSliced} />
      <ProductFilterItem title="Flavours" options={options.flavour} selected={flavour} onFilter={onSearchFlavours} />
    </>
  );
};

ProductFilter.propTypes = {
  search: PropTypes.string,
  category: PropTypes.string,
  subcategory: PropTypes.string,
  size: PropTypes.string,
  packaging: PropTypes.string,
  sliced: PropTypes.string,
  flavour: PropTypes.string,
  onSearch: PropTypes.func,
  onClearSearch: PropTypes.func,
  options: PropTypes.shape({
    category: PropTypes.arrayOf(PropTypes.string),
    subcategory: PropTypes.arrayOf(PropTypes.string),
    size: PropTypes.arrayOf(PropTypes.string),
    packaging: PropTypes.arrayOf(PropTypes.string),
    sliced: PropTypes.arrayOf(PropTypes.string),
    flavour: PropTypes.arrayOf(PropTypes.string),
  }),
};

ProductFilter.defaultProps = {
  search: '',
  category: '',
  subcategory: '',
  size: '',
  packaging: '',
  sliced: '',
  flavour: '',
  onSearch: () => {},
  onClearSearch: () => {},
  options: {
    category: [],
    subcategory: [],
    size: [],
    packaging: [],
    sliced: [],
    flavour: [],
  },
};

export default ProductFilter;
