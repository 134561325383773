import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import snakeCase from 'lodash/snakeCase';

// Reducers
import { getProductsRelated } from '../reducers';

// Helpers
import { sizeWithoutUnitsPerCarton } from '../helpers/getProductsUnits';

const useProductFilter = (product, size, packaging, sliced) => {
  const products = useSelector((state) => getProductsRelated(state, product.webProductName));

  if (isEmpty(products) || isEmpty(size) || isEmpty(packaging) || isEmpty(sliced)) return {};

  const filteredProduct = Object.keys(products)
    .filter((key) => {
      const isOptions = key === 'productOptionsAll' || key === 'productOptionsMatrix';
      const sameSize =
        !isOptions && (snakeCase(products[key].options.size) === size || snakeCase(products[key].options.size) === sizeWithoutUnitsPerCarton(size));
      const samePackaging = !isOptions && snakeCase(products[key].options.packaging) === packaging;
      const sameSliced = !isOptions && snakeCase(products[key].options.sliced) === sliced;

      return samePackaging && sameSize && sameSliced;
    })
    .reduce((obj, key) => products[key], {});

  return filteredProduct;
};

export default useProductFilter;
