import { combineReducers } from 'redux';
import mapKeys from 'lodash/mapKeys';

const available = (state = null, action) => {
  switch (action.type) {
    case 'SET_SESSION_DATA': {
      if (!('rBKrunLine' in action.user)) return false;

      const map = {
        deliveryOkMon1: 'monday',
        deliveryOkTue1: 'tuesday',
        deliveryOkWed1: 'wednesday',
        deliveryOkThu1: 'thursday',
        deliveryOkFri1: 'friday',
        deliveryOkSat1: 'saturday',
        deliveryOkSun1: 'sunday',
      };

      let result = {
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
      };

      // Loop though each of the delivery runs and
      Object.keys(action.user.rBKrunLine).forEach((key) => {
        const debtorRun = action.user.rBKrunLine[key];

        // Only get days that can be delivered on.
        const debtorRunValidDays = Object.fromEntries(
          Object.entries(debtorRun).filter(([debtorRunKey, value]) => debtorRunKey.includes('delivery') && value !== null && value !== false),
        );

        // Remap the keys to the ones we use internally.
        const debtorRunCorrectKeys = mapKeys(debtorRunValidDays, (v, k) => map[k]);

        // eslint-disable-next-line fp/no-mutation
        result = { ...result, ...debtorRunCorrectKeys };
      });

      return result;
    }
    default:
      return state;
  }
};

const cutoff = (state = null, action) => {
  switch (action.type) {
    case 'SET_CART_DELIVERY_CUTOFF':
      return action.cutoff;
    default:
      return state;
  }
};

export default combineReducers({
  available,
  cutoff,
});

export const getDeliveryDates = (state) => state.available;
export const getDeliveryCutoff = (state) => state.cutoff;
