export const showModal = (type, order) => ({
  type: 'SHOW_MODAL',
  show: type,
  order,
});

export const hideModal = () => ({
  type: 'HIDE_MODAL',
  show: false,
});
