import React from 'react';
import { useSelector } from 'react-redux';
import { parseName } from 'humanparser';
import PropTypes from 'prop-types';

// Material-UI
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

// Config
import routes from '../config/routes';

// Actions
import { clearCart } from '../actions/cart';

// Hooks
import useAction from '../hooks/useAction';
import useOrderLoad from '../hooks/useOrderLoad';

// Reducers
import { getUser } from '../reducers';

// Components
import Layout from './shared/Layout';
import Link from './shared/Link';
import TitlePage from './shared/TitlePage';

// Styles
const useStyles = makeStyles((theme) => ({
  drop: theme.drop,
  dropHalf: theme.dropHalf,
  fullWidth: {
    width: '100%',
  },
  collapse: {
    marginLeft: 72,
  },
  button: {
    marginRight: theme.spacing(1),
  },
  capitalize: {
    textTransform: 'capitalize',
  },
  total: {
    height: 30,
  },
}));

const OrderConfirmed = ({ currentUrl, orderId }) => {
  const classes = useStyles();
  const onRemoveAllCartItems = useAction(clearCart);

  // User
  const user = useSelector(getUser);
  const contactName = user ? parseName(user.contactName) : '';

  // Orders
  const { orderLoading, order } = useOrderLoad(orderId);

  // Clear the cart when user returns back from payment gateway.
  onRemoveAllCartItems();

  return (
    <Layout currentUrl={currentUrl}>
      <Grid className={classes.dropHalf}>
        <TitlePage
          loading={orderLoading}
          title="Order Confirmed"
          subtitle={`Thanks${contactName.firstName ? `, ${contactName.firstName}` : ``}!`}
          orderDate={order.despatchDate}
          orderNumber={order.orderNo}
        >
          <Grid item xs={12} className={classes.dropHalf}>
            <Link to={`${routes.private.orders}/scheduled`}>
              <Button variant="contained" color="primary" className={classes.button}>
                View Orders
              </Button>
            </Link>
          </Grid>
        </TitlePage>
      </Grid>
    </Layout>
  );
};

OrderConfirmed.propTypes = {
  currentUrl: PropTypes.string,
  orderId: PropTypes.string,
};

OrderConfirmed.defaultProps = {
  currentUrl: '/',
  orderId: '',
};

export default OrderConfirmed;
