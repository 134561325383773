import { combineReducers } from 'redux';

const showErrors = (state = false, action) => {
  switch (action.type) {
    case 'SHOW_ERROR':
      return action.show;

    case 'HIDE_ERROR':
      return false;

    default:
      return state;
  }
};

const error = (state = false, action) => {
  switch (action.type) {
    case 'SET_ERROR':
      return action.message;

    default:
      return state;
  }
};

const errorObject = (state = false, action) => {
  switch (action.type) {
    case 'SET_ERROR':
      return typeof action.error === 'object' && action.error ? action.error : null;

    default:
      return state;
  }
};

const success = (state = false, action) => {
  switch (action.type) {
    case 'SET_SUCCESS':
      return action.message;

    default:
      return state;
  }
};

const errorModal = (state = false, action) => {
  switch (action.type) {
    case 'SET_ERROR_MODAL':
      return action.message;

    default:
      return state;
  }
};

const successModal = (state = false, action) => {
  switch (action.type) {
    case 'SET_SUCCESS_MODAL':
      return action.message;

    default:
      return state;
  }
};

export default combineReducers({
  showErrors,
  error,
  errorObject,
  success,
  errorModal,
  successModal,
});

export const getError = (state) => state.error;
export const getErrorObject = (state) => state.errorObject;
export const getSuccess = (state) => state.success;
export const getErrorModal = (state) => state.errorModal;
export const getSuccessModal = (state) => state.successModal;
