import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

// Material-UI
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import FavoriteBorderOutlinedIcon from '@material-ui/icons/FavoriteBorderOutlined';
import FavoriteOutlinedIcon from '@material-ui/icons/FavoriteOutlined';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Skeleton from '@material-ui/lab/Skeleton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

// Config
import constants from '../../config/constants';
import routes from '../../config/routes';

// Actions
import { saveFavourite } from '../../actions/favourites';

// Helpers
import { dollars } from '../../helpers/number';
import { sizeWithUnitsPerCarton } from '../../helpers/getProductsUnits';

// Hooks
import useAction from '../../hooks/useAction';
import useProductFavourite from '../../hooks/useProductFavourite';
import useProductImage from '../../hooks/useProductImage';
import useProductOptions from '../../hooks/useProductOptions';

// Components
// import { ReactComponent as IconGlutFree } from '../../assets/images/glut-free.svg';
import { ReactComponent as IconChill } from '../../assets/images/chill.svg';
// import { ReactComponent as IconVegan } from '../../assets/images/vegan.svg';
import Link from './Link';
import ModalCartAdd from './ModalCartAdd';

// Define valid options for variants
export const optionsVariant = {
  mini: 200,
  normal: 300,
  large: 800,
};

// Styles
const useStyles = makeStyles((theme) => ({
  card: {
    position: 'relative',
    boxShadow: '0 8px 20px -12px rgba(0,0,0,0.3)',
    borderRadius: 6,
  },
  skeleton: {
    width: '100%',
    display: 'block',
    position: 'absolute',
    paddingTop: '75%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    top: 0,
  },
  icons: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '-webkit-fill-available',
  },
  icon: {
    height: 30,
    margin: '12px 0 12px 12px',
    width: 30,
  },
  iconFavourite: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  title: {
    display: '-webkit-box',
    maxWidth: '100%',
    minHeight: 48,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    textTransform: 'capitalize',
  },
  price: {
    textAlign: 'right',
    marginTop: theme.spacing(2),
  },
  total: {
    fontSize: '1.5rem',
  },
  gst: {
    float: 'right',
    fontSize: '0.8rem',
  },
  tooltip: {
    fontSize: 14,
    color: '#fff',
    padding: theme.spacing(1),
  },
}));

const ProductItem = ({ loading, product, productId, showLinks, imageQuality }) => {
  const classes = useStyles();

  const [image, imageLoading] = useProductImage(productId, product.itemCode, optionsVariant[imageQuality]);
  const hasProduct = !isEmpty(product);
  const { productOptionsMatrix } = useProductOptions(product);
  const favourite = useProductFavourite(productId, productOptionsMatrix);
  const isFavourite = (favourite && favourite.favourite && favourite.favourite.webFavourite) || false;
  const onFavouriteClick = useAction(() => saveFavourite(favourite.productId, favourite.favourite));
  const isProductChill = hasProduct && product.temperature < 0;
  const showProductTitle = !loading && showLinks;
  const showLoadingProductTitle = loading && showLinks;

  if (!loading && !product) return null;

  return (
    <Card className={classes.card}>
      <CardMedia className={classes.cardMedia} image={image} title={product.webProductName}>
        {(imageLoading || !image) && <Skeleton variant="rect" className={classes.skeleton} />}
      </CardMedia>

      <Box className={classes.icons}>
        {isProductChill && (
          <Tooltip
            enterDelay={constants.tooltipEnterDelay}
            leaveDelay={constants.tooltipLeaveDelay}
            placement="top"
            title={<Typography className={classes.tooltip}>Chill</Typography>}
          >
            <IconChill className={classes.icon} />
          </Tooltip>
        )}
        {/*
        <Tooltip
          enterDelay={constants.tooltipEnterDelay}
          leaveDelay={constants.tooltipLeaveDelay}
          placement="top"
          title={<Typography className={classes.tooltip}>Gluten Free</Typography>}
        >
          <IconGlutFree className={classes.icon} />
        </Tooltip>
        */}
        {/*
        <Tooltip
          enterDelay={constants.tooltipEnterDelay}
          leaveDelay={constants.tooltipLeaveDelay}
          placement="top"
          title={<Typography className={classes.tooltip}>Vegan</Typography>}
        >
          <IconVegan className={classes.icon} />
        </Tooltip>
        */}
        {!isFavourite ? (
          <Tooltip
            enterDelay={constants.tooltipEnterDelay}
            leaveDelay={constants.tooltipLeaveDelay}
            placement="top"
            title={<Typography className={classes.tooltip}>Favorite</Typography>}
          >
            <IconButton color="primary" className={classes.iconFavourite} aria-label="delete" onClick={onFavouriteClick}>
              <FavoriteBorderOutlinedIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip
            enterDelay={constants.tooltipEnterDelay}
            leaveDelay={constants.tooltipLeaveDelay}
            placement="top"
            title={<Typography className={classes.tooltip}>Unfavorite</Typography>}
          >
            <IconButton color="primary" className={classes.iconFavourite} aria-label="delete" onClick={onFavouriteClick}>
              <FavoriteOutlinedIcon />
            </IconButton>
          </Tooltip>
        )}
      </Box>

      {showLoadingProductTitle && (
        <CardContent>
          <Typography gutterBottom variant="h4">
            <Skeleton variant="rect" />
          </Typography>
          <Typography gutterBottom color="textSecondary">
            <Skeleton variant="rect" width="80%" />
          </Typography>
          <Typography>
            <Skeleton variant="rect" width="40%" />
          </Typography>
        </CardContent>
      )}

      {showProductTitle && (
        <>
          <CardContent>
            <Typography variant="h6" className={classes.title}>
              {product.webProductName}
            </Typography>
            <Grid container direction="row" justifyContent="flex-end" alignItems="flex-start" spacing={constants.gridSpacing / 3}>
              <Grid item xs={12} className={classes.price}>
                <Typography display="inline">{`${sizeWithUnitsPerCarton('', product.unitsPerCtn, false, false)} for `}</Typography>
                <Typography display="inline" className={classes.total}>
                  {dollars(product.sellingPrice)}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2" className={classes.gst}>
                  Ex. GST
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
          <CardActions>
            <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end" spacing={constants.gridSpacing / 3}>
              <ModalCartAdd productId={productId} product={product} productOptionsMatrix={productOptionsMatrix} />
              <Link color="primary" to={`${routes.private.product}/${productId}`}>
                <Button size="small" color="primary">
                  View
                </Button>
              </Link>
            </Grid>
          </CardActions>
        </>
      )}
    </Card>
  );
};

ProductItem.propTypes = {
  loading: PropTypes.bool,
  product: PropTypes.shape({
    oid: PropTypes.string,
    itemCode: PropTypes.string,
    webProductName: PropTypes.string,
    sellingPrice: PropTypes.number,
    temperature: PropTypes.number,
    unitsPerCtn: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    options: PropTypes.shape({
      size: PropTypes.string,
    }),
  }),
  productId: PropTypes.string,
  showLinks: PropTypes.bool,
  imageQuality: PropTypes.oneOf(Object.keys(optionsVariant)),
};

ProductItem.defaultProps = {
  loading: false,
  product: {
    oid: '1234.123',
    itemCode: '',
    webProductName: '',
    sellingPrice: 0,
    temperature: 0,
    unitsPerCtn: 0,
    options: {
      size: '',
    },
  },
  productId: null,
  showLinks: false,
  imageQuality: 'large',
};

export default ProductItem;
