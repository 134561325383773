// Define the routes for the application
const routes = {
  public: {
    contact: '/contact',
    faq: '/faq',
    home: '/',
    terms: '/terms',
    payment_paid: '/payment_paid', // Payment Gateway - triggered automatically at the time the receipt page is displayed to the customer
  },

  auth: {
    forgot: '/forgot',
    login: '/login',
    logout: '/',
    register: '/register',
    // guest: '/guest',
  },

  private: {
    cart: '/cart',
    contact: '/contact',
    faq: '/faq',
    favourites: '/favourites',
    notifications: '/notifications',
    order_confirm: '/order_confirm',
    order_paid: '/order_paid', // Payment Gateway - customer must click on the URL for the remote service to receive data
    order: '/order',
    orders: '/orders',
    product: '/product',
    products: '/products',
    profile: '/profile',
    terms: '/terms',
    order_processing: '/order_processing',
  },
};

export default routes;
