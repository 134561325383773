import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';

// Material-UI
import { makeStyles } from '@material-ui/core/styles';
import SearchBar from 'material-ui-search-bar';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';

// Styles
const useStyles = makeStyles((theme) => ({
  drop: theme.drop,
  dropHalf: theme.dropHalf,
  search: {
    boxShadow: 'none',
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    borderRadius: '0',
    backgroundColor: 'transparent',
  },
}));

const ProductFilterSearch = ({ search, onSearch, onClearSearch }) => {
  const classes = useStyles();

  const [searchTerm, setSearch] = useState(search);
  const typingTimeout = useRef();

  const searchIcon = <SearchOutlinedIcon />;

  // Filter the products, on search, category, size and packaging.
  useEffect(() => {
    if (typingTimeout.current) clearTimeout(typingTimeout.current);

    typingTimeout.current = setTimeout(() => {
      onSearch({ searchTerm });
    }, 300);
  }, [searchTerm, typingTimeout, onSearch]);

  const onSearchChange = useCallback((newValue) => setSearch(newValue), [setSearch]);
  const onSearchRequest = useCallback((nextSearchTerm) => onSearch({ searchTerm: nextSearchTerm }), [onSearch]);
  const onSearchCancel = useCallback(() => onClearSearch(), [onClearSearch]);

  return (
    <SearchBar
      placeholder="Search our selection"
      value={searchTerm}
      className={classes.search}
      closeIcon={searchIcon}
      onChange={onSearchChange}
      onCancelSearch={onSearchCancel}
      onRequestSearch={onSearchRequest}
    />
  );
};

ProductFilterSearch.propTypes = {
  search: PropTypes.string,
  onSearch: PropTypes.func,
  onClearSearch: PropTypes.func,
};

ProductFilterSearch.defaultProps = {
  search: '',
  onSearch: () => {},
  onClearSearch: () => {},
};

export default ProductFilterSearch;
