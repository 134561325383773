import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import toNumber from 'lodash/toNumber';
import isNaN from 'lodash/isNaN';

// API
import { fetchOrders, fetchOrder, fetchOrderByOrderNumber, editOrderLineItem, deleteOrderLineItem, deleteOrder } from '../api/Orders';

// Helpers
import { getOrderWithinOrders } from '../helpers/getOrderWithinOrders';
import { dollars } from '../helpers/number';

// Actions
import { setError, setSuccess } from './notifications';
import { setUserExpired } from './users';

export const setOrders = (type, orders) => ({
  type: 'SET_ORDERS',
  [type]: { orders, loading: false },
});

export const setOrdersLoading = (type) => ({
  type: 'SET_ORDERS_LOADING',
  [type]: true,
});

export const setOrder = (order) => ({
  type: 'SET_ORDER',
  order,
});

export const setOrderLoading = () => ({
  type: 'SET_ORDER_LOADING',
});

export const setRemoveOrder = (order) => ({
  type: 'REMOVE_ORDER',
  order,
});

export const loadOrders = (type, limit, offset) => async (dispatch) => {
  if (!type) return false;

  dispatch(setOrdersLoading(type));

  const token = localStorage.getItem('tokens'); // Retrieve the token from localStorage. The token will be used if the user refreshes the page.

  return fetchOrders(token, type, limit, offset)
    .then((response) => {
      dispatch(setOrders(type, response.data.objects));
      return response;
    })
    .catch((error) => {
      if (error.message) dispatch(setError(String(error.message), error));
      if (error.expired) dispatch(setUserExpired());
      return error;
    });
};

export const loadOrder = (oid) => async (dispatch) => {
  if (!oid) return false;

  dispatch(setOrderLoading());

  const token = localStorage.getItem('tokens'); // Retrieve the token from localStorage. The token will be used if the user refreshes the page.
  const oidNumber = toNumber(oid);

  if (isNumber(oidNumber) && !isNaN(oidNumber)) {
    return fetchOrder(token, oid)
      .then((response) => {
        dispatch(setOrder(response.data || {}));
        return response;
      })
      .catch((error) => {
        if (error.message) dispatch(setError(String(error.message), error));
        if (error.expired) dispatch(setUserExpired());
        return error;
      });
  }

  if (isNaN(oidNumber)) {
    return fetchOrderByOrderNumber(token, oid)
      .then((response) => {
        dispatch(setOrder(response.data || {}));
        return response;
      })
      .catch((error) => {
        if (error.message) dispatch(setError(String(error.message), error));
        if (error.expired) dispatch(setUserExpired());
        return error;
      });
  }

  return fetchOrder(token, oid)
    .then((response) => {
      dispatch(setOrder(response.data || {}));
      return response;
    })
    .catch((error) => {
      if (error.message) dispatch(setError(String(error.message), error));
      if (error.expired) dispatch(setUserExpired());
      return error;
    });
};

export const changeOrderLineItem = (order) => async (dispatch) => {
  if (isEmpty(order)) return false;

  const token = localStorage.getItem('tokens'); // Retrieve the token from localStorage. The token will be used if the user refreshes the page.

  return editOrderLineItem(token, order)
    .then(() => dispatch(loadOrder(order.rBKdocket)))
    .then((response) => {
      const { class: responseClass, request, ...responseOrder } = response.data;
      return responseOrder;
    })
    .catch((error) => {
      if (error.message) dispatch(setError(String(error.message), error));
      if (error.expired) dispatch(setUserExpired());
      return error;
    });
};

export const removeOrderLineItem = (order, lineItem) => async (dispatch) => {
  if (isEmpty(lineItem)) return false;

  const token = localStorage.getItem('tokens'); // Retrieve the token from localStorage. The token will be used if the user refreshes the page.

  // Find the order within all orders.
  const found = getOrderWithinOrders(lineItem.oid, order.rBKdocketLine);
  const removedItem = order.rBKdocketLine[found.key];

  return deleteOrderLineItem(token, lineItem)
    .then(() => dispatch(loadOrder(order.oid)))
    .then((response) => {
      dispatch(setSuccess(`Removed ${removedItem.rBKproduct.webProductName} from your order "${order.orderNo}". We will credit the difference.`));
      const { class: responseClass, request, ...responseOrder } = response.data;
      return responseOrder;
    })
    .catch((error) => {
      if (error.message) dispatch(setError(String(error.message), error));
      if (error.expired) dispatch(setUserExpired());
      return error;
    });
};

export const removeOrder = (order) => async (dispatch) => {
  if (!order) return false;

  dispatch(setOrderLoading());

  const token = localStorage.getItem('tokens'); // Retrieve the token from localStorage. The token will be used if the user refreshes the page.

  return deleteOrder(token, order)
    .then((response) => {
      dispatch(setSuccess(`Order "${order.orderNo}" has been deleted. Your account will be credited ${dollars(order.totalNett)}`));
      return response;
    })
    .then((result) => {
      dispatch(setRemoveOrder(order));
      return result;
    })
    .catch((error) => {
      if (error.message) dispatch(setError(String(error.message), error));
      if (error.expired) dispatch(setUserExpired());
      return error;
    });
};
