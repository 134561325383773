import React from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';

// Material-UI
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Typography from '@material-ui/core/Typography';

// Config
import constants from '../config/constants';

// Components
import Layout from './shared/Layout';

// Styles
const useStyles = makeStyles((theme) => ({
  drop: theme.drop,
  dropHalf: theme.dropHalf,

  container: {
    margin: 'auto',
    maxWidth: '960px',
  },

  header: {
    fontSize: '0.875rem',
    fontWeight: '600',
    letterSpacing: '0.4px',
    textTransform: 'uppercase',
  },

  title: {
    backgroundColor: 'white',
    fontWeight: '600',
    letterSpacing: '0.4px',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(5),
    textTransform: 'uppercase',
  },

  titleNumber: {
    color: theme.palette.primary.main,
    display: 'inline',
    fontSize: '0.875rem',
    paddingRight: 10,
  },

  answer: {
    marginLeft: 30,
  },

  answerNumber: {
    color: theme.palette.primary.main,
    display: 'inline',
    fontSize: '0.875rem',
    fontWeight: 600,
    letterSpacing: 0.4,
    marginBottom: 16,
    paddingRight: 10,
    textTransform: 'uppercase',
  },

  answerText: {
    display: 'inline',
    fontWeight: 'normal',
    marginTop: theme.spacing(1),
    textTransform: 'inherit',

    '& a': {
      color: theme.palette.primary.main,
      textDecoration: 'none',
      '&:hover': {
        color: theme.palette.primary.dark,
      },
    },
  },
}));

const sections = [
  {
    id: 1,
    title: 'Ordering',
    terms: [
      {
        id: 1,
        text: 'For next-day deliveries, Tuesday through to Saturday, orders must be placed no later than 3 pm daily the preceding day. Orders for Monday can be placed no later than Sunday 12 pm via email only. We do not deliver on Sunday.',
      },
      {
        id: 2,
        text: 'Minimum order applies and must exceed the value of $40 + GST. Orders under the minimum will incur a delivery charge.',
      },
      {
        id: 3,
        text: 'Saturday deliveries to Canberra, Central Coast, Gosford, Hunter Region, and Wollongong area will require a $150 + GST delivery minimum. Orders under the minimum will incur a delivery charge.',
      },
      {
        id: 4,
        text: 'Private orders must be pre-paid and an image of the location sent through to sales for transport.',
      },
      {
        id: 5,
        text: 'Orders placed after the deadline will be attended to on the next business day.',
      },
      {
        id: 6,
        text: 'A "Standing Order" can be placed which will guarantee next day delivery. This order can be adjusted daily on request and will ensure delivery to your door if you forget to place an order.',
      },
      {
        id: 7,
        text: 'Cancellations or amendments to orders/standing orders must be made prior to weekday 3 pm cut-off or 12 pm Sunday cut-off. If received after, we are not able to credit or receive the product back.',
      },
      {
        id: 8,
        text: 'Public holidays will affect delivery days and times, please check with the sales team or request a schedule. Standing orders will be sent out on the next available delivery day, unless specified otherwise.',
      },
      {
        id: 9,
        text: 'All new customers, who have not filled out an account application form, are required to pre-pay their orders via credit card or bank transfer. Supplying sales with proof of payment to finalise the order.',
      },
      {
        id: 10,
        text: "Same-day deliveries/pick-up's will incur a $20.00 + GST additional order fee.",
      },
    ],
  },
  {
    id: 2,
    title: 'Deliveries',
    terms: [
      {
        id: 1,
        text: 'Any orders approved to be left unattended on delivery will not be approved for any credits due to the product being stolen or damaged.',
      },
      {
        id: 2,
        text: 'Traffic and weather conditions are unpredictable, and we cannot guarantee a set delivery time. Our drivers request a 3 hour time window which they can make the delivery however this is not a guarantee. Please check with the sales team should you require a specific delivery time frame.',
      },
      {
        id: 3,
        text: "We request that at the time of delivery the person accepting the order checks the product has been received correctly and in good order. If there are any discrepancies, please note it on the driver's manifest or call the sales team at the time of delivery. Signing the manifest indicates whole order has been received correctly and in good order.",
      },
      {
        id: 4,
        text: 'If you require empty bread crates to be collected, please contact our sales team and request a pick-up. We cannot guarantee collection without having been informed.',
      },
    ],
  },
  {
    id: 3,
    title: 'Products',
    terms: [
      {
        id: 1,
        text: 'Product size, weight, consistency, description, and packaging configurations are subject to change at our discretion and without notice. Actual products compared to pictures featured may vary.',
      },
      {
        id: 2,
        text: 'All products manufactured in a facility that also handles gluten, egg, milk, tree nuts, sesame seeds, and soy products. As a result, the products may contain traces of these allergens.',
      },
    ],
  },
  {
    id: 4,
    title: 'Packing Configurations',
    terms: [
      {
        id: 1,
        text: 'To provide a fresher, longer-lasting product for ease of delivery, we have designed our products to be delivered in pack configuration as outlines in this price list.',
      },
      {
        id: 2,
        text: 'When placing your order, please keep within the required pack quantities. Any orders which do not comply will be rounded up to the stated quantity amounts.',
      },
      {
        id: 3,
        text: 'Some products may be delivered in just crates due to our efforts to be environmentally friendly.',
      },
    ],
  },
  {
    id: 5,
    title: 'Prices',
    terms: [
      {
        id: 1,
        text: 'Prices are subject to change at our discretion. Please note that the GST component has not been applied to these prices and may vary slightly due to rounding.',
      },
    ],
  },
  {
    id: 6,
    title: 'Credits / Refund Policy',
    terms: [
      {
        id: 1,
        text: 'To ensure that you are issued credit notes correctly and for traceability purposes, our office must be notified of any discrepancies with 2 business days of having received the product. Either via email or over the phone. Any requests made outside of this time frame may not be granted.',
      },
      {
        id: 2,
        text: 'Signing the manifest with no notes of any discrepancies may result in no credit being authorised.',
      },
      {
        id: 3,
        text: 'If you have ordered a product and you would like to return the product, you must first contact our sales department and explain (with photos if required) why you wish to return the product. A pick-up will then be organized.',
      },
      {
        id: 4,
        text: 'The product must be returned for a credit to be approved.',
      },
      {
        id: 5,
        text: 'We will offer a replacement, where possible. To be able to produce this means we will need to re-invoice the product.',
      },
      {
        id: 6,
        text: 'Credits take 3-5 business days to process.',
      },
      {
        id: 7,
        text: 'We do not offer compensation for loss of sales under any circumstances.',
      },
    ],
  },
  {
    id: 7,
    title: 'Payments',
    terms: [
      {
        id: 1,
        text: 'For account customers, payment must be made within the Trading Terms. If your account should go into arrears we may suspend further deliveries until payment has been received, without warning. A late payment fee of $10.00 + GST will be charged to your account.',
      },
      {
        id: 2,
        text: 'For customers not on account, we require payment to be strictly pre-paid via credit card or transfer.',
      },
      {
        id: 3,
        text: 'We further reserve the right to recover all expenses, costs, and/or disbursements incurred in recovering all overdue money, including debt collection fees and all legal costs. The ownership of any goods supplied by VSE International does not transfer to the customer until such goods are fully paid for.',
      },
      {
        id: 4,
        text: 'A $25.00 + GST fee will be charged to your account in the event of a dishonoured payment.',
      },
      {
        id: 5,
        text: 'Should you have a standing order with us and your account is on hold due to pending payments, your standing order will be unable to generate until the presenting payment discrepancy has been resolved.',
      },
    ],
  },
  {
    id: 8,
    title: 'Payment Methods',
    terms: [
      {
        id: 1,
        text: 'Direct Debit: Available from a bank account or credit card. No surcharged apply for bank account direct debit.',
      },
      {
        id: 2,
        text: 'Direct Debit Transfer: Please transfer funds to VSE International BSB: 969-100 Account: 701308809',
      },
      {
        id: 3,
        text: 'Credit Card Payments: Visa/Master Card - these incur a 1% surcharge. Amex Card - this incurs a 2.2% surcharge.',
      },
      {
        id: 4,
        text: 'BPAY: Details featured on the bottom of your invoice.',
      },
      {
        id: 5,
        text: 'Cash: We do not accept cash at this time.',
      },
    ],
  },
  {
    id: 9,
    title: 'Change of Ownership',
    terms: [
      {
        id: 1,
        text: "VSE International must be notified of any changes of ownership within 7 days from the date of such change and indemnifies VSE International against any loss or damages occurred by it as a result of the customer's failure to notify us of any change.",
      },
    ],
  },
];

const Terms = ({ currentUrl }) => {
  const classes = useStyles();

  return (
    <Layout currentUrl={currentUrl}>
      <Grid container spacing={constants.gridSpacing} justifyContent="flex-end" className={classes.container}>
        <Grid item xs={12}>
          <Typography variant="h3" align="center" className={classes.drop}>
            Terms &amp; Conditions
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.container}>
          <List className={classes.list} subheader={<li />}>
            {sections.map((section) => (
              <li key={`section-${section.id}`} className={classes.listSection}>
                <ul className={classes.ul}>
                  <ListSubheader>
                    <Typography className={classes.title} component="h2" variant="h6">
                      <Box className={classes.titleNumber}>{`${section.id}.0`}</Box>
                      {ReactHtmlParser(section.title)}
                    </Typography>
                  </ListSubheader>
                  {section.terms.map((term) => (
                    <ListItem key={`item-${section.id}-${term.id}`}>
                      <ListItemText
                        primary={`${section.id}.${term.id}`}
                        secondary={ReactHtmlParser(term.text)}
                        className={classes.answer}
                        classes={{
                          primary: classes.answerNumber,
                          secondary: classes.answerText,
                        }}
                      />
                    </ListItem>
                  ))}
                </ul>
              </li>
            ))}
          </List>
        </Grid>
      </Grid>
    </Layout>
  );
};

Terms.propTypes = {
  currentUrl: PropTypes.string,
};

Terms.defaultProps = {
  currentUrl: '/',
};

export default Terms;
