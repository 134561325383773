import isEmpty from 'lodash/isEmpty';

export const getCartHasProduct = (search, cartItems) => {
  const searchString = search.trim().toLowerCase();

  const hasProduct = Object.entries(cartItems).filter((item) => item[1].subCategory.trim().toLowerCase() === searchString);

  return !isEmpty(hasProduct);
};

export { getCartHasProduct as default };
