import { combineReducers } from 'redux';

import cart, * as fromCart from './Cart';
import deliveries, * as fromDeliveries from './Deliveries';
import favourites, * as fromFavourites from './Favourites';
import invoices, * as fromInvoices from './Invoices';
import modals, * as fromModals from './Modals';
import notifications, * as fromNotifications from './Notifications';
import orders, * as fromOrders from './Orders';
import products, * as fromProducts from './Products';
import blacklistProducts, * as fromBlacklistProducts from './BlacklistProducts';
import users, * as fromUsers from './Users';

const rootReducer = combineReducers({
  cart,
  deliveries,
  favourites,
  invoices,
  modals,
  notifications,
  orders,
  products,
  blacklistProducts,
  users,
});

export default rootReducer;

// Cart
export const getCartDate = (state) => fromCart.getCartDate(state.cart) || new Date();
export const getCartLoading = (state) => fromCart.getCartLoading(state.cart);
export const getCartItems = (state) => fromCart.getCartItems(state.cart);
export const getCartOrderNunber = (state) => fromCart.getCartOrderNunber(state.cart);
export const getCartPaymentReference = (state) => fromCart.getCartPaymentReference(state.cart);

// Deliveries
export const getDeliveryDates = (state) => fromDeliveries.getDeliveryDates(state.deliveries);
export const getDeliveryCutoff = (state) => fromDeliveries.getDeliveryCutoff(state.deliveries);

// Favourites
export const getFavourites = (state) => fromFavourites.getFavourites(state.favourites);
export const getFavouritesLoading = (state) => fromFavourites.getFavouritesLoading(state.favourites);

// Invoices
export const getInvoices = (state) => fromInvoices.getInvoices(state.invoices);
export const getInvoicesLoading = (state) => fromInvoices.getInvoicesLoading(state.invoices);

// Notifications
export const getError = (state) => fromNotifications.getError(state.notifications);
export const getErrorObject = (state) => fromNotifications.getErrorObject(state.notifications);
export const getSuccess = (state) => fromNotifications.getSuccess(state.notifications);
export const getErrorModal = (state) => fromNotifications.getErrorModal(state.notifications);
export const getSuccessModal = (state) => fromNotifications.getSuccessModal(state.notifications);

// Modal
export const getModal = (state) => fromModals.getModal(state.modals);

// Orders
export const getOrder = (state) => fromOrders.getOrder(state.orders);
export const getOrderLoading = (state) => fromOrders.getOrderLoading(state.orders);
export const getOrders = (state) => fromOrders.getOrders(state.orders);
export const getOrdersLoading = (state) => fromOrders.getOrdersLoading(state.orders);

// Products
export const getBlacklistProducts = (state) => fromBlacklistProducts.getBlacklistProducts(state.blacklistProducts);
export const getProduct = (state) => fromProducts.getProduct(state.products);
export const getProductLoading = (state, oid) => fromProducts.getProductLoading(state.products, oid);
export const getProductsFilter = (state) => fromProducts.getProductsFilter(state.products);
export const getProducts = (state) => fromProducts.getProducts(state.products);
export const getProductsLoading = (state) => fromProducts.getProductsLoading(state.products);
export const getProductsLoaded = (state) => fromProducts.getProductsLoaded(state.products);
export const getProductsRelatedLoading = (state) => fromProducts.getProductsRelatedLoading(state.products);
export const getProductImage = (state, oid, width) => fromProducts.getProductImage(state.products, oid, width);
export const getProductImageLoading = (state, oid, width) => fromProducts.getProductImageLoading(state.products, oid, width);

export const getProductsRelated = (state, webProductName) => fromProducts.getProductsRelated(state.products, webProductName);
export const getProductOptionsAll = (state, webProductName) => fromProducts.getProductOptionsAll(state.products, webProductName);
export const getProductOptionsMatrix = (state, webProductName) => fromProducts.getProductOptionsMatrix(state.products, webProductName);

// Users
export const getContact = (state) => fromUsers.getContact(state.users);
export const getMinimumOrderValue = (state) => fromUsers.getMinimumOrderValue(state.users);
export const getUser = (state) => fromUsers.getUser(state.users);
export const getUserLoading = (state) => fromUsers.getUserLoading(state.users);
export const getUserName = (state) => fromUsers.getUserName(state.users);
export const getUserOutstanding = (state) => fromUsers.getUserOutstanding(state.users);
