import isArray from 'lodash/isArray';

export const getProductRelatedIds = (productOptionsMatrix) => {
  let result = [];

  // Loop though the product option matrix and get the id's at the bottom of the object.
  Object.keys(productOptionsMatrix).forEach((key) => {
    const element = productOptionsMatrix[key];
    if (!isArray(element)) {
      // eslint-disable-next-line fp/no-mutation
      result = [...result, ...getProductRelatedIds(element)];
    } else {
      // eslint-disable-next-line fp/no-mutation
      result = [...result, ...element];
    }
  });

  return result;
};

export { getProductRelatedIds as default };
