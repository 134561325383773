import { useCallback } from 'react';
import { confirmPayment } from '../api/Authentication';

const useConfirmPayment = ({ orderId, paymentDate, paymentAmount, bankReference, paymentNumber }) =>
  useCallback(async () => {
    try {
      await confirmPayment({
        orderId,
        paymentAmount,
        paymentDate,
        paymentNumber,
        bankReference,
      }).then((response) => {
        console.log(`PAYMENT API THEN :: response = `, JSON.stringify(response));
        return response;
      });
    } catch (error) {
      console.log(`PAYMENT API ERROR :: error = `, JSON.stringify(error));
    }
  }, [bankReference, orderId, paymentAmount, paymentDate, paymentNumber]);

export default useConfirmPayment;
