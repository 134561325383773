import { combineReducers } from 'redux';

const products = (state = {}, action) => {
  switch (action.type) {
    case 'SET_PRODUCTS': {
      return action.products;
    }

    case 'RESET_PRODUCTS':
      return {};

    default:
      return state;
  }
};

const productsFilter = (state = '', action) => {
  switch (action.type) {
    case 'SET_PRODUCTS_FILTER':
      return action.search || '';
    default:
      return state;
  }
};

const productsLoading = (state = false, action) => {
  switch (action.type) {
    case 'SET_PRODUCTS_LOADING':
      return true;
    case 'SET_PRODUCTS':
      return false;
    default:
      return state;
  }
};

const productsLoaded = (state = false, action) => {
  switch (action.type) {
    case 'SET_PRODUCTS':
      return Date.now();
    default:
      return state;
  }
};

const product = (state = {}, action) => {
  switch (action.type) {
    case 'SET_PRODUCT':
      return action.product;

    case 'RESET_PRODUCT':
      return {};

    default:
      return state;
  }
};

const productLoading = (state = [], action) => {
  switch (action.type) {
    case 'SET_PRODUCT_LOADING':
      return [...state, action.oid];
    case 'SET_PRODUCT':
      return [];
    default:
      return state;
  }
};

const productImagesLoading = (state = {}, action) => {
  switch (action.type) {
    case 'SET_PRODUCT_IMAGE_LOADING':
      return { ...state, [action.oid]: { ...state[action.oid], [action.width]: true } };

    case 'SET_PRODUCT_IMAGE':
      return { ...state, [action.product.oid]: { ...state[action.product.oid], [action.product.width]: false } };

    default:
      return state;
  }
};

const productImages = (state = {}, action) => {
  switch (action.type) {
    case 'SET_PRODUCT_IMAGE':
      return { ...state, [action.product.oid]: { ...state[action.product.oid], [action.product.width]: action.product.picture } };

    default:
      return state;
  }
};

// const productsRelated = (state = [], action) => {
//   switch (action.type) {
//     case 'SET_PRODUCTS_RELATED':
//       return action.productsRelated;
//     default:
//       return state;
//   }
// };

// const productsRelatedLoading = (state = false, action) => {
//   switch (action.type) {
//     case 'SET_PRODUCTS_RELATED_LOADING':
//       return true;
//     case 'SET_PRODUCTS_RELATED':
//       return false;
//     default:
//       return state;
//   }
// };

export default combineReducers({
  products,
  productsFilter,
  productsLoading,
  productsLoaded,
  product,
  productLoading,
  productImages,
  productImagesLoading,
  // productsRelated,
  // productsRelatedLoading,
});

export const getProducts = (state) => state.products;
export const getProductsFilter = (state) => state.productsFilter;
export const getProductsLoading = (state) => state.productsLoading;
export const getProductsLoaded = (state) => state.productsLoaded;
export const getProduct = (state) => state.product;
export const getProductLoading = (state, oid) => state.productLoading.includes(oid);
export const getProductImage = (state, oid, width) => (state.productImages[oid] && state.productImages[oid][width]) || '';
export const getProductImageLoading = (state, oid, width) => (state.productImagesLoading[oid] && state.productImagesLoading[oid][width]) || false;
export const getProductsRelated = (state, webProductName) => state.products[webProductName];
export const getProductOptionsAll = (state, webProductName) => (webProductName && state.products[webProductName] ? state.products[webProductName].options : []);
export const getProductOptionsMatrix = (state, webProductName) =>
  webProductName && state.products[webProductName] ? state.products[webProductName].productOptionsMatrix : {};
export const getProductsRelatedLoading = (state) => state.productsRelatedLoading;
