import React from 'react';
import PropTypes from 'prop-types';

// Material-UI
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

// Styles
const useStyles = makeStyles((theme) => ({
  drop: theme.drop,
  dropHalf: theme.dropHalf,

  columnOne: {
    width: 300,
    verticalAlign: 'top',

    [theme.breakpoints.down('sm')]: {
      width: 100,
    },
  },
}));

function createDataNutrition(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const NutritionTable = ({ rowsNutrition = {} }) => {
  const classes = useStyles();

  return (
    <Table className={classes.dropHalf} aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell component="th" scope="row">
            <Typography>&nbsp;</Typography>
          </TableCell>
          <TableCell align="right">
            <Typography>Avg. qty. per serving</Typography>
          </TableCell>
          <TableCell align="right">
            <Typography>Avg. qty. per 100g</Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rowsNutrition.map((row) => (
          <TableRow key={row.name}>
            <TableCell component="th" scope="row">
              <Typography>{row.name}</Typography>
            </TableCell>
            <TableCell align="right">
              <Typography>{row.calories}</Typography>
            </TableCell>
            <TableCell align="right">
              <Typography>{row.fat}</Typography>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

NutritionTable.propTypes = {
  rowsNutrition: PropTypes.shape({
    name: PropTypes.string,
    calories: PropTypes.number,
    fat: PropTypes.number,
  }),
};

NutritionTable.defaultProps = {
  rowsNutrition: {
    name: '',
    calories: 0,
    fat: 0,
  },
};

const ProductNutrition = ({ product }) => {
  const classes = useStyles();

  const rowsNutrition = [
    createDataNutrition(
      'Enery (kj)',
      `${(product.xBKnutrition && product.xBKnutrition.energy) || '0'}Kj`,
      `${(product.xBKnutrition && product.xBKnutrition.energyBase) || '0'}Kj`,
    ),
    createDataNutrition(
      'Protein (g)',
      `${(product.xBKnutrition && product.xBKnutrition.protein) || '0'}g`,
      `${(product.xBKnutrition && product.xBKnutrition.proteinBase) || '0'}g`,
    ),
    createDataNutrition(
      'Fat-Total (g)',
      `${(product.xBKnutrition && product.xBKnutrition.totalFat) || '0'}g`,
      `${(product.xBKnutrition && product.xBKnutrition.totalFatBase) || '0'}g`,
    ),
    createDataNutrition(
      '- Saturated',
      `${(product.xBKnutrition && product.xBKnutrition.saturatedFat) || '0'}g`,
      `${(product.xBKnutrition && product.xBKnutrition.saturatedFatBase) || '0'}g`,
    ),
    createDataNutrition(
      'Carbohydrate (g)',
      `${(product.xBKnutrition && product.xBKnutrition.carbohydrate) || '0'}g`,
      `${(product.xBKnutrition && product.xBKnutrition.carbohydrateBase) || '0'}g`,
    ),
    createDataNutrition(
      'Sugars (g)',
      `${(product.xBKnutrition && product.xBKnutrition.sugar) || '0'}g`,
      `${(product.xBKnutrition && product.xBKnutrition.sugarBase) || '0'}g`,
    ),
    createDataNutrition(
      'Dietry Fibre (g)',
      `${(product.xBKnutrition && product.xBKnutrition.dietaryFibre) || '0'}g`,
      `${(product.xBKnutrition && product.xBKnutrition.dietaryFibreBase) || '0'}g`,
    ),
    createDataNutrition(
      'Sodium (g)',
      `${(product.xBKnutrition && product.xBKnutrition.sodium) || '0'}g`,
      `${(product.xBKnutrition && product.xBKnutrition.sodiumBase) || '0'}g`,
    ),
  ];

  return (
    <Grid item xs={12}>
      <Table aria-label="simple table">
        <TableBody>
          <TableRow key="ingredients">
            <TableCell component="th" scope="row" className={classes.columnOne}>
              <Typography component="h5" variant="h5">
                Ingredients:
              </Typography>
            </TableCell>
            <TableCell style={{ whiteSpace: 'pre-wrap' }}>
              <Typography>{product.rawMaterialsListCsv || ''}</Typography>
            </TableCell>
          </TableRow>
          <TableRow key="allergens">
            <TableCell component="th" scope="row" className={classes.columnOne}>
              <Typography component="h5" variant="h5">
                Allergens:
              </Typography>
            </TableCell>
            <TableCell style={{ whiteSpace: 'pre-wrap' }}>
              <Typography>{product.allergens || ''}</Typography>
            </TableCell>
          </TableRow>
          <TableRow key="nutritional">
            <TableCell component="th" scope="row" className={classes.columnOne}>
              <Typography component="h5" variant="h5">
                Nutritional Information:
              </Typography>
            </TableCell>
            <TableCell style={{ whiteSpace: 'pre-wrap' }}>
              <Typography>Servings per pack: {product.unitsPerCtn || '0'}</Typography>
              <Typography>Approx serving size: {product.servingSize || '0'}g</Typography>
              <Hidden smDown>
                <NutritionTable rowsNutrition={rowsNutrition} />
              </Hidden>
            </TableCell>
          </TableRow>
          <Hidden mdUp>
            <TableRow key="nutrition">
              <TableCell component="th" scope="row" colSpan={2} className={classes.columnOne}>
                <NutritionTable rowsNutrition={rowsNutrition} />
              </TableCell>
            </TableRow>
          </Hidden>
          <TableRow key="disclaimer">
            <TableCell component="th" scope="row" className={classes.columnOne}>
              <Typography component="h5" variant="h5">
                Disclaimer:
              </Typography>
            </TableCell>
            <TableCell style={{ whiteSpace: 'pre-wrap' }}>
              <Typography>
                Please note that all product specifications are subject to change at our discretion, and actual products received may vary to the information or
                image provided.
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow key="allergen">
            <TableCell component="th" scope="row" className={classes.columnOne}>
              <Typography component="h5" variant="h5">
                Allergen Warning:
              </Typography>
            </TableCell>
            <TableCell style={{ whiteSpace: 'pre-wrap' }}>
              <Typography>Made in a facility that also uses Dairy, Nuts, Sesame ,Eggs, Soya and Sulphites.</Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Grid>
  );
};

ProductNutrition.propTypes = {
  product: PropTypes.shape({
    oid: PropTypes.string,
    allergens: PropTypes.string,
    unitsPerCtn: PropTypes.number,
    servingSize: PropTypes.number,
    rawMaterialsListCsv: PropTypes.string,
    xBKnutrition: PropTypes.shape({
      energy: PropTypes.number,
      energyBase: PropTypes.number,
      protein: PropTypes.number,
      proteinBase: PropTypes.number,
      totalFat: PropTypes.number,
      totalFatBase: PropTypes.number,
      saturatedFat: PropTypes.number,
      saturatedFatBase: PropTypes.number,
      carbohydrate: PropTypes.number,
      carbohydrateBase: PropTypes.number,
      sugar: PropTypes.number,
      sugarBase: PropTypes.number,
      dietaryFibre: PropTypes.number,
      dietaryFibreBase: PropTypes.number,
      sodium: PropTypes.number,
      sodiumBase: PropTypes.number,
    }),
  }),
};

ProductNutrition.defaultProps = {
  product: {
    oid: '',
    allergens: '',
    unitsPerCtn: 0,
    servingSize: 0,
    rawMaterialsListCsv: '',
    xBKnutrition: {
      energy: 0,
      energyBase: 0,
      protein: 0,
      proteinBase: 0,
      totalFat: 0,
      totalFatBase: 0,
      saturatedFat: 0,
      saturatedFatBase: 0,
      carbohydrate: 0,
      carbohydrateBase: 0,
      sugar: 0,
      sugarBase: 0,
      dietaryFibre: 0,
      dietaryFibreBase: 0,
      sodium: 0,
      sodiumBase: 0,
    },
  },
};

export default ProductNutrition;
