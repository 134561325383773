import { combineReducers } from 'redux';

const showModal = (state = false, action) => {
  switch (action.type) {
    case 'SHOW_MODAL':
      return action.show;

    case 'HIDE_MODAL':
      return false;

    default:
      return state;
  }
};

export default combineReducers({
  showModal,
});

export const getModal = (state) => state.showModal;
