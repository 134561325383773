import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

// Date
import DayJS from 'react-dayjs';

// Material-UI
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

// Config
import constants from '../../config/constants';
import routes from '../../config/routes';

// Helpers
import { dollars } from '../../helpers/number';

// Components
import Link from './Link';

const useStyles = makeStyles((theme) => ({
  marginBottom: {
    marginBottom: theme.spacing(1),
  },
  align: {
    display: 'flex',
    alignItems: 'center',
  },
  link: {
    '&:hover': {
      color: theme.palette.primary.dark,
    },
  },
}));

const TitleWelcome = ({ loading, name, balance, orderDate, invoiceDate }) => {
  const classes = useStyles();

  if (loading)
    return (
      <Grid container item xs={12} spacing={constants.gridSpacing}>
        <Grid item xs={12}>
          <Typography variant="h4" className={classes.marginBottom}>
            Welcome back!
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" className={classes.marginBottom}>
            Your next order is scheduled for delivery on:
          </Typography>
          <Typography variant="body1" color="primary">
            <Skeleton variant="rect" width="80%" />
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="body1" className={classes.marginBottom}>
            Account Balance:
          </Typography>
          <Typography variant="body2">
            <Skeleton variant="rect" width="80%" />
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="body1" className={classes.marginBottom}>
            Next Invoice Due:
          </Typography>
          <Typography variant="body2">
            <Skeleton variant="rect" width="80%" />
          </Typography>
        </Grid>
      </Grid>
    );

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h4" className={classes.marginBottom}>{`Welcome back${name ? `, ${name}` : ``}!`}</Typography>
      </Grid>
      {!orderDate && (
        <Grid item xs={12}>
          <Typography variant="body1" className={classes.marginBottom}>
            You have no scheduled orders.
          </Typography>
        </Grid>
      )}
      {orderDate && (
        <Grid item xs={12}>
          <Typography variant="body1" className={classes.marginBottom}>
            Your next order is scheduled for delivery on:
          </Typography>
          <Typography variant="h6">
            <DayJS format="dddd, MMMM D, YYYY">{orderDate}</DayJS>
          </Typography>
        </Grid>
      )}
      <Grid item xs={12} md={6}>
        <Typography variant="body1" className={classes.marginBottom}>
          Account Balance:
        </Typography>
        <Typography variant="h6">{dollars(balance)}</Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="body1" className={classes.marginBottom}>
          Next Invoice Due:
        </Typography>
        <Typography variant="h6">
          {!isEmpty(invoiceDate) && <DayJS format="MMMM D, YYYY">{invoiceDate}</DayJS>}
          {isEmpty(invoiceDate) && <>No invoices due.</>}
        </Typography>
      </Grid>
      <Grid item xs={12} md={5}>
        <Link to={`${routes.private.orders}/scheduled`}>
          <Typography variant="h6" color="primary" className={`${classes.link} ${classes.align}`}>
            View Orders <ChevronRightIcon />
          </Typography>
        </Link>
      </Grid>
    </>
  );
};

TitleWelcome.propTypes = {
  loading: PropTypes.bool,
  name: PropTypes.string,
  balance: PropTypes.number,
  orderDate: PropTypes.string,
  invoiceDate: PropTypes.string,
};

TitleWelcome.defaultProps = {
  loading: false,
  name: '',
  balance: 0,
  orderDate: '',
  invoiceDate: '',
};

export default TitleWelcome;
