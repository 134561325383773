// import React, { useState, useCallback } from 'react';
import React from 'react';
import PropTypes from 'prop-types';
// import { useDispatch } from 'react-redux';
// import isEmpty from 'lodash/isEmpty';
// import pluralize from 'pluralize';

// Material-UI
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

// Actions
// import { changeOrderLineItem, removeOrderLineItem } from '../../actions/orders';
// import { setSuccess } from '../../actions/notifications';
// import { showModal } from '../../actions/modals';

// Helpers
// import { customJoin } from '../../helpers/string';
// import { deleteProductWithinOrder } from '../../helpers/deleteProductWithinOrder';
import { sizeWithUnitsPerCarton } from '../../helpers/getProductsUnits';
// import { updateProductWithinOrder } from '../../helpers/updateProductWithinOrder';

// Hooks
// import useAction from '../../hooks/useAction';
import useProductOptions from '../../hooks/useProductOptions';
// import useDebounce from '../../hooks/useDebounce';

// Components
import ProductOptions from './ProductOptions';

// Styles
const useStyles = makeStyles((theme) => ({
  drop: theme.drop,
  dropHalf: theme.dropHalf,
  name: {
    paddingLeft: 53,
  },
  itemRow: {
    fontSize: '0.9rem',
    fontWeight: 300,
  },
  itemTotal: {
    fontSize: '0.9rem',
    textAlign: 'right',
    fontWeight: 300,
  },
  total: {
    paddingTop: theme.spacing(1.25),
    paddingBottom: theme.spacing(1.25),
    textAlign: 'right',
    border: 'none',
  },
  totalRow: {
    border: 'none',
    paddingTop: theme.spacing(1.25),
    paddingBottom: theme.spacing(1.25),
  },
  grandTotal: {
    borderColor: theme.palette.secondary.main,
    borderTop: '1px solid',
    borderLeft: 0,
    backgroundColor: '#B0DF2B0D',
    fontWeight: 400,
  },
  row: {
    borderBottom: '1px solid #f5f5f5',
  },
  rowOdd: {
    backgroundColor: '#B0DF2B0D',
  },
  rowEven: {},
  sum: {
    fontWeight: 500,
  },
  cell: {
    paddingTop: theme.spacing(1.25),
    paddingBottom: theme.spacing(1.25),
    borderBottom: 'none',
  },
  helpIcon: {
    fontSize: 14,
    position: 'absolute',
  },
  button: {
    float: 'right',
  },
}));

const OrdersListLineItems = ({ index, order, product, disabled, onOrderChange, onOrderLineItemDelete }) => {
  const classes = useStyles();
  // const dispatch = useDispatch();

  // const [saving, setSaving] = useState(false);
  const { productOptionsMatrix } = useProductOptions(product.rBKproduct);
  // const onChangeOrderLineItem = useAction((value) => changeOrderLineItem(value));
  // const onRemoveOrderLineItem = useAction((value, lineItem) => removeOrderLineItem(value, lineItem));
  // const onShowDeleteLineItemModal = useCallback((value) => dispatch(showModal('delete_last_product', value)), [dispatch]);

  // const onProductChangeDebounce = useDebounce((productNew, changedLineItem) => {
  //   const quantity = changedLineItem.data.quantity || index;
  //   const changes = [];
  //   // eslint-disable-next-line fp/no-mutating-methods
  //   if (changedLineItem.hasDifferentQuantity) changes.push('quantity');
  //   // eslint-disable-next-line fp/no-mutating-methods
  //   if (changedLineItem.hasDifferentSize) changes.push('size');
  //   // eslint-disable-next-line fp/no-mutating-methods
  //   if (changedLineItem.hasDifferentPackaging) changes.push('packaging');

  //   // Flag the order in the saving process.
  //   setSaving(true);

  //   // Update the order line item.
  //   onChangeOrderLineItem(changedLineItem.data).then((response) => {
  //     // On successful update, notify the user of the change.
  //     if (!isEmpty(changes))
  //       dispatch(
  //         setSuccess(`Updated ${customJoin(changes, "' ", ' & ')} for ${pluralize(productNew.webProductName, quantity)} in your ${response.orderNo} order.`),
  //       );

  //     // Call the parent function when orders are changed.
  //     onOrderChange(response);
  //     // Reset loading.
  //     setSaving(false);
  //     return response;
  //   });
  // }, 250);

  // const onProductChange = useCallback(
  //   (key, inputs) => {
  //     const productPrevious = order.rBKdocketLine[index].rBKproduct || {};
  //     const productNew = inputs.products[0];
  //     const hasQuantityChanged = order.rBKdocketLine[index].quantity !== productNew.quantity;
  //     const hasProductChanged = productPrevious.oid !== productNew.oid;
  //     const changedLineItem = updateProductWithinOrder(order, productNew, index);

  //     if (!saving && (hasProductChanged || hasQuantityChanged) && !isEmpty(changedLineItem.data)) {
  //       setSaving(true);
  //       onProductChangeDebounce(productNew, changedLineItem);
  //     }

  //     return true;
  //   },
  //   [order, index, saving, onProductChangeDebounce],
  // );

  // const onProductDelete = useCallback(
  //   (key, inputs) => {
  //     if (order.rBKdocketLine.length < 2) {
  //       onOrderLineItemDelete();
  //       onShowDeleteLineItemModal(order);
  //     } else {
  //       if (saving) return false;
  //       const changedLineItem = deleteProductWithinOrder(order, inputs.products[key]);

  //       if (!saving && !isEmpty(changedLineItem)) {
  //         // Remove the lineitem from the order.
  //         onRemoveOrderLineItem(order, changedLineItem).then((response) => {
  //           // Call the parent function and update the order and reset loading.
  //           onOrderChange(response);

  //           setSaving(false);
  //           return response;
  //         });
  //       }
  //     }

  //     return true;
  //   },
  //   [order, saving, onShowDeleteLineItemModal, onOrderLineItemDelete, onRemoveOrderLineItem, onOrderChange],
  // );

  return (
    <TableRow className={classes.row} key={`OrdersList-${order.oid}-LineItem-${index}`}>
      <TableCell colSpan={4} className={`${classes.cell} ${index % 2 ? classes.rowEven : classes.rowOdd}`}>
        <ProductOptions
          productId={product.rBKproduct.oid}
          itemCode={product.itemCode}
          webProductName={product.rBKproduct.webProductName}
          category={product.rBKproduct.WebCategory}
          subCategory={product.rBKproduct.WebSubCategory}
          sellingPrice={product.extended / product.quantity}
          quantity={product.quantity}
          maximumOrderQty={product.maximumOrderQty}
          exemptGst={product.exemptGst}
          size={sizeWithUnitsPerCarton(product.rBKproduct.WebSize, product.rBKproduct.unitsPerCtn)}
          packaging={product.rBKproduct.WebWrapped ? 'wrapped' : 'unwrapped'}
          sliced={product.rBKproduct.WebSliced ? 'sliced' : 'unsliced'}
          options={productOptionsMatrix}
          disabled={disabled}
          showAddRow={false}
          showDeleteRow={!disabled}
          showProductName
          showProductCost
          showActions={false}
          className={classes.paddingLeft}
          // onProductAdd={onProductAdd}
          // onProductChange={onProductChange}
          // onProductDelete={onProductDelete}
        />
      </TableCell>
    </TableRow>
  );
};

OrdersListLineItems.propTypes = {
  index: PropTypes.number,
  order: PropTypes.shape({
    oid: PropTypes.string,
    rBKdocketLine: PropTypes.arrayOf(
      PropTypes.shape({
        oid: PropTypes.string,
        quantity: PropTypes.number,
        extended: PropTypes.number,
        rBKproduct: PropTypes.shape({
          oid: PropTypes.string,
          webProductName: PropTypes.string,
          sellingPrice: PropTypes.number,
          unitsPerCtn: PropTypes.number,
          WebCategory: PropTypes.string,
          WebSubCategory: PropTypes.string,
          WebSize: PropTypes.string,
          WebWrapped: PropTypes.bool,
          WebSliced: PropTypes.bool,
        }),
      }),
    ),
  }),
  product: PropTypes.shape({
    oid: PropTypes.string,
    itemCode: PropTypes.string,
    webProductName: PropTypes.string,
    sellingPrice: PropTypes.number,
    extended: PropTypes.number,
    quantity: PropTypes.number,
    maximumOrderQty: PropTypes.number,
    exemptGst: PropTypes.bool,
    rBKproduct: PropTypes.shape({
      oid: PropTypes.string,
      webProductName: PropTypes.string,
      sellingPrice: PropTypes.number,
      unitsPerCtn: PropTypes.number,
      WebCategory: PropTypes.string,
      WebSubCategory: PropTypes.string,
      WebSize: PropTypes.string,
      WebWrapped: PropTypes.bool,
      WebSliced: PropTypes.bool,
    }),
  }),
  disabled: PropTypes.bool,
  onOrderChange: PropTypes.func,
  onOrderLineItemDelete: PropTypes.func,
};

OrdersListLineItems.defaultProps = {
  index: 0,
  order: {
    rBKdocketLine: [],
  },
  product: {},
  disabled: false,
  onOrderChange: () => {},
  onOrderLineItemDelete: () => {},
};

export default OrdersListLineItems;
