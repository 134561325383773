import React from 'react';
import { BrowserRouter, useLocation, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

// Config
import routes from './config/routes';

// Helpers
import { identifyCurrentRoute } from './helpers/identifyCurrentRoute';

// Components
import AppUnauthenticated from './AppUnauthenticated';
import AppAuthenticated from './AppAuthenticated';

export default function AppBody({ loading, user }) {
  const location = useLocation();
  const hasUserData = typeof user === 'object' && user !== null && Object.keys(user).length > 0;
  const body = hasUserData ? <AppAuthenticated /> : <AppUnauthenticated />;
  const [sections] = identifyCurrentRoute(location, hasUserData);

  // If the user is not logged in and is trying to access a private page, redirect to the login screen.
  if (!loading && !hasUserData && !(sections.includes('auth') || sections.includes('public'))) {
    return (
      <Redirect
        to={{
          pathname: routes.auth.login,
          state: { referrer: location },
        }}
      />
    );
  }

  // If the user is logged in and is trying to access a public page, redirect to the favourites dashboard.
  if (!loading && hasUserData && !sections.includes('private')) {
    return (
      <Redirect
        to={{
          pathname: routes.private.favourites,
          state: { referrer: location },
        }}
      />
    );
  }

  return <BrowserRouter>{body}</BrowserRouter>;
}

AppBody.propTypes = {
  loading: PropTypes.bool,
  user: PropTypes.shape({
    oid: PropTypes.string,
    token: PropTypes.string,
  }),
};

AppBody.defaultProps = {
  loading: false,
  user: {
    oid: '',
    token: '',
  },
};
