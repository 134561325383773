import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from '@stripe/react-stripe-js';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import constants from '../../config/constants';
import routes from '../../config/routes';
import { getAuthenticationToken } from '../../helpers/getAuthenticationToken';
import { getDebtorId } from '../../helpers/getDebtorId';
import { parseFloat2dp } from '../../helpers/number';

const useStyles = makeStyles((theme) => ({
  checkoutModal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90vw',
    maxWidth: '1100px',
    maxHeight: '90vh',
    boxSizing: 'border-box',
    overflow: 'auto',
    backgroundColor: theme.palette.background.paper,
    padding: '2rem',
    borderRadius: '4px',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const stripePromise = loadStripe(constants.stripeSecretKey);

const CheckoutForm = ({ orderNo, cartItems, cartGst, onClose }) => {
  const classes = useStyles();

  const debtorId = getDebtorId();
  const tokens = getAuthenticationToken();
  const returnLinkBaseUrl = `${constants.appUrl}${routes.private.order_processing}/`;

  const cartItemsArray = Object.values(cartItems);

  const lineItems = cartItemsArray.map(({ sellingPrice, webProductName, size, packaging, sliced, quantity }) => ({
    price_data: {
      currency: 'aud',
      unit_amount: Math.round(parseFloat2dp(sellingPrice) * 100),
      product_data: {
        name: webProductName + (size ? `, ${size}` : '') + (packaging ? `, ${packaging}` : '') + (sliced ? `, ${sliced}` : ''),
      },
    },
    quantity: parseFloat(quantity),
  }));

  const lineItemsWithGst = [
    ...lineItems,
    {
      price_data: {
        currency: 'aud',
        unit_amount: parseInt(Math.round(cartGst * 100), 10),
        product_data: {
          name: 'GST',
        },
      },
      quantity: 1,
    },
  ];

  // Stripe - Credit Card Fees
  // The formula is Pcharge = (Pgoal + Ffixed)/(1 - Fpercent).

  // Where:
  // - Pcharge: the final amount your product will have.
  // - Pgoal: the amount you want to take.
  // - Ffixed: [Fixed amount in decimals, e.g 30c is 0.3] fixed cents for Stripe pricing.
  // - Fpercent: [Percentual amount in decimals equivalent, e.g 2.9% is 0.029] percentual Stripe fee.

  // So let's say that you want to sell a product for $50. In that case the formula would be like this:

  // Pcharge = (50 + [Ffixed])/(1 - [Fpercent])
  // Pcharge = [result]/[result]
  // Pcharge = [Final result]

  // Meaning that you'll need to charge your customers [Final result] for that product. Stripe fees would be [Pcharge-Pgoal] and you will have your full $50
  // Your fees plan is AU default pricing w/1.75% Amex domestic; with USD and NZD.

  // Calculate credit card fee
  const calculateCreditCardFee = (amount) => {
    const Ffixed = 30;
    const Fpercent = 1.7;
    const result = amount + amount * (Fpercent / 100) + Ffixed;
    return result;
  };

  const totalAmount = lineItemsWithGst.reduce((sum, item) => sum + item.price_data.unit_amount * item.quantity, 0);
  const creditCardFee = calculateCreditCardFee(totalAmount) - totalAmount;

  const lineItemsWithGstAndCreditCardFee = [
    ...lineItemsWithGst,
    {
      price_data: {
        currency: 'aud',
        unit_amount: Math.round(creditCardFee),
        product_data: {
          name: 'Credit Card Fee',
        },
      },
      quantity: 1,
    },
  ];

  console.log(`TESTING2 :: ======================= `);
  console.log(`TESTING2 :: totalAmount = `, totalAmount);
  console.log(`TESTING2 :: creditCardFee = `, creditCardFee);
  console.log(`TESTING2 :: lineItems = `, lineItems);
  console.log(`TESTING2 :: lineItemsWithGst = `, lineItemsWithGst);
  console.log(`TESTING2 :: lineItemsWithGstAndCreditCardFee = `, lineItemsWithGstAndCreditCardFee);

  const fetchClientSecret = async () => {
    try {
      const response = await axios.post(constants.createCheckoutSessionUrl, {
        lineItems: lineItemsWithGstAndCreditCardFee,
        returnLinkBaseUrl,
        orderNo,
        debtorId,
        tokens,
      });
      return JSON.parse(response.data)?.session?.client_secret;
    } catch (error) {
      console.error(`postCheckoutSession ERROR :: error = `, JSON.stringify(error));
      return error;
    }
  };

  const options = { fetchClientSecret };

  return (
    <div id="checkout" className={classes.checkoutModal}>
      <IconButton className={classes.closeButton} onClick={onClose}>
        <CloseIcon />
      </IconButton>
      <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </div>
  );
};

CheckoutForm.propTypes = {
  cartItems: PropTypes.shape({}).isRequired,
  cartGst: PropTypes.number.isRequired,
  orderNo: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CheckoutForm;
