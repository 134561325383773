import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import size from 'lodash/size';

// Actions
import { loadFavourites } from '../actions/favourites';
import { loadProducts } from '../actions/products';

// Helpers
import { getFavouritesFiltered } from '../helpers/getFavouritesFiltered';

// Reducers
import { getFavourites, getFavouritesLoading, getProducts, getProductsLoading } from '../reducers';

const useFavouritesLoad = (type = 'all', limit = 12, offset = 0) => {
  const dispatch = useDispatch();
  const loadingFavourites = useSelector(getFavouritesLoading);
  const loadingProducts = useSelector(getProductsLoading);
  const favouritesRaw = useSelector(getFavourites);
  const products = useSelector(getProducts);
  const count = 0;

  useEffect(() => {
    dispatch(loadProducts());
  }, [dispatch]);

  useEffect(() => {
    dispatch(loadFavourites(type, limit, offset));
  }, [dispatch, type, limit, offset]);

  const favouriteProductsSorted = getFavouritesFiltered('products', favouritesRaw, products);
  const favouriteIngredientsSorted = getFavouritesFiltered('ingredients', favouritesRaw, products);

  return {
    favouritesLoading: loadingFavourites || loadingProducts,
    favouritesLimit: limit === 0 ? 0 : count,

    favouriteProducts: favouriteProductsSorted || {},
    favouriteProductsCount: size(favouriteProductsSorted),

    favouriteIngredients: favouriteIngredientsSorted || {},
    favouriteIngredientsCount: size(favouriteIngredientsSorted),
  };
};

export default useFavouritesLoad;
