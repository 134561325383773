import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { sendEmailMessage } from '../api/Email';
import { setSuccess } from '../actions/notifications';

const useRegisterSubmit = () => {
  const dispatch = useDispatch();

  return useCallback(
    async (data) => {
      try {
        const { firstName, lastName } = data;

        await sendEmailMessage({
          from: data.email,
          subject: 'New sign up request',
          type: 'register-notification',
          preview: `${firstName} ${lastName} would like to sign up to the system.`,
          data,
        });
      } catch (error) {
        console.log(error);
      }

      dispatch(setSuccess(`Thank you. We will personally review your registration and make contact with you very soon.`));
    },
    [dispatch],
  );
};

export default useRegisterSubmit;
