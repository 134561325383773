import React from 'react';
import PropTypes from 'prop-types';

// Redux
import { Provider } from 'react-redux';
import configureStore from './config/configureStore';

const store = configureStore();

const ProviderWrapper = ({ children }) => <Provider store={store}>{children}</Provider>;

ProviderWrapper.propTypes = {
  children: PropTypes.node,
};

ProviderWrapper.defaultProps = {
  children: null,
};

export default ProviderWrapper;
