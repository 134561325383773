import { useDispatch } from 'react-redux';

// Hooks
import useInterval from './useInterval';
import { reauthenticateUser } from '../actions/users';

const useUserKeepAlive = () => {
  const dispatch = useDispatch();
  const delay = 540000; // 9 minutes

  useInterval(() => {
    dispatch(reauthenticateUser());
  }, delay);

  return {};
};

export default useUserKeepAlive;
