import React from 'react';
import PropTypes from 'prop-types';

// Material-UI
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// Config
import constants from '../config/constants';

// Hooks
import useContactSubmit from '../hooks/useContactSubmit';

// Components
import ContactForm from './shared/ContactForm';
import Layout from './shared/Layout';

// Styles
const useStyles = makeStyles((theme) => ({
  drop: theme.drop,
  dropHalf: theme.dropHalf,
  container: {
    maxWidth: '960px',
    margin: 'auto',
  },
}));

const Contact = ({ currentUrl }) => {
  const classes = useStyles();
  const onSubmit = useContactSubmit();
  return (
    <Layout currentUrl={currentUrl}>
      <Grid container spacing={constants.gridSpacing} justifyContent="flex-end" className={classes.container}>
        <Grid item xs={12}>
          <Typography variant="h3" align="center" className={classes.drop}>
            Contact Us
          </Typography>
        </Grid>
        <ContactForm loggedIn onSubmit={onSubmit} />
      </Grid>
    </Layout>
  );
};

Contact.propTypes = {
  currentUrl: PropTypes.string,
};

Contact.defaultProps = {
  currentUrl: '/',
};

export default Contact;
