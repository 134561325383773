import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getProductImage, getProductImageLoading } from '../reducers';
import { loadProductImage } from '../actions/products';

const doGetProductImage =
  (oid, width = 800) =>
  (state) =>
    getProductImage(state, oid, width);

const doGetProductImageLoading = (oid, width) => (state) => getProductImageLoading(state, oid, width);

const useProductImage = (oid, itemCode, width) => {
  const dispatch = useDispatch();
  const picture = useSelector(doGetProductImage(oid, width));
  const loading = useSelector(doGetProductImageLoading(oid, width));

  useEffect(() => {
    if (oid && !picture) {
      dispatch(loadProductImage(oid, itemCode, width));
    }
  }, [dispatch, picture, oid, itemCode, width]);

  return [picture, loading];
};

export default useProductImage;
