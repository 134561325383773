import isEmpty from 'lodash/isEmpty';

// Helpers
import { getProductWithinProducts } from './getProductWithinProducts';

export const getFavouritesFiltered = (type, favourites, products) => {
  if (isEmpty(favourites) || isEmpty(products)) return {};

  // Manipulate the favourties and products into one object we can use for filtering.
  const favouriteProducts = Object.entries(favourites).reduce((filteredProducts, favourite) => {
    const productId = favourite[0];
    const isFavourite = favourite[1].webFavourite || false;
    const product = isFavourite ? getProductWithinProducts(productId, products) : {};
    const isIngredient = !isEmpty(product) && product.options.category === 'ingredients';

    if (!isEmpty(product) && isFavourite && ((type === 'ingredients' && isIngredient) || (type !== 'ingredients' && !isIngredient))) {
      return {
        ...filteredProducts,
        [product.webProductName]: products[product.webProductName],
      };
    }

    return filteredProducts;
  });

  // Sort and filter the favourites
  // eslint-disable-next-line fp/no-mutating-methods
  return Object.keys(favouriteProducts)
    .sort()
    .filter((item) => !isEmpty(favouriteProducts[item].productOptionsAll))
    .reduce(
      (a, k) => ({
        ...a,
        [k]: favouriteProducts[k],
      }),
      {},
    );
};

export { getFavouritesFiltered as default };
