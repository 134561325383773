/* eslint-disable react/prop-types */ // TODO: HACK - Investigate why eslint throws a lot of errors on this page
import React from 'react';
import { Route, Switch } from 'react-router-dom';

// Material-UI
import Grid from '@material-ui/core/Grid';

// Config
import routes from './config/routes';

// Components
import Home from './components/Home';
import NotFound from './components/errors/NotFound';
import ScrollToTop from './components/shared/ScrollToTop';
import ModalError from './components/modals/ModalError';
import PaymentPaid from './components/PaymentPaid';

export default function AppUnauthenticated() {
  return (
    <Route
      render={({ location }) => (
        <Grid container direction="column" justifyContent="center" alignItems="center">
          <Switch location={location}>
            {/* Public */}
            <Route exact path={routes.public.home} component={Home} />

            {/* Payment Gateway */}
            <Route
              exact
              path="/payment_paid/:oid/:cart"
              render={(props) => (
                <PaymentPaid
                  currentUrl={props.location.pathname}
                  orderId={props.match.params.oid}
                  cart={props.match.params.cart}
                  search={props.location.search}
                />
              )}
            />

            {/* Auth */}
            <Route exact path={routes.public.login} render={() => <Home tab="LogIn" />} />
            <Route exact path={routes.public.register} render={() => <Home tab="Register" />} />
            <Route exact path={routes.public.forgot} render={() => <Home tab="Forgot" />} />
            <Route exact path={routes.public.contact} render={() => <Home tab="Contact" />} />
            {/* <Route exact path={routes.public.guest} render={() => <Home tab="Guest" />} /> */}

            {/* 404 Not Found - catch-all component */}
            <Route component={NotFound} />
          </Switch>
          <ModalError />
          <ScrollToTop />
        </Grid>
      )}
    />
  );
}
