import React from 'react';
import PropTypes from 'prop-types';

// Material-UI
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';

// Config
import routes from '../../config/routes';
import constants from '../../config/constants';

// Components
import Link from './Link';

// Images
import BannerHighendRange from '../../assets/images/banners/banner-400x220-highend-range.jpg';
import BannerPiesRolls from '../../assets/images/banners/banner-400x220-pies-rolls.jpg';
import BannerSandwiches from '../../assets/images/banners/banner-400x220-sandwiches.jpg';
import BannerDonuts from '../../assets/images/banners/banner-400x220-donuts.jpg';
import BannerBakedBread from '../../assets/images/banners/banner-1197x319-baked-bread.jpg';

const useStyles = makeStyles((theme) => ({
  drop: theme.drop,
  dropHalf: theme.dropHalf,
  banner: {
    width: '100%',
  },
}));

const Banners = ({ mode }) => {
  const classes = useStyles();

  if (mode === 'header')
    return (
      <>
        <Grid container spacing={constants.gridSpacing}>
          <Grid item xs={12} md={8}>
            <Link to={`${routes.private.products}?category=bread`}>
              <img src={BannerBakedBread} alt="View our freshly baked bread" className={classes.banner} />
            </Link>
          </Grid>

          <Hidden smDown>
            <Grid item md={4}>
              <Link to={`${routes.private.products}?subcategory=donuts%2Fwaffles`}>
                <img src={BannerDonuts} alt="View our donuts and cronuts" className={classes.banner} />
              </Link>
            </Grid>
          </Hidden>
        </Grid>
      </>
    );

  return (
    <>
      <Grid item xs={12} sm={6} md={3}>
        <Link to={`${routes.private.products}?subcategory=high%20end%20desserts`}>
          <img src={BannerHighendRange} alt="View our high-end range" className={classes.banner} />
        </Link>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <Link to={`${routes.private.products}?subcategory=pies%2Frolls`}>
          <img src={BannerPiesRolls} alt="View our savoury pastries, pies and rolls" className={classes.banner} />
        </Link>
      </Grid>
      <Hidden smDown>
        <Grid item xs={12} sm={6} md={3}>
          <Link to={`${routes.private.products}?subcategory=sandwiches%2Fwraps`}>
            <img src={BannerSandwiches} alt="View our sandwiches, wraps and salads" className={classes.banner} />
          </Link>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Link to={`${routes.private.products}?subcategory=donuts%2Fwaffles`}>
            <img src={BannerDonuts} alt="View our donuts and cronuts" className={classes.banner} />
          </Link>
        </Grid>
      </Hidden>
    </>
  );
};

Banners.propTypes = { mode: PropTypes.string };

Banners.defaultProps = { mode: 'footer' };

export default Banners;
