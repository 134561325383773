import { combineReducers } from 'redux';

const blacklistProducts = (state = {}, action) => {
  switch (action.type) {
    case 'SET_BLACKLIST_PRODUCTS': {
      return action.products;
    }

    case 'RESET_BLACKLIST_PRODUCTS':
      return {};

    default:
      return state;
  }
};

export default combineReducers({
  blacklistProducts,
});

export const getBlacklistProducts = (state) => state.blacklistProducts;
