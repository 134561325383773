import { combineReducers } from 'redux';

const invoices = (state = {}, action) => {
  switch (action.type) {
    case 'SET_INVOICES_LOADING':
      return {};
    case 'SET_INVOICES':
      return action.invoices;
    default:
      return state;
  }
};

const invoicesLoading = (state = false, action) => {
  switch (action.type) {
    case 'SET_INVOICES_LOADING':
      return true;
    case 'SET_INVOICES':
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  invoices,
  invoicesLoading,
});

export const getInvoices = (state) => state.invoices;
export const getInvoicesLoading = (state) => state.invoicesLoading;
