import { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getOrders, getOrdersLoading } from '../reducers';
import { loadOrders } from '../actions/orders';

const useOrdersLoad = (type, limit, offset) => {
  const dispatch = useDispatch();
  const ordersLoading = useSelector(getOrdersLoading);
  const orders = useSelector(getOrders);
  const ordersLength = useMemo(() => (typeof orders[type].orders === 'undefined' ? 0 : Object.keys(orders[type].orders).length), [orders, type]);

  const subset = useMemo(
    () => (ordersLength > 0 ? orders[type].orders.filter((x, index) => index >= offset && index < offset + limit) : []),
    [orders, ordersLength, type, limit, offset],
  );

  useEffect(() => {
    // Fetch more if the limit and offset doesn't match our existing length of objects.
    // This stops triggering a fetch when navigating between components.
    if (ordersLength < limit + offset) dispatch(loadOrders(type, limit, offset));
  }, [dispatch, type, limit, offset, ordersLength]);

  return { ordersLoading, orders: subset, ordersLength };
};

export default useOrdersLoad;
