import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

// Material-UI
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  link: {
    textDecoration: 'none',
  },
}));

// eslint-disable-next-line react/jsx-props-no-spreading
const LinkComponent = React.forwardRef((props, ref) => <RouterLink ref={ref} {...props} />);

const LinkWrapper = ({ isInternal, to, children, ...rest }) => {
  const classes = useStyles();
  if (isInternal)
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Link component={LinkComponent} underline="none" to={to} {...rest}>
        {children}
      </Link>
    );

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <a href={to} className={classes.link} {...rest}>
      {children}
    </a>
  );
};

LinkWrapper.propTypes = {
  isInternal: PropTypes.bool,
  to: PropTypes.string,
  children: PropTypes.node,
};

LinkWrapper.defaultProps = {
  isInternal: true,
  to: '',
  children: null,
};

export default LinkWrapper;
