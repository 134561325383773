import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// Date
import dayjs from 'dayjs';

// Material-UI
import { makeStyles } from '@material-ui/core/styles';
// import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import Badge from '@material-ui/core/Badge';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import EventAvailableOutlinedIcon from '@material-ui/icons/EventAvailableOutlined';
import Grid from '@material-ui/core/Grid';
import HelpIcon from '@material-ui/icons/Help';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

// Config
import routes from '../../config/routes';

// Components
import Link from './Link';

// Helpers
import { localDate, localTime } from '../../helpers/date';

// Reducers
import { getDeliveryCutoff } from '../../reducers';

const useStyles = makeStyles((theme) => ({
  drop: theme.dropHalf,
  flex: {
    display: 'flex',
  },
  padding: {
    marginTop: theme.spacing(1),
    backgroundColor: '#F0F0F0',
    padding: theme.spacing(1),
  },
  margin: {
    marginLeft: theme.spacing(2),
  },
  date: {
    margin: 0,
    fontWeight: 500,
  },
  helpIcon: {
    fontSize: 20,
    position: 'relative',
    top: 3,
    left: 6,
    color: '#00A1E4',
    cursor: 'pointer',
  },
  shopIcon: {
    fontSize: 32,
  },
  tooltip: {
    fontSize: 14,
    color: '#fff',
    padding: theme.spacing(1),
  },
}));

const NavigationOrder = ({ deliveryDate, cartLength, ordersLength, onViewCartClick }) => {
  const classes = useStyles();

  const cartDeliveryCutoff = useSelector(getDeliveryCutoff);

  return (
    <Grid container item xs={12} className={classes.padding}>
      <Grid item xs={10} sm={8} md={6} className={classes.flex}>
        <Box display="flex" alignItems="center">
          <Box p={1}>
            <Typography>Next Delivery:</Typography>
          </Box>
          <Box>
            <Typography variant="subtitle1" className={classes.date}>
              {(deliveryDate && localDate(deliveryDate)) || `Never`}
              <Tooltip
                title={
                  <Typography className={classes.tooltip}>
                    {deliveryDate
                      ? `Your next available delivery date is ${localDate(deliveryDate)}. Get your order in before ${localTime(
                          cartDeliveryCutoff,
                        )} to confirm it's delivered on time.`
                      : `There are no delivery dates set for you. Please contact VSE to arrange
                    delivery dates`}
                  </Typography>
                }
                placement="top-end"
              >
                <HelpIcon className={classes.helpIcon} />
              </Tooltip>
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid container item xs={2} sm={4} md={6} justifyContent="flex-end" alignItems="center">
        <Hidden smDown>
          {/* <Link color="inherit" underline="none" to={routes.private.order}>
            <Button variant="contained" disableElevation color="primary" startIcon={<AddShoppingCartIcon />} className={classes.button}>
              Place an order
            </Button>
          </Link> */}
          <Link color="inherit" underline="none" to={`${routes.private.orders}/scheduled`}>
            <Button variant="outlined" startIcon={<EventAvailableOutlinedIcon />} className={classes.margin}>
              {`Scheduled orders (${ordersLength})`}
            </Button>
          </Link>
        </Hidden>
        <IconButton color="secondary" onClick={onViewCartClick}>
          <Badge badgeContent={cartLength} color="secondary" overlap="rectangular">
            <ShoppingCartIcon className={classes.shopIcon} />
          </Badge>
        </IconButton>
      </Grid>
    </Grid>
  );
};

NavigationOrder.propTypes = {
  deliveryDate: PropTypes.oneOfType([PropTypes.bool, PropTypes.instanceOf(dayjs)]),
  cartLength: PropTypes.number,
  ordersLength: PropTypes.number,
  onViewCartClick: PropTypes.func,
};

NavigationOrder.defaultProps = {
  deliveryDate: '',
  cartLength: 0,
  ordersLength: 0,
  onViewCartClick: () => {},
};

export default NavigationOrder;
