import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getProduct, getProductLoading } from '../reducers';
import { loadProduct } from '../actions/products';

const doGetProductLoading = (oid) => (state) => getProductLoading(state, oid);

const useProductLoad = (oid) => {
  const dispatch = useDispatch();
  const productLoading = useSelector(doGetProductLoading(oid));
  const product = useSelector(getProduct);

  useEffect(() => {
    dispatch(loadProduct(oid));
  }, [dispatch, oid]);

  return { productLoading, product };
};

export default useProductLoad;
