import first from 'lodash/first';
import isEmpty from 'lodash/isEmpty';

// API
import { fetchFavourites, addFavourite, updateFavourite } from '../api/Favourites';

// Actions
import { setError } from './notifications';
import { setUserExpired } from './users';

export const setFavourites = (favourites) => ({
  type: 'SET_FAVOURITES',
  favourites,
});

export const setFavouritesRaw = (favourites) => ({
  type: 'SET_FAVOURITES_RAW',
  favourites,
});

export const setFavouritesFilter = (search) => ({
  type: 'SET_FAVOURITES_FILTER',
  search,
});

export const setFavouritesLoading = () => ({
  type: 'SET_FAVOURITES_LOADING',
});

export const resetFavourites = () => ({
  type: 'RESET_FAVOURITES',
});

export const setFavourite = (favourite) => ({
  type: 'SET_FAVOURITE',
  favourite,
});

export const loadFavourites = (type, limit, offset, search) => async (dispatch, getState) => {
  if (!type) return false;

  const { favourites } = getState();
  const { favouritesFilter } = favourites;

  // If the search has changed, reset the favourites so the page shows loading.
  if (search !== favouritesFilter) dispatch(resetFavourites());

  dispatch(setFavouritesLoading());

  const token = localStorage.getItem('tokens'); // Retrieve the token from localStorage. The token will be used if the user refreshes the page.
  const debtorId = localStorage.getItem('debtor_id'); // Retrieve the debtor_id from localStorage. The debtor_id is used to fetch related records from the API

  // Search the entire list of favourites to get a list of oid's we should use to filter the debtor favourites.
  // We do this as the fetchFavourites method fetches the favourites for a debtor, we can't do an addition product search on that.
  return fetchFavourites(token, debtorId, type, limit, offset)
    .then((response) => {
      // Cache the API response into local storage, this will improve the proformance of viewing the product screen a secondary time.
      dispatch(setFavouritesRaw(response));

      const debtorProduct = {
        ...response.objects[0].rBKdebtorProduct,
      };

      const favouriteProducts = Object.entries(debtorProduct)

        // Manipulate the result into something more suitable for our application.
        .reduce(
          (a, [, product]) => ({
            ...a,
            [product.rBKproduct.oid]: {
              oid: product.oid,
              edition: product.edition,
              webFavourite: product.webFavourite,
            },
          }),
          {},
        );

      // Store the favourites into Redux state
      dispatch(setFavourites(favouriteProducts));
      return response;
    })
    .catch((error) => {
      if (error.message) dispatch(setError(String(error.message), error));
      if (error.expired) dispatch(setUserExpired());
      return error;
    });
};

export const saveFavourite = (productId, favourite) => async (dispatch) => {
  if (!productId) return false;

  const token = localStorage.getItem('tokens'); // Retrieve the token from localStorage. The token will be used if the user refreshes the page.
  const debtorId = localStorage.getItem('debtor_id'); // Retrieve the debtor_id from localStorage. The debtor_id is used to fetch related records from the API

  // Add a new Favourite
  if (isEmpty(favourite)) {
    const status = true;

    return addFavourite(token, debtorId, productId, status)
      .then((response) => {
        const changedObjects = first(response.changedObjects);

        // Update the favourite into Redux state
        dispatch(setFavourite({ [productId]: { oid: changedObjects.savedOid, edition: changedObjects.savedEdition, webFavourite: status } }));

        return response;
      })
      .catch((error) => {
        if (error.message) dispatch(setError(String(error.message), error));
        if (error.expired) dispatch(setUserExpired());
        return error;
      });
  }

  // Updated an existing Favourite

  const { oid } = favourite;
  const edition = favourite.edition + 1;
  const webFavourite = !favourite.webFavourite;

  return updateFavourite(token, edition, oid, webFavourite)
    .then((response) => {
      // Update the favourite into Redux state
      dispatch(setFavourite({ [productId]: { ...favourite, edition, webFavourite } }));

      return response;
    })
    .catch((error) => {
      if (error.message) dispatch(setError(String(error.message), error));
      if (error.expired) dispatch(setUserExpired());
      return error;
    });
};
