import concat from 'lodash/concat';
import isEmpty from 'lodash/isEmpty';
import isString from 'lodash/isString';
import merge from 'lodash/merge';
import union from 'lodash/union';

// Helpers
import { sizeWithUnitsPerCarton } from './getProductsUnits';

export const getProductOptions = (product) => ({
  oid: product.oid || '',
  category: product.WebCategory ? product.WebCategory.trim().toLowerCase() : '',
  subcategory: product.WebSubCategory ? product.WebSubCategory.trim().toLowerCase() : '',
  size: product.WebSize ? product.WebSize.trim().toLowerCase() : '',
  unitsPerCtn: product.unitsPerCtn ? product.unitsPerCtn : '',
  packaging: (product.WebWrapped ? 'wrapped' : 'unwrapped') || '',
  sliced: (product.WebSliced ? 'sliced' : 'unsliced') || '',
  flavour: product.WebFlavour ? product.WebFlavour.trim().toLowerCase() : '',
});

export const cleanOptions = (options) =>
  Object.entries(options).reduce((nextOptions, [key, option]) => {
    // Disabling no mutation as we've taken special care here to make this non-mutating.
    // eslint-disable-next-line fp/no-mutation, fp/no-mutating-methods, no-param-reassign
    nextOptions[key] = option.filter(Boolean).slice().sort();
    return nextOptions;
  }, {});

export const combineProductOptionsAll = (optionsExisting, options) => {
  const isOptionsExisting = !(typeof optionsExisting === 'undefined' || Object.keys(optionsExisting).length === 0);

  return Object.entries(options).reduce(
    (nextOptions, [key, option]) => {
      if (isString(option) && nextOptions[key] && !nextOptions[key].includes(option)) {
        return {
          ...nextOptions,
          // eslint-disable-next-line fp/no-mutating-methods
          [key]: concat(nextOptions[key], option).slice().sort(),
        };
      }
      if (!isString(option) && nextOptions[key] && !nextOptions[key].includes(option)) {
        return {
          ...nextOptions,
          [key]: union(nextOptions[key], option),
        };
      }
      return nextOptions;
    },
    isOptionsExisting
      ? optionsExisting
      : {
          category: [],
          subcategory: [],
          size: [],
          packaging: [],
          sliced: [],
          flavour: [],
        },
  );
};

export const combineProductOptionsMatrix = (optionsExisting, options) => {
  const result = { ...optionsExisting };

  // Build our matrix of product options.
  return merge(result, {
    [sizeWithUnitsPerCarton(options.size, options.unitsPerCtn)]: {
      [options.packaging]: {
        [options.sliced]: [options.oid],
      },
    },
  });
};

export const getApiProductOptions = (products = {}, apiProduct) => {
  const { price, rBKproductFinished: product } = apiProduct;
  const { ...nextProduct } = product;

  // Identify the product options
  const options = getProductOptions(product);

  const nextOptions = {
    productOptionsAll: combineProductOptionsAll(products.productOptionsAll, options),
    productOptionsMatrix: combineProductOptionsMatrix(products.productOptionsMatrix, options),
  };

  // Merge the product options into the overall products options

  // Push the product into our products
  if (products[nextProduct.oid]) {
    return {
      ...products,
      ...nextOptions,
    };
  }

  return {
    ...products,
    ...nextOptions,
    [nextProduct.oid]: {
      oid: nextProduct.oid,
      itemCode: nextProduct.itemCode,
      webProductName: nextProduct.webProductName,
      maximumOrderQty: nextProduct.maximumOrderQty,
      sellingPrice: price,
      exemptGst: nextProduct.exemptGst,
      temperature: nextProduct.temperature,
      unitsPerCtn: nextProduct.unitsPerCtn,
      options,
    },
  };
};

export const poplateProductOptions = (options, sizeSelected, packagingSelected, slicedSelected) => {
  if (isEmpty(options)) return { poplateOptions: { size: [], packaging: [], sliced: [] }, first: { size: '', packaging: '', sliced: '' } };

  // Sizes are the first option, all are valid.
  const validOptionsSize = Object.keys(options);
  const firstValidOptionSize = isEmpty(sizeSelected) || !validOptionsSize.includes(sizeSelected) ? validOptionsSize[0] : sizeSelected;

  // Packaging is second option, we only want to the options that match the selected size. If not set, the first size options.
  const validOptionsPackaging = Object.keys(options[firstValidOptionSize]);
  const firstValidOptionPackaging =
    isEmpty(packagingSelected) || !validOptionsPackaging.includes(packagingSelected) ? validOptionsPackaging[0] : packagingSelected;

  // Sliced is third option, we only want to the options that match the selected size & packaging. If not set, the first sliced options.
  const validOptionsSliced = Object.keys(options[firstValidOptionSize][firstValidOptionPackaging]);
  const firstValidOptionSliced = isEmpty(slicedSelected) || !validOptionsSliced.includes(slicedSelected) ? validOptionsSliced[0] : slicedSelected;

  return {
    poplateOptions: { size: validOptionsSize, packaging: validOptionsPackaging, sliced: validOptionsSliced },
    first: { size: firstValidOptionSize, packaging: firstValidOptionPackaging, sliced: firstValidOptionSliced },
  };
};
