import React from 'react';
import PropTypes from 'prop-types';

// Date
import DayJS from 'react-dayjs';

// Material-UI
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@material-ui/core/Typography';

// Config
import constants from '../../config/constants';
import routes from '../../config/routes';

// Components
import Link from './Link';

// Images
import BannerBakedBread from '../../assets/images/banners/banner-1197x319-baked-bread.jpg';

const useStyles = makeStyles((theme) => ({
  alignCenter: {
    alignItems: 'center',
  },
  logo: {
    width: '100%',
  },
  marginBottom: {
    marginBottom: theme.spacing(1),
  },
}));

const TitlePage = ({ loading, title, subtitle, orderDate, orderNumber, children }) => {
  const classes = useStyles();

  return (
    <Grid container justifyContent="space-between" className={classes.alignCenter}>
      <Grid container item xs={12} md={4} spacing={constants.gridSpacing} className={classes.panelHeight} alignContent="center">
        {title && (
          <Grid item xs={12}>
            <Typography variant="h3" className={classes.marginBottom}>
              {title}
            </Typography>
          </Grid>
        )}
        {subtitle && (
          <Grid item xs={12}>
            <Typography variant="h4" className={classes.marginBottom}>
              {subtitle}
            </Typography>
          </Grid>
        )}
        {orderDate && (
          <Grid item xs={12}>
            <Typography variant="body1" className={classes.marginBottom}>
              Your order has been placed and will be delivered on:
            </Typography>
            <Typography variant="h6">
              {loading && <Skeleton variant="rect" width="80%" />}
              {!loading && <DayJS format="dddd, MMMM D, YYYY">{orderDate}</DayJS>}
            </Typography>
          </Grid>
        )}
        {orderNumber && (
          <Grid item xs={12}>
            <Typography variant="body1" className={classes.marginBottom}>
              Your order number is:
            </Typography>
            <Typography variant="h6">
              {loading && <Skeleton variant="rect" width="80%" />}
              {!loading && orderNumber}
            </Typography>
          </Grid>
        )}
        {!subtitle && !orderDate && (
          <Grid item xs={12}>
            <Typography variant="body1" className={classes.marginBottom}>
              You have no scheduled orders.
            </Typography>
          </Grid>
        )}
        {children}
      </Grid>
      <Hidden smDown>
        <Grid item xs={8}>
          <Link to={`${routes.private.products}?category=bread`}>
            <img src={BannerBakedBread} alt="View our freshly baked bread" className={classes.logo} />
          </Link>
        </Grid>
      </Hidden>
    </Grid>
  );
};

TitlePage.propTypes = {
  loading: PropTypes.bool,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  orderDate: PropTypes.string,
  orderNumber: PropTypes.string,
  children: PropTypes.node,
};

TitlePage.defaultProps = {
  loading: false,
  title: 'Your Orders',
  subtitle: '',
  orderDate: '',
  orderNumber: '',
  children: <></>,
};

export default TitlePage;
