import { useSelector } from 'react-redux';
import { getProductOptionsAll, getProductOptionsMatrix } from '../reducers';

const useProductOptions = (product) => {
  const productOptionsAll = useSelector((state) => getProductOptionsAll(state, product ? product.webProductName : null));
  const productOptionsMatrix = useSelector((state) => getProductOptionsMatrix(state, product ? product.webProductName : null));
  return { productOptionsAll, productOptionsMatrix };
};

export default useProductOptions;
