import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// Material-UI
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import HelpIcon from '@material-ui/icons/Help';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/Info';

// Config
import constants from '../../config/constants';

// Helpers
import { dollars } from '../../helpers/number';

// Reducers
import { getMinimumOrderValue } from '../../reducers';

// Styles
const useStyles = makeStyles((theme) => ({
  drop: theme.drop,
  dropHalf: theme.dropHalf,
  total: {
    height: 30,
    textAlign: 'right',
  },
  helpIcon: {
    fontSize: 14,
    position: 'absolute',
  },
  price: {
    fontSize: '1.5rem',
    textAlign: 'right',
    // marginTop: theme.spacing(2),
  },
  note: {
    border: '1px solid #eee',
    borderRadius: 3,
    padding: theme.spacing(2, 3, 2, 6),
    position: 'relative',
    marginBottom: theme.spacing(3),
    float: 'right',
  },
  noteIcon: {
    position: 'absolute',
    left: 14,
  },
}));

const OrderTotals = ({ delivery, net, gst, total, showDeliveryNote }) => {
  const classes = useStyles();
  const showDeliveryNotePanel = showDeliveryNote && delivery > 0;
  const deliveryCost = useSelector(getMinimumOrderValue);

  return (
    <Grid container item xs={12} spacing={constants.gridSpacing / 3}>
      {showDeliveryNotePanel && (
        <Grid item xs={12}>
          <Typography className={classes.note}>
            <InfoIcon color="primary" className={classes.noteIcon} />
            Delivery is free for all orders over {dollars(deliveryCost)}. For orders smaller than this, there will be a delivery fee that brings the order total
            up to {dollars(deliveryCost)} exc. GST
          </Typography>
        </Grid>
      )}
      <Grid item xs={8} sm={8} className={classes.total}>
        <Typography variant="h6">
          Delivery Fee:
          <Tooltip
            title={`Delivery is free for all orders over ${dollars(
              deliveryCost,
            )}. For orders smaller than this, there will be a delivery fee that brings the order total up to ${dollars(deliveryCost)} exc. GST`}
            placement="top-end"
          >
            <HelpIcon className={classes.helpIcon} />
          </Tooltip>
        </Typography>
      </Grid>
      <Grid item xs={4} sm={4} className={classes.total}>
        <Typography variant="h6">{dollars(delivery)}</Typography>
      </Grid>
      <Grid item xs={8} sm={8} className={classes.total}>
        <Typography variant="h6">Net:</Typography>
      </Grid>
      <Grid item xs={4} sm={4} className={classes.total}>
        <Typography variant="h6">{dollars(net)}</Typography>
      </Grid>
      <Grid item xs={8} sm={8} className={classes.total}>
        <Typography variant="h6">GST:</Typography>
      </Grid>
      <Grid item xs={4} sm={4} className={classes.total}>
        <Typography variant="h6">{dollars(gst)}</Typography>
      </Grid>
      <Grid item xs={8} sm={8} className={classes.total}>
        <Typography variant="h6">Total:</Typography>
      </Grid>
      <Grid item xs={4} sm={4} className={classes.total}>
        <Typography variant="h4" className={classes.price}>
          {dollars(total)}
        </Typography>
      </Grid>
    </Grid>
  );
};

OrderTotals.propTypes = {
  delivery: PropTypes.number,
  net: PropTypes.number,
  gst: PropTypes.number,
  total: PropTypes.number,
  showDeliveryNote: PropTypes.bool,
};

OrderTotals.defaultProps = {
  delivery: 0,
  net: 0,
  gst: 0,
  total: 0,
  showDeliveryNote: false,
};

export default OrderTotals;
